import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestService } from '../general/request.service';
import { Ordenamiento } from '../../clases/general/ordenamiento';
import { Bloque } from '../../clases/catalogos/bloque';

/**
 * BloqueService
 * @author MG
 */
@Injectable()
export class BloqueService {

    private urlBloquesPagiancion: string = "/bloque/all";
    private urlBloques: string = "/bloque";
    private urlBloquesCorto: string = "/bloque/materia";

    constructor(
        private requestService: RequestService
    ) { }
    
    /**
     * Obtener la lista 
     * @param {number} pagina 
     * @param {number} cantidadRegistros
     * @param {Ordenamiento} ordenamiento
     * @param {string} busqueda
     * @return {Observable<any>} resnse
     */
    public obtenerLista(pagina: number, cantidadRegistros: number, ordenamiento: Ordenamiento, busqueda: string): Observable<any> {
        var body = {
            busqueda: busqueda
        };
        var url = this.requestService.formarUrlPaginacion(this.urlBloquesPagiancion, pagina, cantidadRegistros, ordenamiento);
        return this.requestService.post(url, body);
    }

    /**
     * Obtener la lista
     */
    public obtenerListaPorMateria(idMateria: number): Observable<any> {
        var body = {
            id: idMateria
        };
        return this.requestService.post(this.urlBloquesCorto, body);
    }

    /**
     * Crea
     * @param {Bloque} bloque
     * @return {Observable<any>} 
     */
    public crear(bloque: Bloque): Observable<any> {
        var body = {
            nombre: bloque.nombre,
            materia: bloque.materia
        };
        return this.requestService.post(this.urlBloques, body);
    }

    /**
     * Guardar
     * @param {Bloque} plantel
     * @return {Observable<any>}
     */
    public guardar(bloque: Bloque): Observable<any> {
        var body = {
            id: bloque.id,
            nombre: bloque.nombre,
            materia: bloque.materia
        };
        return this.requestService.put(this.urlBloques, body);
    }

    /**
     * Permite eliminar
     * @param  {number} id
     * @return {Observable<any>} 
     */
    public eliminar(id: number): Observable<any> {
        var url = this.urlBloques + "/" + id;
        return this.requestService.delete(url);
    }

} 