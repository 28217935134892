import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';

/**
 * AcceptErrorDialogComponent 
 * @author MG
 * 
 */
@Component({
  selector: 'accept-error-dialog',
  templateUrl: './accept-error-dialog.component.html',
})
export class AcceptErrorDialogComponent {

  public titulo: string;
  public mensaje: string;

  constructor(public dialogRef: MatDialogRef<AcceptErrorDialogComponent>) { }
  
  /**
   * Cierra el dialogo
   */
  private aceptar(): void {
    this.dialogRef.close();
  }

}
