import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GtConfigSetting } from '@angular-generic-table/core';
import { RequestService } from './request.service';
import { AuthService } from '../seguridad/auth.service';

/**
 * ToolbarService
 * @author MG
 */
@Injectable()
export class ToolbarService {

    private urlTablaConfig = "/ordenTable";

    constructor(
        private requestService: RequestService,
        private authService: AuthService
    ) {
    }

    /**
     * Obtiene la configuracion de la tabla guardada para el usuario
     * @param {string} vista Nombre de la tabla de la cual se desea obtener la configuracion
     * @returns {Observable<any>} Response
     */
    public obtieneConfiguracion(tabla: string): Observable<any> {
        var url = this.urlTablaConfig + "/consulta";
        var usuario = this.authService.obtenerUsuario();
        var body = {
            id_user: usuario.id,
            vista: tabla
        };
        return this.requestService.post(url, body);
    }

    /**
     * Crea la configuracion de una tabla para un usuario
     * @param {string} vista Nombre de la tabla de la cual se desea guardar la configuracion
     * @param {Array<GtConfigSetting>} config Configuracion de la tabla
     * @returns {Observable<any>} Response
     */
    public creaConfiguracion(vista: string, config: Array<GtConfigSetting>): Observable<any> {
        var json = this.convertirGTConfigAString(config);
        var usuario = this.authService.obtenerUsuario();
        var body = {
            user: {
                id: usuario.id
            },
            vista: vista,
            jsonconfig: json
        };
        return this.requestService.post(this.urlTablaConfig, body);
    }

    /**
     * Actualiza la configuracion de una tabla para un usuario
     * @param vista Nombre de la tabla de la cual se desea guardar la configuracion
     * @param config Configuracion de la tabla
     * @returns {Observable<any>} Response
     */
    public actualizaConfiguracion(idConfig: number, vista: string, config: Array<GtConfigSetting>): Observable<any> {
        var usuario = this.authService.obtenerUsuario();
        var json = this.convertirGTConfigAString(config);
        var body = {
            id: idConfig,
            user: {
                id: usuario.id
            },
            vista: vista,
            jsonconfig: json
        };
        return this.requestService.put(this.urlTablaConfig, body);
    }

    /**
     * Llena el objeto settings de tipo Array<GtConfigSetting> con la configuracion contenida en config
     * @param settings Objeto de tipo Array<GtConfigSetting> que contiene la configuracion por default de la tabla
     * @param config Configuracion obtenida para el usuario
     * @returns {Array<GtConfigSetting>} Configuracion de la tabla 
     */
    public convertirStringAGTConfig(settings: Array<GtConfigSetting>, config: string): Array<GtConfigSetting> {
        var columnas: Array<string>;
        var configColumna: Array<string>;
        var configParametros: Array<string>;
        var nombreColumna: string;
        var visible: boolean;
        var orden: number;

        if (config != null && config != "") {
            //Dividir cadena por ;
            columnas = config.split(";");
            //Recorrer cada cadena de configuacion de columna
            columnas.forEach(columna => {
                //Separa el nombre de la columna de sus parametros
                configColumna = columna.split("=");
                if (configColumna.length == 2) {
                    //Obtiene el nombre de la columna
                    nombreColumna = configColumna[0].trim();
                    //Separa sus parametros
                    configParametros = configColumna[1].split(",");
                    visible = true;
                    orden = 0;
                    if (configParametros.length == 2) {
                        orden = Number(configParametros[0].trim());
                        visible = (configParametros[1].trim() === 'true');
                    }
                }
                //Buscar en la configuracion por default
                settings.forEach(columnSetting => {
                    if (columnSetting.objectKey === nombreColumna) {
                        columnSetting.columnOrder = orden;
                        columnSetting.visible = visible;
                    }
                });
            });
        }    
        return settings;
    }

    /**
     * Convierte la configuracion de la tabla en un string 
     * @param {Array<GtConfigSetting>} settings Configuracion de la tabla
     * @returns {string} Configuracion de la tabla en forma de cadena
     */
    public convertirGTConfigAString(settings: Array<GtConfigSetting>): string {
        var json = "";
        settings.forEach(columna => {
            if (columna.visible != null || columna.visible != undefined)
                json = json + columna.objectKey + "=" + columna.columnOrder + "," + columna.visible + ";";
        });
        return json;
    }


}