import { Component, Input, OnInit } from '@angular/core';
import { CHARTCONFIG } from '../charts.config';
import { ItemGraficaBarrasSimple } from '../../../core/clases/general/item-grafica-barras-simple';

@Component({
    selector: 'horizontal-bar-chart',
    templateUrl: './horizontal-bar.component.html'
})
export class HorizontalBarComponent implements OnInit {

    @Input() legend: boolean = false; //Si muestra leyenda
    @Input() saveAsImage: boolean = true; //Si permite guardar la grafica como imagen
    @Input() data: ItemGraficaBarrasSimple; //Informacion a mostrar (X)
    @Input() style: string = ""
    @Input() titleTooltip: string = "";
    @Input() title: string = "";
    
    config = CHARTCONFIG;
    
    options = {
        title: {
            show: false,
            text: '',
            textStyle: {
                color: this.config.textColor,
                fontSize: 14,
                fontWeight: 400,
                padding: 0,
                top: 0
            }
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        legend: {
            orient: 'vertical',
            show: false,
            data: [],
            x: 'right',
            y: 'top',
            textStyle: {
                color: this.config.textColor
            }
        },
        toolbox: {
            show: true,
            feature: {
                saveAsImage: { show: true, title: ' ', name: this.config.nombreArchivoDescarga }
            },
            bottom: 30
        },
        grid: {
            x: 150,
            y: 40,
            x2: 40,
            y2: 80,
            borderWidth: 0
        },
        calculable: true,
        xAxis: [
            {
                type: 'value',
                axisLabel: {
                    textStyle: {
                        color: this.config.textColor
                    }
                },
                splitLine: {
                    lineStyle: {
                        color: this.config.splitLineColor
                    }
                }
            }
        ],
        yAxis: [
            {
                show: true,
                type: 'category',
                data: [],
                axisLabel: {
                    rotate: 30,
                    textStyle: {
                        color: this.config.textColor
                    }
                },
                splitLine: {
                    lineStyle: {
                        color: this.config.splitLineColor
                    }
                },
                splitArea: {
                    show: true,
                    areaStyle: {
                        color: this.config.splitAreaColor
                    }
                }
            }
        ],
        color: [
            this.config.primary
        ],
        series: [
            {
                name: '',
                type: 'bar',
                stack: 'Sum',
                itemStyle: { normal: { label: { show: true, position: 'insideRight' } } },
                data: []
            }
        ]
    };

    /**
     * Inicializar componente
     */
    public ngOnInit(): void {
        //Verificar si se mostraran las leyendas
        if (this.legend) {
            this.options.legend.show = true;
            this.options.legend.data = [this.legend]; 
        }
         //Verificar si se especifico un titulo
        if (this.title) {
            this.options.title.text = this.title;
            this.options.title.show = true;
        }
        //Verificar configuracion de guardar imagen
        this.options.toolbox.feature.saveAsImage.show = this.saveAsImage;
         //Definir datos
        this.options.yAxis[0].data = this.data.nombres;
        this.options.series[0].name = this.titleTooltip;
        this.options.series[0].data = this.data.valores;
    }

}
