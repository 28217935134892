import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestService } from '../general/request.service';
import { Respuesta } from '../../clases/actividades/respuesta';

/**
 * PreguntaTareaProfesorService
 * @author MG
 */
@Injectable()
export class PreguntaTareaProfesorService {

    private urlPreguntaTarea: string = "/preguntaTareaProfesor/xTarea";
    private urlPreguntaTareaEvaluar: string = "/preguntaTareaProfesor/evaluar";
    private urlTareaArchivo: string = "/files/tarea";

    constructor(
        private requestService: RequestService
    ) { }
    
    /**
    * Lista de todas las preguntas por tarea
    * @param {number} idTarea
    */
    public obtenerLista(idTarea: number): Observable<any> {
        var body = {
            id: idTarea
        };
        return this.requestService.post(this.urlPreguntaTarea, body);
    }

    /**
     * Evalua las respuestas del alumno
     * @param {number} idTarea
     * @param {Array<Respuesta>} lista
     */
    public evaluarTarea(idTarea: number, lista: Array<Respuesta>): Observable<any> {
        var body = {
            id: idTarea,
            respuesta: lista
        };
        return this.requestService.post(this.urlPreguntaTareaEvaluar, body);
    }

    /**
     * Carga archivo a andamio
     * @param {number} idTarea
     * @param {any} archivo 
     */
    public crearArchivo(idTarea: number, archivo: any, nombre: string): Observable<any> {
        var formData: FormData = new FormData();
        formData.append('file', archivo, nombre);
        formData.append("tarea", idTarea + "");
        return this.requestService.postFile(this.urlTareaArchivo, formData);
    }

} 