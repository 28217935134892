import { Component} from '@angular/core';
import { GtCustomComponent } from '@angular-generic-table/core';

/**
 * IconDownloadComponent
 * @author MG
 */
@Component({
    selector: 'download-icon',
    templateUrl: './download-icon.component.html'
})
export class DownloadIconComponent extends GtCustomComponent<any>  {

    constructor() {
        super();
    }
}