import { Directive, HostListener } from '@angular/core';
import * as screenfull from 'screenfull';
import { Screenfull } from "screenfull";

/**
 * ToggleFullscreenDirective
 * @author MG
 */
@Directive({
    selector: '[toggleFullscreen]'
})
export class ToggleFullscreenDirective {
    @HostListener('click') onClick() {
        let sf = <Screenfull>screenfull;
        /*if (sf.isEnabled) {
            sf.toggle();
        }*/
        sf.toggle();
    }
}