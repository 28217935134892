import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { GtConfig, GtConfigSetting } from '@angular-generic-table/core';
import { DragulaService } from 'ng2-dragula'
import { HttpErrorResponse } from '@angular/common/http';
import { MensajeService } from '../../core/servicios/general/mensaje.service';
import { ToolbarService } from '../../core/servicios/general/toolbar.service';
import { ErrorService } from '../../core/servicios/general/error.service';
import { ConfiguracionTabla } from '../../core/clases/configuracion/configuracion-tabla';
import { expandCollapse } from '../animations/expandCollapse';
import { finalize } from 'rxjs/operators';
import { Subscription } from 'rxjs';

/**
 * ToolbarComponent
 * @author MG
 */
@Component({
    selector: 'tool-bar',
    templateUrl: './tool-bar.component.html',
    animations: [
        expandCollapse
    ]
})
export class ToolBarComponent implements OnInit, OnDestroy {

    @Input() agrega: boolean = false; //Boton para agregar
    @Input() configura: boolean = false; //Configuracion de la tabla personalizable
    @Input() expande: boolean = false; //Boton para expandir la tabla
    @Input() busca: boolean = false; //Busqueda
    @Input() actualiza: boolean = false; //Boton de actualizar
    @Input() seleccionaTodos: boolean = false; //Boton de seleccionar todos
    @Input() configObject: GtConfig<any>;
    @Input() configDefaultTabla: any = null; //Configuracion de la vista que se actualizara
    @Input() textoBuscar: string; //texto en campo de busqueda
    @Input() exportExcel: boolean = false; //Boton exportar a excel
    @Input() exportPDF: boolean = false;//Boton exportar PDF
    @Input() ordenar: boolean = false; //Boton Ordenar
    @Input() informacion: boolean = false; //Boton Info
    @Input() importar: boolean = false; //Boton Importar
    @Input() descargar: boolean = false; //Boton descargar
    @Input() precios: boolean = false; //Boton precios
    @Input() sinImagen: boolean = false; //Boton sin imagen (productos)
    @Input() sinSobrantes: boolean = false; //Auditorias
    @Input() guardar: boolean = false; //Boton Guardar
    @Input() herramientas: boolean = false; //Boton herramientas

    @Output() oAgregar: EventEmitter<void> = new EventEmitter(); 
    @Output() oConfig: EventEmitter<Array<GtConfigSetting>> = new EventEmitter();
    @Output() oExpandir: EventEmitter<boolean> = new EventEmitter();
    @Output() oBuscar: EventEmitter<string> = new EventEmitter();
    @Output() oActualizar: EventEmitter<void> = new EventEmitter();
    @Output() oExportExcel: EventEmitter<void> = new EventEmitter();
    @Output() oExportPDF: EventEmitter<void> = new EventEmitter();
    @Output() oSeleccionarTodos: EventEmitter<void> = new EventEmitter();
    @Output() oOrdenar: EventEmitter<void> = new EventEmitter();
    @Output() oInformacion: EventEmitter<void> = new EventEmitter();
    @Output() oImportar: EventEmitter<void> = new EventEmitter();
    @Output() oDescargar: EventEmitter<void> = new EventEmitter();
    @Output() oPrecios: EventEmitter<void> = new EventEmitter();
    @Output() oSinImagen: EventEmitter<void> = new EventEmitter();
    @Output() oGuardar: EventEmitter<void> = new EventEmitter();
    @Output() oSinSobrantes: EventEmitter<void> = new EventEmitter();
    @Output() oHerramientas: EventEmitter<void> = new EventEmitter();
    @Output() oCambiaBusqueda: EventEmitter<string> = new EventEmitter();

    private mostrarConfig: boolean;
    private expandido: boolean;
    private mostrarMensajeGuardar: boolean;
    private mostrarMensajeError: boolean;
    private cargandoConfig: boolean;
    private columns: Array<GtConfigSetting> = [];
    private config: ConfiguracionTabla = null;
    private busqueda: string = "";
    private errorConfig: boolean = false;
    
    constructor(
        private toolbarService: ToolbarService,
        private mensajeService: MensajeService,
        private dragulaService: DragulaService,
        private errorService: ErrorService
    ) {
        this.expandido = false;
        this.mostrarMensajeGuardar = false;
        this.mostrarMensajeError = false;
        this.cargandoConfig = false;
        if (this.textoBuscar === "" || this.textoBuscar == null) {
            this.textoBuscar = "Buscar";
        }
        var bag = this.dragulaService.find("bag");
        if (bag != null && bag != undefined) {
            this.dragulaService.createGroup("bag", {});
        }
    }

    /**
     * Inicializacion del componente
     */
    public ngOnInit(): void {
        this.dragulaService.drop("bag").subscribe((value) => {
            this.onDrop(value);
        });
        if (this.configura) {
            this.buscaConfiguracion();
        }
    }

    /**
     * 
     */
    public ngOnDestroy() {
        this.dragulaService.destroy('bag');
    }

    /**
     * 
     */
    private buscaConfiguracion(): void {
        this.cargandoConfig = true;
        this.toolbarService.obtieneConfiguracion(this.configDefaultTabla.vista)
            .pipe(
                finalize(() => {
                    this.obtenerColumnas();
                    this.cargandoConfig = false;
                })
            )
            .subscribe(
                res => {
                    if (res.body != null) {
                        this.config = res.body;
                        this.configObject.settings = this.toolbarService.convertirStringAGTConfig(this.configDefaultTabla.columnas, this.config.jsonconfig);
                        this.oConfig.emit(this.configObject.settings);
                    }
                    else {
                        this.configObject.settings = this.configDefaultTabla.columnas;
                    }
                },
                (error: HttpErrorResponse) => {
                    this.errorConfig = true;
                });
    }

    /**
     * Obtiene las columnas que son validas para ser mostradas
     */
    private obtenerColumnas(): void { 
        if (this.configObject.settings) {
            for (var i = 0; i < this.configObject.settings.length; i++) {
                if (this.configObject.fields[i]) {
                    var encabezado = this.configObject.fields[i].name;
                    if (encabezado != '') {
                        this.columns.push(this.configObject.settings[i]);
                    }
                }
            }
        }
    }

    /**
     * Evento que se ejecuta cuando se mueve una opcion
     * @param {any} value
     */
    private onDrop(value: any): void {
        if (this.columns && this.configObject) {
            for (var i = 0; i < this.columns.length; i++) {
                this.columns[i].columnOrder = i;
                for (var j = 0; j < this.configObject.settings.length; j++) {
                    if (this.columns[i].objectKey === this.configObject.settings[j].objectKey)
                        this.configObject.settings[j].columnOrder = this.columns[i].columnOrder;
                }
            }
        }    
        this.actualizaConfiguracion();
    }

    /**
     * Detecta el cambio en la configuracion y envia la peticion para actualizar
     */
    private actualizaConfiguracion(): void {
        this.mostrarMensajeGuardar = true;
        if (this.configObject != null && this.configObject != undefined) {
            this.oConfig.emit(this.configObject.settings);
        }
    }

    /**
     * Detecta el click sobre el boton de configuracion que oculta o muestra la configuracion
     */
    private toogleConfiguracion(): void {
        this.mostrarConfig = !this.mostrarConfig;
    }

    /**
     * Detecta la solicitud de busqueda envia la solicitud
     * @param string Cadena a buscar
     */
    private buscar(): void {
        this.oBuscar.emit(this.busqueda);
    }

    /**
     * Detecta click sobre el boton de agregar y envia la solicitud
     */
    private agregar() : void {
        this.oAgregar.emit();
    }

    /**
     * Detecta el click sobre el boton de expandir y envia la solicitud
     */
    private expandir(): void {
        this.expandido = !this.expandido;
        this.oExpandir.emit(this.expandido);      
    }

    /**
     * Detecta el click sobre el boton de actualizar y envia la solicitud
     */
    private actualizar() {
        this.textoBuscar = null;
        this.oActualizar.emit();
    }

    /**
     * Almacena la configuracion para el usuario para de esta manera la siguiente vez que entre se encuentre
     * disponible. Si se modifica la configuracion pero esta no se guarda, los cambios cambios no seran
     * visibles de forma permanente, si no solo al estarse editando en tiempo real
     */
    private guardarConfiguracion(): void {
        //Verifica que no se ha producido ningun error en la carga de la configuracion de la tabla ya que de repetirse podrian cauar errores
        if (this.errorConfig) {
            this.mensajeService.enviar("E00025", "Error", 1);
        }
        else {
            if (this.configDefaultTabla.vista != "" && this.configDefaultTabla.vista != null) {
                if (this.config && this.config.id != null) {
                    this.toolbarService.actualizaConfiguracion(this.config.id, this.configDefaultTabla.vista, this.configObject.settings).subscribe(
                        res => {
                            this.mostrarMensajeGuardar = false;
                            this.mensajeService.enviar("I00001", "Guardada", 3);
                        },
                        (error: HttpErrorResponse) => {
                            this.errorService.validate(error, this.errorService.HttpType.Consulta);
                        });
                }
                else {
                    this.toolbarService.creaConfiguracion(this.configDefaultTabla.vista, this.configObject.settings).subscribe(
                        res => {
                            this.config = res.body;
                            this.mostrarMensajeGuardar = false;
                            this.mensajeService.enviar("I00001", "Guardada", 3);
                        },
                        (error: HttpErrorResponse) => {
                            this.errorService.validate(error, this.errorService.HttpType.Consulta);
                        });
                }
            } else {
                this.mensajeService.enviar("E00003", "Error", 1);
            }
        }    
    }

    /**
     * Regresa la cadena con la cual se esta realizando la busqueda
     */
    public obtenerCadenaBusqueda(): string {
        return this.busqueda;
    }

    /**
     * Deshabilita mientras carga la configuracion
     */
    public cargandoConfiguracion(): void {
        this.cargandoConfig = true;
    }

    /**
     * Habilita
     */
    public terminoCargarConfiguracion(): void {
        this.cargandoConfig = false;
    }

    /**
     * Limpia la busqueda y envia el evento para la realizacion de la busqueda
     */
    private clean(): void {
        this.busqueda = "";
        this.buscar();
    }

    /**
     * Detecta el click sobre el boton de exportar a excel y envia la solicitud
     */
    private exportarExcel() {
        this.oExportExcel.emit();
    }

    /**
     * Detecta el click sobre el boton de exportar a excel de nomadas y envia la solicitud
     */
    private exportarExcelNomadas(): void {
        this.oExportExcel.emit();
    }

    /**
     *  Detecta el click sobre el boton de exportar el PDF y envia la solicitud
     */
    private exportarPDF(): void{
        this.oExportPDF.emit();
    }

    /**
     * Detecta el click sobre el boton de seleccionar  todos y envia la solicitud
     */
    private seleccionarTodos() {
        this.oSeleccionarTodos.emit();
    }

    /**
     * Detecta el click sobre el boton de ordenar y envia la solicitud
     */
    private irAOrdenar() {
        this.oOrdenar.emit();
    }

    /**
     * Detecta el click sobre el boton de informacion y envia la solicitud
     */
    private verInformacion(): void {
        this.oInformacion.emit();
    }

    /**
     * Detecta el click sobre el boton de importar y envia la solicitud
     */
    private importarInformacion(): void {
        this.oImportar.emit();
    }

    /**
     * Detecta el click sobre el boton de descargar y envia la solicitud
     */
    private descargarAlgo(): void {
        this.oDescargar.emit();
    }
    
    /**
     * Detecta el click sobre el boton de precios y envia la solicitud
     */
    private irAActualizarPrecios(): void {
        this.oPrecios.emit();
    }

    /**
     * Detecta el click sobre el boton de productos sin imagen y envia la solicitud
     */
    private irASinImagen(): void {
        this.oSinImagen.emit();
    }

    /**
     * Detecta el click sobre el boton de guardar y envia la solicitud
     */
    private irAGuardar(): void {
        this.oGuardar.emit();
    }

    /**
     * Detecta el click sobre el boton de herramientas y envia la solicitud
     */
    private irAHerramientas(): void {
        this.oHerramientas.emit();
    }

    /**
     * Detecta que el campo de la busqueda ha cambiado
     * @param $event 
     */
    private busquedaChange($event): void {
        this.oCambiaBusqueda.emit(this.busqueda);
    }

    private irASinSobrantes(): void {
        this.oSinSobrantes.emit();
    }
}