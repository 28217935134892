import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginRoutingModule } from './login-routing.module';
import { MySharedModule } from '../my-shared/my-shared.module';
//Componentes
import { LoginComponent } from './login.component';
import { ValidacionUsuarioComponent } from './validacion-usuario/validacion-usuario.component';
import { ValidacionContrasenaComponent } from './validacion-contrasena/validacion-contrasena.component';
import { RecuperarContrasenaComponent } from './recuperar-contrasena/recuperar-contrasena.component';
import { RecuperarUsuarioComponent } from './recuperar-usuario/recuperar-usuario.component';
import { RestaurarContrasenaComponent } from './restaurar-contrasena/restaurar-contrasena.component';
//Servicios
import { ComunicacionService } from './servicios/comunicacion.service';
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MySharedModule.forRoot(),
        LoginRoutingModule
    ],
    declarations: [
        ValidacionUsuarioComponent,
        ValidacionContrasenaComponent,
        RecuperarContrasenaComponent,
        RecuperarUsuarioComponent,
        LoginComponent,
        RestaurarContrasenaComponent
    ],
    providers: [
        ComunicacionService
    ]
})
export class LoginModule { }