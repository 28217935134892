import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Perfil } from '../../clases/seguridad/perfil';
import { RequestService } from '../general/request.service';
import { Ordenamiento } from '../../clases/general/ordenamiento';

/**
 * PerfilService
 * @author MG
 */
@Injectable()
export class PerfilService {

    private urlPerfilesPaginados: string = "/perfil/all";
    private urlPerfiles: string = "/perfil";
    private urlPerfilUsuario: string = "/perfil/user";
    private urlPerfilesCorto: string = "/perfil/corto/all";

    constructor(
        private requestService: RequestService
    ) { }

    /**
     * Obtener la lista de los perfiles
     * @return {Observable<any>} Response
     */
    public obtenerLista(pagina: number, cantidadRegistros: number, ordenamiento: Ordenamiento, busqueda: string): Observable<any> {
        var body = {
            busqueda: busqueda
        };
        var url = this.requestService.formarUrlPaginacion(this.urlPerfilesPaginados, pagina, cantidadRegistros, ordenamiento);
        return this.requestService.post(url, body);
    }

    /**
     * 
     */
    public obtenerListaCorto(): Observable<any> {
        return this.requestService.get(this.urlPerfilesCorto);
    }

    /**
     * Obtener la informacion del perfil seleccionado
     * @param {number} id Id del perfil
     * @return {Observable<any>} Response
     */
    public obtenerPorId(id: number): Observable<any> {
        var url = this.urlPerfiles + "/"+ id;
        return this.requestService.get(url);
    }
    
    /**
     * Crea un nuevo perfil
     * @param {Perfil} perfil
     * @return {Observable<any>} Response
     */
    public crear(perfil: Perfil): Observable<any> {
        var body = {
            nombre: perfil.nombre,
            descripcion: perfil.descripcion,
            enabled: perfil.enabled
        };
        return this.requestService.post(this.urlPerfiles, body);
    }

    /**
     * Guardar el perfil
     * @param {Perfil} perfil
     * @return {Observable<any>} Response
     */
    public guardar(perfil: Perfil): Observable<any> {
        var body = {
            id: perfil.id,
            nombre: perfil.nombre,
            descripcion: perfil.descripcion,
            enabled: perfil.enabled
        };
        return this.requestService.put(this.urlPerfiles, body);
    }

    /**
     * Permite eliminar el perfil indicado
     * @param  {number} id Id del perfil a eliminar
     * @return {Observable<any>} Response
     */
    public eliminar(id: number): Observable<any> {
        var url = this.urlPerfiles + "/" + id;
        return this.requestService.delete(url);
    }
    
    /**
     * Obtiene los datos del perfil del usuario firmado
     * @return {Observable<any>} Response
     */
    public obtenerPerfil(): Observable<any> {
        return this.requestService.get(this.urlPerfilUsuario);
    }

} 