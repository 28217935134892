import { AbstractControl } from '@angular/forms';

/**
 * Valida que no este vacio el contenido del control
 * @author MG
 * @param {AbstractControl} control
 */
export function empty(control: AbstractControl): { [key: string]: boolean } | null {
    if (control.value != null) {
        if (control.value.length > 0) {
            if (control.value.trim() === "") {
                return { 'empty': true }
            }
        }
    }
    return null;
}