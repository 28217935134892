import { RequestService } from '../general/request.service';
import { Ordenamiento } from '../../clases/general/ordenamiento';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

/**
 * ReporteCodigosLibrosService
 * @author MG
 */
@Injectable()
export class ReporteCodigosLibrosService {

    private urlCodigosCantidad = "/reporteCodigoLibros/cantidad";

    constructor(
        private requestService: RequestService
    ) { }

    /**
     * Obtener la lista de los alumnos
     * @param {string} fechaInicio
     * @param {string} fechaFin
     * @param {string} busqueda
     * @param {boolean} eliminados
     * @return {Observable<any>} response
     */
    public obtenerListaCantidad(fechaInicio: string, fechaFin: string, busqueda: string, eliminados: boolean): Observable<any> {
        var body = {
            fechaInicio: fechaInicio,
            fechaFin: fechaFin,
            busqueda: busqueda,
            eliminados: eliminados
        };
        return this.requestService.post(this.urlCodigosCantidad, body);
    }
}