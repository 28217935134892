import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../servicios/seguridad/auth.service';
import { ROUTES } from '../../config/routes';

/**
 * AuthenticatedGuard. Proporciana la verificacion de rutas para usuarios autentificados
 * @author MG
 * 
 */
@Injectable()
export class AuthUserGuard implements CanActivate {
    
    constructor(
        private router: Router,
        private authService: AuthService
    ) { }

    /**
     * Verifica si el usuario esta autentificado
     * para acceder a la ruta sin comprobar los permisos para la misma
     */
    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        //console.log("AuthUserGuard");
        var autentificado = this.authService.estaAutentificado();
        //Verifica si el usuario esta autenticado
        if (!autentificado) {
            this.router.navigate([ROUTES.validarUsuario]);
            return false;
        }
        return true;
    }
}