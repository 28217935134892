import { Component, Input, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorService } from '../../core/servicios/general/error.service';
import { finalize } from 'rxjs/operators';
import { Tarea } from '../../core/clases/actividades/tarea';
import { PreguntaTarea } from '../../core/clases/actividades/pregunta-tarea';
import { PreguntaTareaService } from '../../core/servicios/actividades/pregunta-tarea.service';
import { TareaService } from '../../core/servicios/actividades/tarea.service';

/**
 * TareaNoCopyVistaPreviaComponent
 * @author MG
 */
@Component({
    selector: 'tareanocopy-vistaprevia',
    templateUrl: './tareanocopy-vistaprevia.component.html'
})
export class TareaNoCopyVistaPreviaComponent implements OnInit {
    

    @Input() tarea: Tarea = new Tarea();

    private listaPreguntas: Array<PreguntaTarea> = [];
    private cargandoDatos: boolean;
    private cargandoDatosTarea: boolean;

    constructor(
        private errorService: ErrorService,
        private tareaService: TareaService,
        private preguntaTareaService: PreguntaTareaService
    ) {
        
    }

    /**
     * Inicializa el componente
     */
    ngOnInit(): void {
        this.buscarTarea();
        this.buscarLista();
    }

    private buscarTarea(): void {
        this.cargandoDatosTarea = true;
        this.tareaService.obtenerPorId(this.tarea.id)
            .pipe(
                finalize(() => {
                    this.cargandoDatosTarea = false;
                })
            )
            .subscribe(
                res => {
                    var tarea: Tarea = res.body;
                    this.tarea.contenido = tarea.contenido;
                },
                (error: HttpErrorResponse) => {
                    this.errorService.validate(error, this.errorService.HttpType.Generico);
                });
    }
    
    
    /**
     * Obtiene las preguntas del examen
     */
    private buscarLista(): void {
        this.cargandoDatos = true;
        this.preguntaTareaService.obtenerListaTodas(this.tarea.id)
            .pipe(
                finalize(() => {
                    this.cargandoDatos = false;
                })
            )
            .subscribe(
                res => {
                    if (res.body) {
                        var lista: Array<PreguntaTarea> = res.body;
                        var listaAux: Array<PreguntaTarea> = [];
                        var contador: number = 1;
                        if (lista) {
                            lista.forEach(p => {
                                p.consecutivo = contador;
                                listaAux.push(p);
                                p.pregunta = contador + ".- " + p.pregunta;
                                contador = contador + 1;
                            });
                        }
                        this.listaPreguntas = listaAux;
                    }
                },
                (error: HttpErrorResponse) => {
                    this.errorService.validate(error, this.errorService.HttpType.Consulta);
                });
    }

}