import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestService } from '../general/request.service';
import { VideoTutorial } from '../../clases/catalogos/video-tutorial';
import { Ordenamiento } from '../../clases/general/ordenamiento';

@Injectable()
export class VideoTutorialService {

    private urlVideos = "/videotutoriales";
    private urlVideosConsulta = "/videotutoriales/all";
    private urlVideosBloque = "/videotutoriales/bloque";

    constructor(
        private requestService: RequestService
    ) { }

    /**
     * Obtener la lista de todos los videotutoriales
     * @return {Observable<any>}
     */
    public obtenerListaTodos(pagina: number, cantidadRegistros: number, ordenamiento: Ordenamiento, busqueda: string): Observable<any> {
        var body = {
            busqueda: busqueda
        };
        var url = this.requestService.formarUrlPaginacion(this.urlVideosConsulta, pagina, cantidadRegistros, ordenamiento);
        return this.requestService.post(url, body);
    }

    /**
     * Obtener la lista de los videotutoriales por bloque
     * @param {number} idBloque
     * @return {Observable<any>}
     */
    public obtenerLista(idBloque: number): Observable<any> {
        var body = {
            id: idBloque
        }
        return this.requestService.post(this.urlVideosBloque, body);
    }

    /**
     * Crea un nuevo videotutorial
     * @param {VideoTutorial} videotutorial
     * @return {Observable<any>}
     */
    public crear(videotutorial: VideoTutorial): Observable<any> {
        var body = {
            titulo: videotutorial.titulo,
            descripcion: videotutorial.descripcion,
            bloque: videotutorial.bloque,
            url: videotutorial.url
        };
        return this.requestService.post(this.urlVideos, body);
    }

    /**
     * Guarda la informacion del videotutorial
     * @param {VideoTutorial} videotutorial
     * @return {Observable<any>}
     */
    public guardar(videotutorial: VideoTutorial): Observable<any> {
        var body = {
            id: videotutorial.id,
            titulo: videotutorial.titulo,
            descripcion: videotutorial.descripcion,
            bloque: videotutorial.bloque,
            url: videotutorial.url
        };
        return this.requestService.post(this.urlVideos, body);
    }
    
    /**
     * Permite eliminar el videotutorial indicado
     * @param {number} id
     * @return {Observable<any>}
     */
    public eliminar(id: number): Observable<any> {
        var url = this.urlVideos + "/" + id;
        return this.requestService.delete(url);
    }
}