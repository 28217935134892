import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { Idioma } from '../../clases/configuracion/idioma';

@Injectable()
export class LanguageService {

    constructor(
        private translateService: TranslateService
    ) { }
    
    //Idiomas disponibles
    private idiomasDisponibles: Array<Idioma> = [
        { id: 1, nombre: "Español", bandera: "flag-icon-mx", abreviacion: "es" },
        { id: 2, nombre: "Ingles", bandera: "flag-icon-us", abreviacion: "en" }
    ];

    /**
     * Obtiene la lista de idiomas disponibles para el sistema
     * @return {Array<Idioma>}
     */
    public obtieneIdiomasDisponibles(): Array<Idioma> {
        return this.idiomasDisponibles;
    }

    /**
     * Obtiene el nombre del idioma en base a la abreviacion
     * @return {string}
     */
    public obtieneNombreIdiomas(abreviacion: string): string {
        var nombre = "";
        for (var i = 0; i < this.idiomasDisponibles.length; i++) {
            if (this.idiomasDisponibles[i].abreviacion === abreviacion) {
                nombre = this.idiomasDisponibles[i].nombre;
            }
        }
        if (nombre != "") {
            return this.translateService.instant(nombre);
        }
        return "";
    }
}