import { Input, Component } from '@angular/core';

/**
 * TabHeaderComponent
 * @author MG
 */
@Component({
    selector: 'tab-header',
    templateUrl: './tab-header.component.html'
})
export class TabHeaderComponent {

    @Input() title: string;
    @Input() classIcon: string;
    @Input() icon: string;

}