import { Component, OnInit } from '@angular/core';
import { APPCONFIG } from '../../config';

/**
 * AppFooterComponent
 * @author MG
 */
@Component({
  selector: 'my-app-footer',
  styles: [],
  templateUrl: './footer.component.html'
})

export class AppFooterComponent implements OnInit {
  
  public AppConfig: any;
  
  /**
   * Inicializar el componente
   */
  public ngOnInit(): void {
    this.AppConfig = APPCONFIG;
  }
}


