import { Component, Input} from '@angular/core';

@Component({
    selector: 'box-dashboard-money',
    templateUrl: './box-dashboard-money.component.html'
})
export class BoxDashboardMoneyComponent {

    @Input() title: string;
    @Input() value: string;
    @Input() currency: string;
    @Input() classIcon: string;
    @Input() icon: string;
    @Input() cargando: boolean = false;
}
