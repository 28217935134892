import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestService } from '../general/request.service';

/**
 * AlumnoExamenService
 * @author MG
 */
@Injectable()
export class AlumnoExamenService {

    private urlExamenesActivos: string = "/alumnoExamen/all";
    private urlExamenesEvaluaciones: string = "/alumnoExamen/evaluaciones";
    private urlExamenesCalificaciones: string = "/alumnoExamen/calificaciones";
    private urlExamenesReactivar: string = "/alumnoExamen/reactivar";

    constructor(
        private requestService: RequestService
    ) { }
    
    /**
     * Obtener la lista 
     * @param  {number} idGrupo
     * @return {Observable<any>} response
     */
    public obtenerListaActivos(idGrupo: number): Observable<any> {
        var body = {
            grupo: {
                id: idGrupo
            }
        };
        return this.requestService.post(this.urlExamenesActivos, body);
    }

    /**
     * Obtener la lista de las evaluaciones
     * @param  {number} idGrupo
     * @param  {number} idBloque
     * @return {Observable<any>} response
     */
    public obtenerListaEvaluaciones(idGrupo: number, idBloque: number): Observable<any> {
        var body = {
            grupo: {
                id: idGrupo
            },
            bloque: {
                id: idBloque
            }
        };
        return this.requestService.post(this.urlExamenesEvaluaciones, body);
    }

    /**
     * Obtener la lista de las evaluaciones
     * @param  {number} idGrupo
     * @return {Observable<any>} response
     */
    public obtenerListaCalificaciones(idGrupo: number): Observable<any> {
        var body = {
            grupo: {
                id: idGrupo
            }
        };
        return this.requestService.post(this.urlExamenesCalificaciones, body);
    }

    /**
     * Reactivar examen
     * @param {number} id 
     */
    public reactivar(id: number): Observable<any> {
        var url = this.urlExamenesReactivar + "/" + id;
        return this.requestService.post(url, {});
    }
} 