import { Injectable } from '@angular/core';
import { SessionStorangeService } from '../general/session-storange.service';
import { MensajeService } from '../general/mensaje.service';
import { STORANGE } from '../../../config/storange';

/**
 * ComunicacionService.
 * Facilita la comunicacion entre los componentes que conforman el EstacionesModule
 * @author MG
 */
@Injectable()
export class RefreshingSessionService {

    private refreshing;
    private variable = STORANGE.refreshing;

    constructor(
        private sessionStorangeService: SessionStorangeService,
        private mensajeService: MensajeService
    ) {
        this.refreshing = sessionStorangeService.obtener(this.variable);
    }
    
    /**
     * Almacena el id de la estacion que se envie
     * @param {boolean} refreshing
     */
    public enviar(refreshing: boolean): void {
        if (refreshing) {
            this.mensajeService.enviar("I00011", "Información", 4);
        }    
        this.sessionStorangeService.guardar(this.variable, refreshing);
    }

    /**
     * 
     * @returns {boolean}
     */
    public obtener(): boolean {
        this.refreshing = this.sessionStorangeService.obtener(this.variable);
        if (this.refreshing === null) {
            this.refreshing = false;
        }
        return this.refreshing;
    }

}