import { Component} from '@angular/core';
import { GtCustomComponent } from '@angular-generic-table/core';

/**
 * HistoryIconComponent
 * @author MG
 */
@Component({
    selector: 'history-icon',
    templateUrl: './history-icon.component.html'
})
export class HistoryIconComponent extends GtCustomComponent<any>  {

    constructor() {
        super();
    }
}