import { Bloque } from '../catalogos/bloque';
import { Profesor } from '../seguridad/profesor';

export class Examen {
    id: number;
    nombre: string;
    bloque: Bloque;
    profesor: Profesor;
    totalProfesores: number;
    totalUsando: number;
}