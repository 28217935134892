import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { LocalStorangeService } from '../../core/servicios/general/local-storange.service';
import { Perfil } from '../../core/clases/seguridad/perfil';

/**
 * ComunicacionService
 * @author MG
 * 
 */
@Injectable()
export class ComunicacionService {

    private variableUsuario: string = "cUsr";
    private variableNombre: string = "cUsrN";
    private variablePerfil: string = "cUsrP";
    private variableImagen: string = "cUsrI";
    private subject: BehaviorSubject<boolean>;

    constructor(
        private localStorangeService: LocalStorangeService
    ) {
        
        this.subject = new BehaviorSubject<boolean>(false);
    }
    
    public mostrarLogoEsq(mostrar: boolean): void {
        this.subject = new BehaviorSubject<boolean>(mostrar);
    }

    public obtenerMostrarLogoEsq(): Observable<boolean> {
        return this.subject.asObservable();
    }

    public guardarUsuario(usr: string): void {
        this.localStorangeService.guardar(this.variableUsuario, usr);
    }
    
    public obtenerUsuario(): string {
        return this.localStorangeService.obtener(this.variableUsuario);
    }

    public limpiarUsuario(): void {
        this.localStorangeService.eliminar(this.variableUsuario)
    }

    public guardarNombreUsuario(nombre: string): void {
        this.localStorangeService.guardar(this.variableNombre, nombre);
    }

    public obtenerNombreUsuario(): string {
        return this.localStorangeService.obtener(this.variableNombre);
    }

    public limpiarNombreUsuario(): void {
        this.localStorangeService.eliminar(this.variableNombre)
    }

    public guardarImagenUsuario(usr: string): void {
        this.localStorangeService.guardar(this.variableImagen, usr);
    }

    public obtenerImagenUsuario(): string {
        return this.localStorangeService.obtener(this.variableImagen);
    }

    public limpiarImagenUsuario(): void {
        this.localStorangeService.eliminar(this.variableImagen)
    }

    public guardarPerfil(perfil: Perfil): void {
        var perfilGuardar = new Perfil();
        perfilGuardar.id = perfil.id;
        perfilGuardar.nombre = perfil.nombre;
        this.localStorangeService.guardar(this.variablePerfil, perfilGuardar);
    }

    public obtenerPerfil(): Perfil {
        return this.localStorangeService.obtener(this.variablePerfil);
    }

    public limpiarPerfil(): void {
        this.localStorangeService.eliminar(this.variablePerfil)
    }
    
    
}