import { Component, OnInit} from '@angular/core';
import { GtCustomComponent } from '@angular-generic-table/core';

/**
 * CantidadComponent
 * @author MG
 */
@Component({
    selector: 'cantidad',
    templateUrl: './cantidad.component.html'
})
export class CantidadComponent extends GtCustomComponent<any> implements OnInit {
    
    private cantidad: number = 0;

    constructor() {
        super();
    }

    /**
     * 
     */
    public ngOnInit() {
        if (this.row && this.row.cantidad) {
            this.cantidad = this.row.cantidad;
        }
    }
}