import { Component} from '@angular/core';
import { GtCustomComponent } from '@angular-generic-table/core';

/**
 * EditIconComponent
 * @author MG
 */
@Component({
    selector: 'edit-icon',
    templateUrl: './edit-icon.component.html'
})
export class EditIconComponent extends GtCustomComponent<any>  {

    constructor() {
        super();
    }
}