/**
 * Ordenamiento
 * @author MG
 * 
 */
export class Ordenamiento {
    campo: string;
    equivalencia: string;
    direccion: string;

    constructor() {
        this.campo = "";
        this.equivalencia = "";
        this.direccion = "";
    }

}