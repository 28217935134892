import { Component} from '@angular/core';
import { GtCustomComponent } from '@angular-generic-table/core';

/**
 * CopyIconComponent
 * @author MG
 */
@Component({
    selector: 'copy-icon',
    templateUrl: './copy-icon.component.html'
})
export class CopyIconComponent extends GtCustomComponent<any>  {

    constructor() {
        super();
    }
}