import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestService } from '../general/request.service';

/**
 * ExamenProfesorService
 * @author MG
 */
@Injectable()
export class ExamenProfesorService {

    private urlExamenes: string = "/examenProfesor";
    private urlExamenPagiancion: string = "/examenProfesor/all";
    private urlExamenDesactivar: string = "/examenProfesor/desactivar";
    private urlExamenActivar: string = "/examenProfesor/activar";

    constructor(
        private requestService: RequestService
    ) { }
    
    /**
     * Obtener la lista 
     * @param  {number} idMateria
     * @return {Observable<any>} response
     */
    public obtenerLista(idMateria: number): Observable<any> {
        var body = {
           id: idMateria
        };
        return this.requestService.post(this.urlExamenPagiancion, body);
    }

    /**
     * Permite desactivar un examen
     * @param  {number} id
     * @return {Observable<any>} 
     */
    public desactivar(id: number): Observable<any> {
        var url = this.urlExamenDesactivar + "/" + id;
        return this.requestService.post(url, {});
    }

    /**
     * Permite desactivar un examen
     * @param  {number} id
     * @return {Observable<any>} 
     */
    public activar(id: number): Observable<any> {
        var url = this.urlExamenActivar + "/" + id;
        return this.requestService.post(url, {});
    }

    /**
     * Permite eliminar un examen
     * @param  {number} id
     * @return {Observable<any>} 
     */
    public eliminar(id: number): Observable<any> {
        var url = this.urlExamenes + "/" + id;
        return this.requestService.delete(url);
    }
} 