import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestService } from '../general/request.service';
import { Ordenamiento } from '../../clases/general/ordenamiento';
import { Anio } from '../../clases/catalogos/anio';

/**
 * AnioService
 * @author MG
 */
@Injectable()
export class AnioService {

    private urlAnioPagiancion: string = "/anno/all";
    private urlAnio: string = "/anno";
    private urlAnioCorto: string = "/anno/corto/all";

    constructor(
        private requestService: RequestService
    ) { }
    
    /**
     * Obtener la lista 
     * @param {number} pagina 
     * @param {number} cantidadRegistros
     * @param {Ordenamiento} ordenamiento
     * @param {string} busqueda
     * @return {Observable<any>} response
     */
    public obtenerLista(pagina: number, cantidadRegistros: number, ordenamiento: Ordenamiento, busqueda: string): Observable<any> {
        var body = {
            busqueda: busqueda
        };
        var url = this.requestService.formarUrlPaginacion(this.urlAnioPagiancion, pagina, cantidadRegistros, ordenamiento);
        return this.requestService.post(url, body);
    }

    /**
     * Obtener la lista
     */
    public obtenerListaCorto(): Observable<any> {
        return this.requestService.post(this.urlAnioCorto, {});
    }

    /**
     * Crea
     * @param {Anio} anio
     * @return {Observable<any>} 
     */
    public crear(anio: Anio): Observable<any> {
        var body = {
            anno: anio.anno,
            enabled: anio.enabled
        };
        return this.requestService.post(this.urlAnio, body);
    }

    /**
     * Guardar
     * @param {Anio} anio
     * @return {Observable<any>}
     */
    public guardar(anio: Anio): Observable<any> {
        var body = {
            id: anio.id,
            anno: anio.anno,
            enabled: anio.enabled
        };
        return this.requestService.put(this.urlAnio, body);
    }

    /**
     * Permite eliminar
     * @param  {number} id
     * @return {Observable<any>} 
     */
    public eliminar(id: number): Observable<any> {
        var url = this.urlAnio + "/" + id;
        return this.requestService.delete(url);
    }

} 