import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule} from '@ngx-translate/core';
import { MatSlideToggleModule, MatButtonModule, MatInputModule, MatDialogModule, 
  MatTooltipModule, MatDatepickerModule, MatNativeDateModule, MatProgressBarModule, MatSelectModule, MatIconModule,
  MatListModule, MatMenuModule, MatSortModule, MatProgressSpinnerModule, MatCheckboxModule, MatTabsModule, MatAutocompleteModule,
  MatRadioModule, MatBadgeModule
} from '@angular/material';
import { GenericTableModule } from '@angular-generic-table/core';
import { ColumnSettingsModule } from '@angular-generic-table/column-settings';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragulaModule } from 'ng2-dragula';
import { FileUploadModule } from 'primeng/fileupload';
import { GrowlModule } from 'primeng/growl';
import { CalendarModule } from 'primeng/calendar';
import { ProgressBarModule } from 'primeng/progressbar';
import { EditorModule } from 'primeng/editor';
import { ButtonModule } from 'primeng/button';
import { HttpClientModule } from '@angular/common/http';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
//Directivas
import { EChartsDirective } from './directives/echarts.directive';
import { SlimScrollDirective } from './directives/slim-scroll.directive';
import { OnlyNumbersDirective } from './directives/only-numbers.directive';
import { OnlyDecimalNumbersDirective } from './directives/only-decimal-numbers.directive';
import { FocusDirective } from './directives/focus.directive';
import { BlockCopyPasteDirective } from './directives/block-copy-paste.directive';
//Componentes 
import { BreadcrumComponent } from './breadcrum/breadcrum.component';
import { ValueCardComponent } from './value-card/value-card.component';
import { BoxDashboardComponent } from './box-dashboard/box-dashboard.component';
import { SearchComponent } from './search/search.component';
import { ToolBarComponent } from './tool-bar/tool-bar.component';
import { DialogToolbarComponent } from './dialog-toolbar/dialog-toolbar.component';
import { SelectRowLengthComponent } from './select-row-length/select-row-length.component';
import { NoDataComponent } from './no-data/no-data.component';
import { NoDataListComponent } from './no-data-list/no-data-list.component';
import { LoadingDataComponent } from './loading-data/loading-data.component';
import { LoadingListComponent } from './loading-list/loading-list.component';
import { AppQuestionComponent } from './app-question/app-question.component';
import { MyProgressBarComponent } from './my-progress-bar/my-progress-bar.component';
import { BoxDashboardMoneyComponent } from './box-dashboard-money/box-dashboard-money.component';
import { TabHeaderComponent } from './tab-header/tab-header.component';
import { DescargaAndamioComponent } from './descarga-andamio/descarga-andamio.component';
import { ExamenVistaPreviaComponent } from './examen-vistaprevia/examen-vistaprevia.component';
import { ExamenNoCopyVistaPreviaComponent } from './examennocopy-vistaprevia/examennocopy-vistaprevia.component';
import { ExamenProfesorVistaPreviaComponent } from './examenprofesor-vistaprevia/examenprofesor-vistaprevia.component';
import { TareaVistaPreviaComponent } from './tarea-vistaprevia/tarea-vistaprevia.component';
import { TareaNoCopyVistaPreviaComponent } from './tareanocopy-vistaprevia/tareanocopy-vistaprevia.component';
import { TareaProfesorVistaPreviaComponent } from './tareaprofesor-vistaprevia/tareaprofesor-vistaprevia.component';
import { MyCKEditorComponent } from './my-ckeditor/my-ckeditor.component';
import { DisplayMathMLComponent } from './my-ckeditor/display-mathml.component';
//Graficas
import { PieComponent } from './charts/pie/pie.component';
import { DoughnutComponent } from './charts/doughnut/doughnut.component';
import { HorizontalBarComponent } from './charts/horizontal-bar/horizontal-bar.component';
import { BasicAreaComponent } from './charts/basic-area/basic-area.component';
//Componentes de tipo Dialog
import { ConfirmDialogComponent } from './dialogs/confirm-dialog/confirm-dialog.component';
import { AcceptDialogComponent } from './dialogs/accept-dialog/accept-dialog.component';
import { FileNameDialogComponent } from './dialogs/file-name-dialog/file-name-dialog.component';
import { AcceptErrorDialogComponent } from './dialogs/accept-error-dialog/accept-error-dialog.component';
import { AcceptSuccesDialogComponent } from './dialogs/accept-sucess-dialog/accept-succes-dialog.component';
//Expanded rows
import { DetalleContactoDireccionComponent } from './expanded-rows/detalle-contacto-direccion.component';
import { DetalleDescripcionComponent } from './expanded-rows/detalle-descripcion.component';
//Icons
import { EditIconComponent } from './icons/edit-icon/edit-icon.component';
import { DeleteIconComponent } from './icons/delete-icon/delete-icon.component';
import { DownloadIconComponent } from './icons/download-icon/download-icon.component';
import { ChangePasswordIconComponent } from './icons/change-password-icon/change-password-icon.component';
import { PermissionsIconComponent } from './icons/permissions-icon/permissions-icon.component';
import { CopyIconComponent } from './icons/copy-icon/copy-icon.component';
import { ProductsIconComponent } from './icons/products-icon/products-icon.component';
import { UpdateImageIconComponent } from './icons/update-image-icon/update-image-icon.component';
import { RefreshIconComponent } from './icons/refresh-icon/refresh-icon.component';
import { DeactivateIconComponent } from './icons/deactivate-icon/deactivate-icon.component';
import { DetailIconComponent } from './icons/detail-icon/detail-icon.component';
import { HistoryIconComponent } from './icons/history-icon/history-icon.component';
import { ReactivosIconComponent } from './icons/reactivos-icon/reactivos-icon.component';
import { PreviewIconComponent } from './icons/preview-icon/preview-icon.component';
import { GrupoIconComponent } from './icons/grupo-icon/grupo-icon.component';
import { AlumnoIconComponent } from './icons/alumno-icon/alumno-icon.component';
//Custom
import { CantidadComponent } from './custom/cantidad/cantidad.component';
import { TipoAndamioComponent } from './custom/tipo-andamio/tipo-andamio.component';
//Pipes
import { SafeHTML } from './pipes/safeHtml';
import { DateWSToDateApp } from './pipes/dateWSToDateApp';

/**
 * MySharedModule
 * @author MG
 */
@NgModule({
  imports: [
    //Angular
    CommonModule,
    TranslateModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    //Angular material
    MatIconModule,
    MatSlideToggleModule,
    MatButtonModule,
    MatInputModule,
    MatDialogModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatSelectModule,
    MatListModule,
    MatMenuModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatTabsModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatBadgeModule,
    //Angular generica tables
    GenericTableModule,
    ColumnSettingsModule,
    //ng2-dragula
    DragulaModule.forRoot(),
    //Prime ng
    FileUploadModule,
    GrowlModule,
    CalendarModule,
    ProgressBarModule,
    EditorModule,
    ButtonModule,
    //CKEditor
    CKEditorModule
  ],
  declarations: [
    EChartsDirective,
    SlimScrollDirective,
    OnlyNumbersDirective,
    OnlyDecimalNumbersDirective,
    FocusDirective,
    BlockCopyPasteDirective,
    BreadcrumComponent,
    ValueCardComponent,
    BoxDashboardComponent,
    SearchComponent,
    ToolBarComponent,
    DialogToolbarComponent,
    SelectRowLengthComponent,
    NoDataListComponent,
    NoDataComponent,
    LoadingDataComponent,
    LoadingListComponent,
    AppQuestionComponent,
    BoxDashboardMoneyComponent,
    TabHeaderComponent,
    DescargaAndamioComponent,
    ExamenVistaPreviaComponent,
    ExamenNoCopyVistaPreviaComponent,
    ExamenProfesorVistaPreviaComponent,
    TareaVistaPreviaComponent,
    TareaNoCopyVistaPreviaComponent,
    TareaProfesorVistaPreviaComponent,
    MyCKEditorComponent,
    DisplayMathMLComponent,
    //charts
    PieComponent,
    DoughnutComponent,
    HorizontalBarComponent,
    BasicAreaComponent,
    //dialogs
    ConfirmDialogComponent,
    AcceptDialogComponent,
    MyProgressBarComponent,
    FileNameDialogComponent,
    AcceptErrorDialogComponent,
    AcceptSuccesDialogComponent,
    //Expanded rows
    DetalleContactoDireccionComponent,
    DetalleDescripcionComponent,
    //Icons
    EditIconComponent,
    DeleteIconComponent,
    DownloadIconComponent,
    ChangePasswordIconComponent,
    PermissionsIconComponent,
    CopyIconComponent,
    ProductsIconComponent,
    UpdateImageIconComponent,
    RefreshIconComponent,
    DeactivateIconComponent,
    DetailIconComponent,
    HistoryIconComponent,
    ReactivosIconComponent,
    PreviewIconComponent,
    GrupoIconComponent,
    AlumnoIconComponent,
    //Custom
    CantidadComponent,
    TipoAndamioComponent,
    //Pipes
    SafeHTML,
    DateWSToDateApp
  ],
  exports: [
    //Angular
    FormsModule,
    HttpClientModule,
    TranslateModule,
    //Angular material
    MatIconModule,
    MatSlideToggleModule,
    MatButtonModule,
    MatInputModule,
    MatDialogModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatSelectModule,
    MatListModule,
    MatMenuModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatTabsModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatBadgeModule,
    //Angular generic tables
    GenericTableModule,
    ColumnSettingsModule,
    //ng2-dragula
    DragulaModule,
    //Prime ng
    FileUploadModule,
    GrowlModule,
    CalendarModule,
    ProgressBarModule,
    EditorModule,
    ButtonModule,
    CKEditorModule,
    //Directivas
    EChartsDirective,
    SlimScrollDirective,
    OnlyNumbersDirective,
    OnlyDecimalNumbersDirective,
    FocusDirective,
    BlockCopyPasteDirective,
    BreadcrumComponent,
    ValueCardComponent,
    BoxDashboardComponent,
    SearchComponent,
    ToolBarComponent,
    DialogToolbarComponent,
    SelectRowLengthComponent,
    NoDataComponent,
    NoDataListComponent,
    LoadingDataComponent,
    LoadingListComponent,
    AppQuestionComponent,
    BoxDashboardMoneyComponent,
    TabHeaderComponent,
    DescargaAndamioComponent,
    ExamenVistaPreviaComponent,
    ExamenNoCopyVistaPreviaComponent,
    ExamenProfesorVistaPreviaComponent,
    TareaVistaPreviaComponent,
    TareaNoCopyVistaPreviaComponent,
    TareaProfesorVistaPreviaComponent,
    MyCKEditorComponent,
    DisplayMathMLComponent,
    //charts
    PieComponent,
    DoughnutComponent,
    HorizontalBarComponent,
    BasicAreaComponent,
    //Dialogs
    ConfirmDialogComponent,
    AcceptDialogComponent,
    MyProgressBarComponent,
    FileNameDialogComponent,
    AcceptErrorDialogComponent,
    AcceptSuccesDialogComponent,
    //Expanded rows
    DetalleContactoDireccionComponent,
    DetalleDescripcionComponent,
    //Icons
    EditIconComponent,
    DeleteIconComponent,
    DownloadIconComponent,
    ChangePasswordIconComponent,
    PermissionsIconComponent, 
    CopyIconComponent,
    ProductsIconComponent,
    UpdateImageIconComponent,
    RefreshIconComponent,
    DeactivateIconComponent,
    DetailIconComponent,
    HistoryIconComponent,
    ReactivosIconComponent,
    PreviewIconComponent,
    GrupoIconComponent,
    AlumnoIconComponent,
    //Custom
    CantidadComponent,
    TipoAndamioComponent,
    //Pipes
    SafeHTML,
    DateWSToDateApp
  ],
  entryComponents: [
    //dialogs
    ConfirmDialogComponent,
    AcceptDialogComponent,
    MyProgressBarComponent,
    FileNameDialogComponent,
    AcceptErrorDialogComponent,
    AcceptSuccesDialogComponent,
    //Expanded rows
    DetalleContactoDireccionComponent,
    DetalleDescripcionComponent,
    //Icons
    EditIconComponent,
    DeleteIconComponent,
    DownloadIconComponent,
    ChangePasswordIconComponent,
    PermissionsIconComponent,
    CopyIconComponent,
    ProductsIconComponent,
    UpdateImageIconComponent,
    RefreshIconComponent,
    DeactivateIconComponent,
    DetailIconComponent,
    ReactivosIconComponent,
    PreviewIconComponent,
    GrupoIconComponent,
    AlumnoIconComponent,
    //Custom
    CantidadComponent,
    TipoAndamioComponent
  ]
})
export class MySharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: MySharedModule
    };
  }
}