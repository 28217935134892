import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestService } from '../general/request.service';
import { AreaDiciplinaria } from '../../clases/catalogos/area-diciplinaria';
import { Ordenamiento } from '../../clases/general/ordenamiento';

/**
 * AreaDiciplinariaService
 * @author MG
 */
@Injectable()
export class AreaDiciplinariaService {

    private urlAreasPaginadas: string = "/area/all";
    private urlAreas: string = "/area";
    private urlAreasCorto: string = "/area/enebled";
    private urlAreasCortoSistema: string = "/area/sistema";
    private urlAreasCortoPublico: string = "/areasDiciplinariasAll";
    private urlAreasId: string = "/area/xid";

    constructor(
        private requestService: RequestService
    ) { }
    
    /**
     * Obtener la lista 
     * @param {number} pagina 
     * @param {number} cantidadRegistros
     * @param {Ordenamiento} ordenamiento
     * @param {string} busqueda
     * @return {Observable<any>} 
     */
    public obtenerLista(pagina: number, cantidadRegistros: number, ordenamiento: Ordenamiento, busqueda: string): Observable<any> {
        var body = {
            busqueda: busqueda
        };
        var url = this.requestService.formarUrlPaginacion(this.urlAreasPaginadas, pagina, cantidadRegistros, ordenamiento);
        return this.requestService.post(url, body);
    }

    /**
     * Obtener la lista
     */
    public obtenerListaCorto(): Observable<any> {
        return this.requestService.post(this.urlAreasCorto, {});
    }

    /**
     * Obtener la lista
     * @param  {number} idSistema
     */
    public obtenerListaCortoSistema(idSistema: number): Observable<any> {
        var url = this.urlAreasCortoSistema + "/" + idSistema;
        return this.requestService.post(url, {});
    }

    /**
     * Obtener la lista
     * @param {number} idSistema
     */
    public obtenerListaCortoRegistro(idSistema: number): Observable<any> {
        var url = this.urlAreasCortoPublico + "/" + idSistema;
        return this.requestService._post(url, {});
    }

    /**
     * Crea
     * @param {AreaDiciplinaria} area
     * @return {Observable<any>} 
     */
    public crear(area: AreaDiciplinaria): Observable<any> {
        var body = {
            nombre: area.nombre,
            enabled: area.enabled,
            sistema: area.sistema
        };
        return this.requestService.post(this.urlAreas, body);
    }

    /**
     * Guardar
     * @param {AreaDiciplinaria} area
     * @return {Observable<any>}
     */
    public guardar(area: AreaDiciplinaria): Observable<any> {
        var body = {
            id: area.id,
            nombre: area.nombre,
            enabled: area.enabled,
            sistema: area.sistema
        };
        return this.requestService.put(this.urlAreas, body);
    }

    /**
     * Permite eliminar
     * @param  {number} id
     * @return {Observable<any>} 
     */
    public eliminar(id: number): Observable<any> {
        var url = this.urlAreas + "/" + id;
        return this.requestService.delete(url);
    }

    /**
     * Obtener por id
     * @param  {number} id
     * @return {Observable<any>} 
     */
    public obtenerPorId(id: number): Observable<any> {
        var url = this.urlAreasId + "/" + id;
        return this.requestService.post(url, {});
    }

} 