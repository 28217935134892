import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MensajeService } from './mensaje.service';

@Injectable()
export class ErrorService {

    public HttpType = {
        Generico: 0,
        Consulta: 1,
        Actualizacion: 2
    };

    constructor(
        private mensajeService: MensajeService
    ) { }

    public validate(error: HttpErrorResponse, httpType?: number): boolean {
        var aplicationError: boolean = false;
        if (error) {
            //Verifica que se indico el tipo de request 
            if (httpType === null) {
                httpType = this.HttpType.Generico;
            }
            //No existe una conexion
            if (error.status === 0) {
                this.mensajeService.enviar("E00000", "Error", 1);
            }
            else if (error.status === 1) {
                //this.mensajeService.enviar("I00011", "Informacion", 3);
                aplicationError = false;
                return aplicationError;
            }
            else if (error.status === 500 || error.status === 400) { //Error enviado por el servidor
                //Revisa si el error contiene un mensaje o es un error generico
                if (error.error && error.error != "INTERNAL_SERVER_ERROR") {
                    aplicationError = true;
                    return aplicationError;
                }
                else {
                    var msj = "";
                    //Verifica para que accion se realizo el HttpRequest y envia el mensaje correcto;
                    switch (httpType) {
                        case this.HttpType.Generico:
                            msj = "E00001";
                            break;
                        case this.HttpType.Consulta:
                            msj = "E00002";
                            break;
                        case this.HttpType.Actualizacion:
                            msj = "E00003";
                            break;
                        default:
                            msj = "E00001";
                            break;
                    }
                    this.mensajeService.enviar(msj, "Error", 1);
                }
            }
            else {
                this.mensajeService.enviar("E00001", "Error", 1);
            }
        }
        return aplicationError;
    }    
    
}