import { Component} from '@angular/core';
import { GtCustomComponent } from '@angular-generic-table/core';

/**
 * PermissionsIconComponent
 * @author MG
 */
@Component({
    selector: 'permissions-icon',
    templateUrl: './permissions-icon.component.html'
})
export class PermissionsIconComponent extends GtCustomComponent<any>  {

    constructor() {
        super();
    }
}