import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
//General
import { LocalStorangeService } from './servicios/general/local-storange.service';
import { SessionStorangeService } from './servicios/general/session-storange.service';
import { MensajeService } from './servicios/general/mensaje.service';
import { AngularGenericTableService } from './servicios/general/angular-generic-table.service';
import { GeneralService } from './servicios/general/general.service';
import { RequestService } from './servicios/general/request.service';
import { ToolbarService } from './servicios/general/toolbar.service';
import { ErrorService } from './servicios/general/error.service';
import { LayoutService } from './servicios/general/layout.service';
import { GraficService } from './servicios/general/grafic.service';
import { DateService } from './servicios/general/date.service';
import { ImagenService } from './servicios/general/imagen.service';
import { FileService } from './servicios/general/file.service';
import { JsonConverterService } from './servicios/general/json-converter.service';
import { DrawService } from './servicios/general/draw.service';
//Configuracion
import { ConfiguracionWebService } from './servicios/configuracion/configuracion-web.service';
import { LanguageService } from './servicios/configuracion/language-service';
//Seguridad
import { RefreshingSessionService } from './servicios/seguridad/refreshing-session.service';
import { PerfilService } from './servicios/seguridad/perfil.service';
import { UsuarioService } from './servicios/seguridad/usuario.service';
import { LoginService } from './servicios/seguridad/login.service';
import { AuthService } from './servicios/seguridad/auth.service';
import { RecuperarInfoService } from './servicios/seguridad/recuperar-info.service';
import { RestaurarContrasenaService } from './servicios/seguridad/restaurar-contrasena.service';
import { MenuService } from './servicios/seguridad/menu.service';
import { PermisoService } from './servicios/seguridad/permisos.service';
import { CodigoProfesorService } from './servicios/seguridad/codigo-profesor.service';
import { ProfesorService } from './servicios/seguridad/profesor.service';
import { AlumnoService } from './servicios/seguridad/alumno.service';
//Dashboard
import { DashboardService } from './servicios/dashboard/dashboard.service';
//Catalogos
import { AreaDiciplinariaService } from './servicios/catalogos/area-diciplinaria.service';
import { VideoTutorialService } from './servicios/catalogos/video-tutorial.service';
import { PlantelService } from './servicios/catalogos/plantel.service';
import { TurnoService } from './servicios/catalogos/turno.service';
import { MateriaService } from './servicios/catalogos/materia.service';
import { SistemaService } from './servicios/catalogos/sistema.service';
import { CicloService } from './servicios/catalogos/ciclo.service';
import { BloqueService } from './servicios/catalogos/bloque.service';
import { AnioService } from './servicios/catalogos/anio.service';
import { AndamioService } from './servicios/catalogos/andamio.service';
//Administracion
import { GrupoService } from './servicios/administracion/grupo.service';
import { CodigoLibroService } from './servicios/administracion/codigo-libro.service';
import { AuxiliarService } from './servicios/administracion/auxiliar.service';
//Actividades
import { ExamenService } from './servicios/actividades/examen.service';
import { TareaService } from './servicios/actividades/tarea.service';
import { PreguntaExamenService } from './servicios/actividades/pregunta-examen.service';
import { PreguntaTareaService } from './servicios/actividades/pregunta-tarea.service';
import { ExamenProfesorService } from './servicios/actividades/examen-profesor.service';
import { PreguntaExamenProfesorService } from './servicios/actividades/pregunta-examen-profesor.service';
import { AlumnoExamenService } from './servicios/actividades/alumno-examen.service';
import { TareaProfesorService } from './servicios/actividades/tarea-profesor.service';
import { PreguntaTareaProfesorService } from './servicios/actividades/pregunta-tarea-profesor.service';
import { AlumnoTareaService } from './servicios/actividades/alumno-tarea.service';
//chat
import { ChatService } from './servicios/chat/chat.service';
import { ChatGrupoService } from './servicios/chat/chat-grupo.service';
//reportes
import { ReporteCodigosLibrosService } from './servicios/reporte/reporte-codigo.service';
//Forms
import { QuestionControlService } from './servicios/forms/question-control.service';
//Layout
import { FiltroGrupoService } from './servicios/layout/filtro-grupo.sevice';
//Guards
import { AuthGuard } from './guards/auth-guard';
import { AuthUserGuard } from './guards/auth-user-guard';
import { AuthUserRouteGuard } from './guards/auth-user-route-guard';
import { AuthAdminGuard } from './guards/auth-admin-guard';
import { PublicGuard } from './guards/public-guard';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule
    ],
    providers: [
        //Guards
        AuthGuard,
        AuthUserGuard,
        AuthUserRouteGuard,
        AuthAdminGuard,
        PublicGuard,
        //General
        LocalStorangeService,
        SessionStorangeService,
        MensajeService,
        AngularGenericTableService,
        GeneralService,
        RequestService,
        AuthService,
        ToolbarService,
        LayoutService,
        ErrorService,
        RefreshingSessionService,
        GraficService,
        DateService,
        ImagenService,
        FileService,
        JsonConverterService,
        DrawService,
        //Configuracion
        ConfiguracionWebService,
        LanguageService,
        //Seguridad
        PerfilService,
        UsuarioService,
        LoginService,
        RecuperarInfoService,
        RestaurarContrasenaService,
        MenuService,
        PermisoService,
        CodigoProfesorService,
        ProfesorService,
        AlumnoService,
        //Dashboard
        DashboardService,
        //Catalogos
        AreaDiciplinariaService,
        VideoTutorialService,
        PlantelService,
        TurnoService,
        MateriaService,
        SistemaService,
        AndamioService,
        CicloService,
        BloqueService,
        AnioService,
        //Administracion
        GrupoService,
        CodigoLibroService,
        AuxiliarService,
        //Actividades
        ExamenService,
        TareaService,
        PreguntaExamenService,
        PreguntaTareaService,
        ExamenProfesorService,
        PreguntaExamenProfesorService,
        AlumnoExamenService,
        TareaProfesorService,
        PreguntaTareaProfesorService,
        AlumnoTareaService,
        //chat
        ChatService,
        ChatGrupoService,
        //Reportes
        ReporteCodigosLibrosService,
        //Forms
        QuestionControlService,
        //layout
        FiltroGrupoService
    ]
})
export class CoreModule { 
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: CoreModule
        };
    }
}