import { RouterModule, Routes } from '@angular/router';


const AppRoutes: Routes = [
  {
    path: '',
    loadChildren: './layout/layout.module#LayoutModule',
    pathMatch: 'full'
  },
  { path: 'login', loadChildren: './login/login.module#LoginModule' },
  { path: 'not-found', loadChildren: './not-found/not-found.module#NotFoundModule' },
  { path: 'registro-alumno', loadChildren: './registro-alumno/registro.module#RegistroModule' },
  { path: 'registro-profesor', loadChildren: './registro-profesor/registro.module#RegistroModule' },
  { path: '**', redirectTo: 'not-found' }
];

export const AppRoutingModule = RouterModule.forRoot(AppRoutes, { useHash: true });

