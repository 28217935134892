import { Component, Input, OnInit } from '@angular/core';
import { ROUTES } from '../../config/routes';
import { Breadcrum } from './breadcrum';

@Component({
    selector: 'app-breadcrum',
    templateUrl: './breadcrum.component.html',
    
})
export class BreadcrumComponent {

    @Input() titulo: string;
    @Input() navegacion: Breadcrum[];
    @Input() actual: string;

    private principal: string = "";

    constructor() {
        this.principal = ROUTES.default + ROUTES.principal;
    }

}
