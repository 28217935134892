import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestService } from '../general/request.service';
import { AuthService } from './auth.service';
import { Perfil } from '../../clases/seguridad/perfil';
import { Accion } from '../../clases/seguridad/accion';
import { AsignarPermisoDto } from '../../clases/seguridad/asignar-permiso-dto';

/**
 * PermisoService
 * @author MG
 */
@Injectable()
export class PermisoService {

    private urlPermisos: string = "/permisos";
    private urlPermisosConsulta: string = "/permisos/corto";
    private urlPermisosConsultaAdmin: string = "/permisos/corto/admin";
    private urlPermisosAsignar: string = "/permisos/asignar";
    private urlPermisosUrlAccion: string = "/permisos/idPermisoXUrl";
    private urlPermisosUrlMenu: string = "/permisos/idPermisoXUrlMenu";
    
    constructor(
        private requestService: RequestService,
        private authService: AuthService
    ) { }

    /**
     * Verifica si el perfil tiene permiso a la ruta indicada
     * @param {number} idPerfil Id del perfil firmado
     * @param {string} ruta Ruta a la que se desea acceder
     * @returns {Observable<any>}
     */
    public tienePermisoRuta(ruta: string): Observable<any> {
        var usuario = this.authService.obtenerUsuario();
        var body = {
            idPerfil: usuario.perfil.id,
            url: ruta
        };
        return this.requestService.post(this.urlPermisosUrlAccion, body);
    }

    /**
     * Obtiene los permisos para el usuario logueado sobre las acciones del menu con la url indicada
     * @param {string} urlMenu Url del menu para buscar los permisos de las acciones asociadas a ese menu
     * @returns {Observable<any>}
     */
    public obtenerPorMenu(urlMenu: string): Observable<any> {
        var usuario = this.authService.obtenerUsuario();
        var body = {
            url: urlMenu,
            idPerfil: usuario.perfil.id
        };
        return this.requestService.post(this.urlPermisosUrlMenu, body);
    }

    /**
     * Obtiene los permisos por el Id del perfil
     * @param {number} idPerfil
     * @returns {Observable<any>}
     */
    public obtenerPorPerfil(idPerfil: number): Observable<any> {
        var url = this.urlPermisosConsulta + "/" + idPerfil;
        return this.requestService.get(url);
    }

    /**
     * Obtiene los permisos por el Id del perfil
     * @param {number} idPerfil
     * @returns {Observable<any>}
     */
    public obtenerPorPerfilAdmin(idPerfil: number): Observable<any> {
        var url = this.urlPermisosConsultaAdmin + "/" + idPerfil;
        return this.requestService.get(url);
    }

    /**
     * Crea un nuevo permiso para el perfil y la accion indicada
     * @param {Perfil} perfil Perfil al cual se le quiere asignar el permiso
     * @param {Accion} accion Accion del permiso
     * @returns {Observable<any>}
     */
    public crearPermiso(perfil: Perfil, accion: Accion): Observable<any> {
        var body = {
            enabled: true,
            perfil: {
                id: perfil.id
            },
            acciones : {
                id: accion.id
            }
        }
        return this.requestService.post(this.urlPermisos, body);
    }

    /**
     * Permite eliminar el permiso indicado
     * @param {number} id Id del permiso
     * @return {Observable<any>} Response
     */
    public eliminarPermiso(id: number): Observable<any> {
        var url = this.urlPermisos + "/" + id;
        return this.requestService.delete(url);
    }

    /**
     * Asigna o desasignar una lista de permisos
     * @param {Array<AsignarPermisoDto>} permisos
     * @return {Observable<any>} Response
     */
    public asignarPermisos(permisos: Array<AsignarPermisoDto>): Observable<any> {
        var body = permisos;
        return this.requestService.post(this.urlPermisosAsignar, body);
    }

}