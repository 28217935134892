import { Component, Input, OnInit } from '@angular/core';
import { CHARTCONFIG } from '../charts.config';
import { MAXIMO_ITEMS_LABELS } from '../../../config/constants';

@Component({
    selector: 'doughnut-chart',
    templateUrl: './doughnut.component.html'
})
export class DoughnutComponent implements OnInit {

    @Input() legend: boolean = false;
    @Input() saveAsImage: boolean = true;
    @Input() data: Array<any> = [];
    @Input() textData: Array<any> = [];
    @Input() style: string = ""
    @Input() titleTooltip: string = "";
    @Input() title: string = "";
    
    config = CHARTCONFIG;
    
    options = {
        title: {
            show: false,
            text: '',
            textStyle: {
                color: this.config.textColor,
                fontSize: 14,
                fontWeight: 400,
                padding: 0,
                top: 0
            }
        },
        tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
            show: false,
            type: 'scroll',
            orient: 'vertical',
            x: 'left',
            data: [],
            textStyle: {
                color: this.config.textColor
            },
            pageIconColor: this.config.button
        },
        toolbox: {
            show: true,
            feature: {
                saveAsImage: { show: true, title: ' ', name: this.config.nombreArchivoDescarga }
            },
            bottom: 30
        },
        calculable: true,
        color: this.config.colors,
        grid: {},
        series: []
    };

    /**
     * 
     */
    public ngOnInit(): void {
        var mostrarEtiqueta = true;
        //Verificar si se mostraran las leyendas
        if (this.legend || this.data.length > MAXIMO_ITEMS_LABELS) {
            this.options.legend.show = true;
            mostrarEtiqueta = false;
        }
        this.options.legend.data = this.data;
         //Verificar si se especifico un titulo
        if (this.title) {
            this.options.title.text = this.title;
            this.options.title.show = true;
        }
        //Verificar configuracion de guardar imagen
        this.options.toolbox.feature.saveAsImage.show = this.saveAsImage;
         //Definir datos
        this.options.series = [
            {
                name: this.titleTooltip,
                type: 'pie',
                radius: ['40%', '60%'],
                data: this.data,
                label: {
                    normal: {
                        show: mostrarEtiqueta
                    }
                }
            }
        ];
    }

}
