import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestService } from '../general/request.service';
import { Ordenamiento } from '../../clases/general/ordenamiento';
import { Plantel } from '../../clases/catalogos/plantel';

/**
 * PlantelService
 * @author MG
 */
@Injectable()
export class PlantelService {

    private urlPlantelesPagiancion: string = "/planteles/all";
    private urlPlanteles: string = "/planteles";
    private urlPlantelesCorto: string = "/planteles/notDelate";
    private urlPlantelesCortoSistemaPublico: string = "/plantelesAll";
    private urlPlantelesCortoPublico: string = "/plantelByProfesor";

    constructor(
        private requestService: RequestService
    ) { }
    
    /**
     * Obtener la lista 
     * @param {number} pagina 
     * @param {number} cantidadRegistros
     * @param {Ordenamiento} ordenamiento
     * @param {string} busqueda
     * @return {Observable<any>} response
     */
    public obtenerLista(pagina: number, cantidadRegistros: number, ordenamiento: Ordenamiento, busqueda: string): Observable<any> {
        var body = {
            busqueda: busqueda
        };
        var url = this.requestService.formarUrlPaginacion(this.urlPlantelesPagiancion, pagina, cantidadRegistros, ordenamiento);
        return this.requestService.post(url, body);
    }

    /**
     * Obtener la lista
     * @param {number} idSistema
     */
    public obtenerListaCortoPorSistema(idSistema: number): Observable<any> {
        var url = this.urlPlantelesCorto + "/" + idSistema;
        return this.requestService.post(url, {});
    }

    /**
     * Obtener la lista
     * @param {number} idSistema
     */
    public obtenerListaCortoRegistroPorProfesor(codigo: string): Observable<any> {
        var body = {
            codigo: codigo
        };
        return this.requestService._post(this.urlPlantelesCortoPublico, body);
    }

    /**
     * Obtener la lista
     * @param {number} idSistema
     */
    public obtenerListaCortoRegistroSistema(idSistema: number): Observable<any> {
        var url = this.urlPlantelesCortoSistemaPublico + "/" + idSistema;
        return this.requestService._post(url, {});
    }

    /**
     * Crea
     * @param {Plantel} plantel
     * @return {Observable<any>} 
     */
    public crear(plantel: Plantel): Observable<any> {
        var body = {
            nombre: plantel.nombre,
            sistema: plantel.sistema
        };
        return this.requestService.post(this.urlPlanteles, body);
    }

    /**
     * Guardar
     * @param {Plantel} plantel
     * @return {Observable<any>}
     */
    public guardar(plantel: Plantel): Observable<any> {
        var body = {
            id: plantel.id,
            nombre: plantel.nombre,
            sistema: plantel.sistema
        };
        return this.requestService.put(this.urlPlanteles, body);
    }

    /**
     * Permite eliminar
     * @param  {number} id
     * @return {Observable<any>} 
     */
    public eliminar(id: number): Observable<any> {
        var url = this.urlPlanteles + "/" + id;
        return this.requestService.delete(url);
    }

} 