import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestService } from '../general/request.service';
import { Ordenamiento } from '../../clases/general/ordenamiento';
import { Profesor } from '../../clases/seguridad/profesor';

/**
 * ProfesorService
 * @author MG
 */
@Injectable()
export class ProfesorService {

    private urlProfesoresConsulta: string = "/profesores/all";
    private urlProfesores: string = "/profesores";
    private urlProfesoresAlta: string = "/addProfesor";
    private urlProfesoresPorId: string = "/profesores/xid";
    private urlProfesoresPorUserId: string = "/profesores/user";
    private urlProfesorerNombre: string = "/infoProfesor";
    private urlCodigosConsulta: string = "/profesorCodigo";
    private urlProfesoresConsultaExportar: string = "/profesores/all/export";
    
    constructor(
        private requestService: RequestService
    ) { }

    /**
     * Obtener la lista de los profesores
     * @param {number} pagina 
     * @param {number} cantidadRegistros
     * @param {Ordenamiento} ordenamiento
     * @param {string} busqueda
     * @return {Observable<any>} response
     */
    public obtenerLista(pagina: number, cantidadRegistros: number, ordenamiento: Ordenamiento, busqueda: string): Observable<any> {
        var body = {
            busqueda: busqueda
        };
        var url = this.requestService.formarUrlPaginacion(this.urlProfesoresConsulta, pagina, cantidadRegistros, ordenamiento);
        return this.requestService.post(url, body);
    }

    /**
    * Obtener la lista de los profesores para exportar
    * @param {Ordenamiento} ordenamiento
    * @param {string} busqueda
    * @return {Observable<any>} response
    */
    public obtenerListaExportar(ordenamiento: Ordenamiento, busqueda: string): Observable<any> {
        var body = {
            busqueda: busqueda
        };
        var url = this.requestService.formarUrlPaginacion(this.urlProfesoresConsultaExportar, 0, 0, ordenamiento);
        return this.requestService.post(url, body);
    }

    /**
     * Obtener la informacion del profesor indicado
     * @param {number} id Id del profesor
     * @return {Observable<any>} Response
     */
    public obtenerPorId(id: number): Observable<any> {
        var url = this.urlProfesoresPorId + "/" + id;
        var body = {
        };
        return this.requestService.post(url, body);
    }

    /**
     * Obtener la informacion del profesor indicado
     * @return {Observable<any>} Response
     */
    public obtenerPorUserId(): Observable<any> {
        return this.requestService.get(this.urlProfesoresPorUserId);
    }

    /**
     * Crea un nuevo profesor
     * @param {Profesor} profesor
     * @return {Observable<any>} Response
     */
    public crear(profesor: Profesor): Observable<any> {
        var body = {
            codigoProfesor: profesor.codigoProfesor,
            planteles: profesor.planteles,
            materias: profesor.materias,
            sistemas: profesor.sistemas,
            turno: profesor.turno,
            user: {
                username: profesor.user.username,
                nombre: profesor.user.nombre,
                apellidos: profesor.user.apellidos,
                telefono: profesor.user.telefono,
                celular: profesor.user.celular,
                email: profesor.user.email,
                enabled: profesor.user.enabled,
                password: profesor.user.password
            }
        };
        return this.requestService._post(this.urlProfesoresAlta, body);
    }

    /**
     * Guardar la informacion de un profesor
     * @param {Profesor} profesor
     * @return {Observable<any>} Response
     */
    public guardar(profesor: Profesor): Observable<any> {
        var body = {
            id: profesor.id,
            codigoProfesor: profesor.codigoProfesor,
            planteles: profesor.planteles,
            materias: profesor.materias,
            sistemas: profesor.sistemas,
            turno: profesor.turno,
            user: {
                id: profesor.user.id,
                username: profesor.user.username,
                nombre: profesor.user.nombre,
                apellidos: profesor.user.apellidos,
                telefono: profesor.user.telefono,
                celular: profesor.user.celular,
                email: profesor.user.email,
                enabled: profesor.user.enabled,
                password: profesor.user.password
            }
        };
        return this.requestService.put(this.urlProfesores, body);
    }

    /**
     * Permite eliminar el profesor indicado
     * @param {number} id
     * @return {Observable<any>} Response
     */
    public eliminar(id: number): Observable<any> {
        var url = this.urlProfesores + "/" + id;
        return this.requestService.delete(url);
    }

    /**
     * Obtiene el nombre del profesor por su clave
     * @param {string} clave
     */
    public consultarNombre(clave: string): Observable<any> {
        var body = {
            codigo: clave
        }
        return this.requestService._post(this.urlProfesorerNombre, body);
    }

    /**
     * Consulta el nombre
     * @param codigo 
     */
    public consultarNombreRegistro(codigo: string): Observable<any> {
        var body = {
            codigo: codigo
        };
        return this.requestService._post(this.urlCodigosConsulta, body);
    }
    
}