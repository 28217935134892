import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, timer } from 'rxjs';
import { AuthService } from '../core/servicios/seguridad/auth.service';
import { ROUTES } from '../config/routes';
import { APPCONFIG } from '../config';
import { Usuario } from '../core/clases/seguridad/usuario';
import { ConfiguracionWeb } from '../core/clases/configuracion/configuracion-web';
import { ConfiguracionWebService } from '../core/servicios/configuracion/configuracion-web.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'layout',
  templateUrl: './layout.component.html',
})
export class LayoutComponent implements OnInit, OnDestroy {
   
  AppConfig = APPCONFIG;

  private timer: any;
  private subscriptionTimer: Subscription;
  private timerToken: any;
  private subscriptionTimerToken: Subscription;
  
  private usuario: Usuario;
  private config: ConfiguracionWeb;

  constructor(
    private translate: TranslateService,
    private router: Router,
    private authService: AuthService,
    private configuracionWebService: ConfiguracionWebService,
  ) { 
    this.usuario = this.authService.obtenerUsuario();
  }

  /**
   * Inicializar componente
   */
  public ngOnInit(): void {
    this.configuracionWebService.obtener(this.usuario.id).subscribe(
      res => {
        if (res.body) {
          this.config = res.body;
          this.translate.use(this.config.idioma);
        }
      },
      (error: HttpErrorResponse) => {
      });
    //Verifica que aun siga logueado el usuario cada 10 segundos
    this.timer = timer(1000, 10 * 1000);
    //Actualiza el token cada 15 MIN
    this.timerToken = timer(900 * 1000, 900 * 1000);
    this.subscriptionTimer = this.timer.subscribe(t => {
      if (!this.authService.estaAutentificado()) {
        this.cerrarSesion();
      }
    }); 
    this.subscriptionTimerToken = this.timerToken.subscribe(t => {
      //console.log("Actualizar token");
    }); 
  }

  /**
   * Destruir Componente
   */
  public ngOnDestroy(): void {
    if (this.subscriptionTimer) {
      this.subscriptionTimer.unsubscribe();
    } 
    if (this.subscriptionTimerToken) {
      this.subscriptionTimerToken.unsubscribe();
    } 
  }

  /**
   * Cierra sesion
   */
  private cerrarSesion(): void {
    this.authService.limpiarInfoAuth();
    this.router.navigate([ROUTES.validarUsuario]);
  }

}