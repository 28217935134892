import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestService } from '../general/request.service';
import { Ordenamiento } from '../../clases/general/ordenamiento';
import { Tarea } from '../../clases/actividades/tarea';
import { Grupo } from '../../clases/administracion/grupos';
import { PreguntaTarea } from '../../clases/actividades/pregunta-tarea';

/**
 * TareaService
 * @author MG
 */
@Injectable()
export class TareaService {

    private urlTareasPagiancion: string = "/tarea/all";
    private urlTareasMateria: string = "/tarea/XMateria";
    private urlTareas: string = "/tarea";
    private urlTareasGenerar: string = "/tarea/generar";
    private urlTareasActivar: string = "/tarea/ActivarTarea";

    constructor(
        private requestService: RequestService
    ) { }
     
    /**
     * Obtener la lista 
     * @param {number} pagina 
     * @param {number} cantidadRegistros
     * @param {Ordenamiento} ordenamiento
     * @param {string} busqueda
     * @return {Observable<any>} response
     */
    public obtenerLista(pagina: number, cantidadRegistros: number, ordenamiento: Ordenamiento, busqueda: string): Observable<any> {
        var body = {
            busqueda: busqueda
        };
        var url = this.requestService.formarUrlPaginacion(this.urlTareasPagiancion, pagina, cantidadRegistros, ordenamiento);
        return this.requestService.post(url, body);
    }

    /**
     *  Obtener la lista por materia para profesor
     * @param {string} busqueda
     * @return {Observable<any>}
     */
    public obtenerListaPlantillasMateria(idMateria: number): Observable<any> {
        var body = {
            id: idMateria
        };
        return this.requestService.post(this.urlTareasMateria, body);
    }

    /**
     * Crea
     * @param {Tarea} tarea
     * @return {Observable<any>} 
     */
    public crear(tarea: Tarea): Observable<any> {
        var body = {
            nombre: tarea.nombre,
            bloque: tarea.bloque,
            contenido: tarea.contenido
        };
        return this.requestService.post(this.urlTareas, body);
    }

    /**
     * Guardar
     * @param {Tarea} tarea
     * @return {Observable<any>}
     */
    public guardar(tarea: Tarea): Observable<any> {
        var body = {
            id: tarea.id,
            nombre: tarea.nombre,
            bloque: tarea.bloque,
            contenido: tarea.contenido
        };
        return this.requestService.put(this.urlTareas, body);
    }

    /**
     * Permite eliminar
     * @param  {number} id
     * @return {Observable<any>} 
     */
    public eliminar(id: number): Observable<any> {
        var url = this.urlTareas + "/" + id;
        return this.requestService.delete(url);
    }

    /**
     * Obtener por id
     * @param {number} id 
     */
    public obtenerPorId(id: number): Observable<any> {
        var url = this.urlTareas + "/" + id;
        return this.requestService.get(url);
    }

    /**
     * Genera una vista previa para el examen
     * @param {number} idTarea
     * @param {number} numero
     */
    public generarTarea(idTarea: number, numero: number): Observable<any> {
        var body = {
            tarea: {
                id: idTarea
            },
            numero: numero
        };
        return this.requestService.post(this.urlTareasGenerar, body);
    }

    /**
     * Activa un examen para el profesor
     * @param {number} idTarea
     * @param {Array<Grupo>} grupos
     * @param {Array<PreguntaTarea>} preguntas
     */
    public activarTarea(idTarea: number, grupos: Array<Grupo>, preguntas: Array<PreguntaTarea>): Observable<any> {
        var body = {
            tarea: {
                id: idTarea
            },
            grupos: grupos,
            preguntaTareaDtos: preguntas
        };
        return this.requestService.post(this.urlTareasActivar, body);
    }

} 