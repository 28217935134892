import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfiguracionWeb } from '../../clases/configuracion/configuracion-web';
import { Usuario } from '../../clases/seguridad/usuario';
import { RequestService } from '../general/request.service';
import { LocalStorangeService } from '../general/local-storange.service';
import { IDIOMADEFAULT, TEMADEFAULT } from '../../../config/constants';

/**
 * ConfiguracionWebService
 * @author MG
 */
@Injectable()
export class ConfiguracionWebService {

    private variableIdioma = "B_Idioma"
    private urlConfiguracion = "/configuracionWeb";
    private urlConfiguracionConsulta = "/configuracionWeb/consulta";

    constructor(
        private requestService: RequestService,
        private localStorangeService: LocalStorangeService
    ) { }

    /**
     * Obtiene la configuracion de la aplicacion web para el usuario con id indicado.
     * @param {number} idUsuario Id del usuario para el cual se desea obtener la configuracion
     * @returns {Observable<any>}
     */
    public obtener(idUsuario: number): Observable<any> {
        var body = {
            id_user: idUsuario
        };
        return this.requestService.post(this.urlConfiguracionConsulta, body);
    }

    /**
     * Obtiene la configuracion por default para la aplicacion
     */
    public obtenerConfiguracionDefault(): ConfiguracionWeb {
        var config = new ConfiguracionWeb();
        config.id = null;
        config.idioma = IDIOMADEFAULT;
        config.encabezado = true;
        config.contraer = false;
        config.tema = TEMADEFAULT;
        config.color = '31';
        config.user = new Usuario();
        return config;
    }

    /**
     * Crea una configuracion nueva para el usuario
     * @param {ConfiguracionWeb} config
     * @returns {Observable<any>}
     */
    public crear(config: ConfiguracionWeb): Observable<any> {
        var body = {
            idioma: config.idioma,
            encabezado: config.encabezado,
            contraer: config.contraer,
            tema: config.tema,
            color: config.color,
            user: {
                id: config.user.id
            }
        };
        return this.requestService.post(this.urlConfiguracion, body);
    }

     /**
     * Actualiza la configuracion
     * @param {ConfiguracionWeb} config
     * @returns {Observable<any>}
     */
    public actualizar(config: ConfiguracionWeb): Observable<any> {
        var body = {
            id: config.id,
            idioma: config.idioma,
            encabezado: config.encabezado,
            contraer: config.contraer,
            tema: config.tema,
            color: config.color,
            user: {
                id: config.user.id
            }
        };
        return this.requestService.put(this.urlConfiguracion, body);
    }

    /**
     * Almacena el ultimo idioma que fue guardado en la aplicacion
     * @param {string} idioma
     */
    public guardarUltimoIdioma(idioma: string): void {
        this.localStorangeService.guardar(this.variableIdioma, idioma);
    }

    /**
     * Almacena el ultimo idioma que fue guardado en la aplicacion
     * @returns {string}
     */
    public obtenerUltimoIdioma(): string {
        return this.localStorangeService.obtener(this.variableIdioma);
    }
}