import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login.component';
import { ValidacionUsuarioComponent } from './validacion-usuario/validacion-usuario.component';
import { ValidacionContrasenaComponent } from './validacion-contrasena/validacion-contrasena.component';
import { RecuperarContrasenaComponent } from './recuperar-contrasena/recuperar-contrasena.component';
import { RecuperarUsuarioComponent } from './recuperar-usuario/recuperar-usuario.component';
import { RestaurarContrasenaComponent } from './restaurar-contrasena/restaurar-contrasena.component';
import { PublicGuard } from '../core/guards/public-guard';

const routes: Routes = [
    {
        path: '',
        component: LoginComponent,
        children: [
            { path: '', component: ValidacionUsuarioComponent, pathMatch: "full", canActivate: [PublicGuard] },
            { path: 'validar', component: ValidacionContrasenaComponent, canActivate: [PublicGuard] },
            { path: 'recuperarContrasena', component: RecuperarContrasenaComponent, canActivate: [PublicGuard] },
            { path: 'recuperarUsuario', component: RecuperarUsuarioComponent, canActivate: [PublicGuard] },
            { path: 'restablecer/:token', component: RestaurarContrasenaComponent, canActivate: [PublicGuard] }
        ]
    }
];
@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class LoginRoutingModule { } 