import { Usuario } from '../seguridad/usuario';

/**
 * ConfiguracionWeb
 * @author MG
 * 
 */
export class ConfiguracionWeb {
    id: number;
    idioma: string;
    encabezado: boolean;
    contraer: boolean;
    tema: string;
    color: string;
    user: Usuario;

    constructor() {
        this.id = null;
        this.idioma = "";
        this.encabezado = true;
        this.contraer = false;
        this.tema = "";
        this.color = null;
        this.user = new Usuario();
    }
}