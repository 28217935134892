import { Component} from '@angular/core';
import { GtCustomComponent } from '@angular-generic-table/core';

/**
 * ReactivosIconComponent
 * @author MG
 */
@Component({
    selector: 'reactivos-icon',
    templateUrl: './reactivos-icon.component.html'
})
export class ReactivosIconComponent extends GtCustomComponent<any>  {

    constructor() {
        super();
    }
    
}