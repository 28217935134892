import { Directive, ElementRef, Input, OnInit, Renderer } from '@angular/core';

/**
 * FocusDirective
 * @author MG
 */
@Directive({ selector: '[myFocus]' })
export class FocusDirective implements OnInit {

    @Input('myFocus') isFocused: boolean;

    constructor(private hostElement: ElementRef, private renderer: Renderer) { }

    /**
     * Inicializar la directiva
     */
    public ngOnInit(): void {
        if (this.isFocused) {
            this.renderer.invokeElementMethod(this.hostElement.nativeElement, 'focus');
        }
    }
}