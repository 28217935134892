import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject} from 'rxjs';

/**
 * GraficService
 * @author MG
 */
@Injectable()
export class GraficService {

  private echartsSource: BehaviorSubject<boolean> = new BehaviorSubject(true);

  /**
   * Indica si hay que actualizar el tamaño d euna grafica
   * @param state 
   */
  public updateEChartsState(state: boolean) {
    this.echartsSource.next(state);
  }

  /**
   * Obtiene el Observable para verificar cuando se necesite actualizar el tamaño de una grafica
   * @return {Observable<boolean>}
   */
  public getUpdateEChartsState(): Observable<boolean> {
    return this.echartsSource.asObservable();
  }

}
