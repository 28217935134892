import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AngularGenericTableService } from '../../core/servicios/general/angular-generic-table.service';
import { REGISTROPORPAGINADEFAULT } from '../../config/constants';

/**
 * SelectRowLengthComponent
 * @author MG
 */
@Component({
    selector: 'select-row-length',
    templateUrl: './select-row-length.component.html'
})
export class SelectRowLengthComponent implements OnInit {
    
    @Input() registros: number = REGISTROPORPAGINADEFAULT;
    
    @Output() oChangeRowLength: EventEmitter<number> = new EventEmitter();

    private listaCantidadRegistros: Array<number> = [];


    constructor(
        private angularGenericTableService: AngularGenericTableService
    ) {

    }

    /**
     * Inicializar el componente
     */
    public ngOnInit(): void {
        this.listaCantidadRegistros = this.angularGenericTableService.obtenerListaCantidadRegistros();
    }

    /**
     * Envia el evento al componente padre para indicar que se cambio el numero de filas a mostrar para la tabla
     */
    private changeRowLength($event: number): void {
        this.oChangeRowLength.emit($event);
    }

}