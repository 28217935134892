import { Injectable } from '@angular/core';
import { RequestService } from '../general/request.service';
import { AuthService } from '../seguridad/auth.service';
import { Observable } from 'rxjs';

@Injectable()
export class DashboardService {

    private totalesOperacion: string = "/dashboard/";

    constructor(
        private requestService: RequestService,
        private authService: AuthService
    ) { }

    
}