import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject} from 'rxjs';

/**
 * LayoutService
 * @author MG
 */
@Injectable()
export class LayoutService {

  private updateUserImage = new BehaviorSubject<boolean>(false);
  private updateGrupos = new BehaviorSubject<boolean>(false);
  private updateHeaderLabelGroup = new BehaviorSubject<boolean>(false);

  
  /**
   * Indica si hay que actualizar el usuario firmado
   * @param { boolean } state Indica si hay que actualizar el usuario
   */
  public setUpdateUser(state: boolean): void {
    this.updateUserImage.next(state);
  }

  /**
   * Obtiene el valor que permite saber si hay que actualizar el usuario firmado
   * @returns {Observable<boolean>}
   */
  public getUpdateUser(): Observable < boolean > {
    return this.updateUserImage.asObservable();
  }
  
  /**
  * Indica si hay que actualizar el usuario firmado
  * @param { boolean } state Indica si hay que actualizar el usuario
  */
  public setUpdateGrupos(state: boolean): void {
    this.updateGrupos.next(state);
  }

  /**
   * Obtiene el valor que permite saber si hay que actualizar el usuario firmado
   * @returns {Observable<boolean>}
   */
  public getUpdateGrupos(): Observable<boolean> {
    return this.updateGrupos.asObservable();
  }


  /**
   * Indica si hay que actualizar la etiqueta del grupo en el encabezado
   * @param { boolean } state Indica si hay que actualizar el usuario
   */
  public setHeaderLabelGroup(state: boolean): void {
    this.updateHeaderLabelGroup.next(state);
  }

  /**
   * Obtiene el valor que permite saber si hay que actualizar la etiqueta del grupo en el encabezado
   * @returns {Observable<boolean>}
   */
  public getHeaderLabelGroup(): Observable<boolean> {
    return this.updateHeaderLabelGroup.asObservable();
  }

}
