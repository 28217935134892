import { Component, Input, OnInit } from '@angular/core';
import { Examen } from '../../core/clases/actividades/examen';
import { PreguntaExamen } from '../../core/clases/actividades/pregunta-examen';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorService } from '../../core/servicios/general/error.service';
import { finalize } from 'rxjs/operators';
import { PreguntaExamenService } from '../../core/servicios/actividades/pregunta-examen.service';

/**
 * ExamenVistaPreviaComponent
 * @author MG
 */
@Component({
    selector: 'examen-vistaprevia',
    templateUrl: './examen-vistaprevia.component.html'
})
export class ExamenVistaPreviaComponent implements OnInit {
    

    @Input() examen: Examen = new Examen();

    private listaPreguntas: Array<PreguntaExamen> = [];
    private cargandoDatos: boolean;

    constructor(
        private errorService: ErrorService,
        private preguntaExamenService: PreguntaExamenService
    ) {
        
    }

    /**
     * Inicializa el componente
     */
    ngOnInit(): void {
        this.buscarLista();
    }

    /**
     * Obtiene las preguntas del examen
     */
    private buscarLista(): void {
        this.cargandoDatos = true;
        this.preguntaExamenService.obtenerListaTodas(this.examen.id)
            .pipe(
                finalize(() => {
                    this.cargandoDatos = false;
                })
            )
            .subscribe(
                res => {
                    if (res.body) {
                        this.listaPreguntas = res.body;
                    }
                },
                (error: HttpErrorResponse) => {
                    this.errorService.validate(error, this.errorService.HttpType.Consulta);
                });
    }

}