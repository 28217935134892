import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { APPCONFIG } from '../../../config';
import { Ordenamiento } from '../../clases/general/ordenamiento';

@Injectable()
export class RequestService {

    private urlBaseWS: string = APPCONFIG.urlBaseWS;
    
    constructor(
        private http: HttpClient
    ) { }

    /**
     * Envia una peticion GET a la url indicada
     * @param {string} url 
     * @returns {Observable<any>}
     */
    public get(url: string): Observable<any> {
        url = this.urlBaseWS + url;
        var headers = this.obtenerEncabezados();
        return this.http.get(url, {
            headers: headers,
            observe: 'response',
            responseType: 'json'
        });
    }

    /**
     * Envia una peticion POST a la url indicada
     * @param url 
     * @param body 
     * @returns {Observable<any>}
     */
    public post(url: string, body: any): Observable<any> {
        url = this.urlBaseWS + url;
        var headersS = this.obtenerEncabezados();
        return this.http.post(url, body, {
            headers: headersS,
            observe: 'response',
            responseType: 'json'
        });
    }

    /**
     * Envia una peticion POST a la url indicada
     * @param url 
     * @param body 
     * @returns {Observable<any>}
     */
    public postFile(url: string, body: any): Observable<any> {
        url = this.urlBaseWS + url;
        var headersS = this.obtenerEncabezadosArchivo();
        return this.http.post(url, body, {
            headers: headersS,
            observe: 'response',
            responseType: 'json'
        });
    }

     /**
     * Envia una peticion PUT a la url indicada
     * @param url 
     * @param body 
     * @returns {Observable<any>}
     */
    public put(url: string, body: any): Observable<any> {
        url = this.urlBaseWS + url;
        var headersS = this.obtenerEncabezados();
        return this.http.put(url, body, {
            headers: headersS,
            observe: 'response',
            responseType: 'json'
        })
    }

    /**
     * Envia una peticion DELETE a la url indicada
     * @returns {Observable<any>}
     */
    public delete(url: string): Observable<any> {
        url = this.urlBaseWS + url;
        var headers = this.obtenerEncabezados();
        return this.http.delete(url, {
            headers: headers,
            observe: 'response',
            responseType: 'json'
        });
    }

    /**
    * Envia una peticion DELETE a la url indicada
    * @returns {Observable<any>}
    */
    public deleteParams(url: string, params: HttpParams): Observable<any> {
        url = this.urlBaseWS + url;
        var headers = this.obtenerEncabezados();
        return this.http.delete(url, {
            headers: headers,
            observe: 'response',
            responseType: 'json',
            params: params
        });
    }

    /**
     * Envia una peticion GET a la url indicada
     * @param {string} url 
     * @returns {Observable<any>}
     */
    public _get(url: string): Observable<any> {
        url = this.urlBaseWS + url;
        var headers = this._obtenerEncabezados();
        return this.http.get(url, {
            headers: headers,
            observe: 'response',
            responseType: 'json'
        });
    }

    /**
     * Envia una peticion POST a la url indicada
     * @param {string} url 
     * @param {any} body 
     * @returns {Observable<any>}
     */
    public _post(url: string, body: any): Observable<any> {
        url = this.urlBaseWS + url;
        var headersS = this._obtenerEncabezados();
        return this.http.post(url, body, {
            headers: headersS,
            observe: 'response',
            responseType: 'json'
        });
    }

    /**
     * Envia una peticion POST a la url indicada
     * @param url 
     * @param body 
     * @returns {Observable<any>}
     */
    public _postFile(url: string, body: any): Observable<any> {
        url = this.urlBaseWS + url;
        var headersS = this._obtenerEncabezadosArchivo();
        return this.http.post(url, body, {
            headers: headersS,
            observe: 'response',
            responseType: 'json'
        });
    }

    /**
     * Envia una peticion PUT a la url indicada
     * @param {string} url 
     * @param {any} body 
     * @returns {Observable<any>}
     */
    public _put(url: string, body: any): Observable<any> {
        url = this.urlBaseWS + url;
        var headersS = this._obtenerEncabezados();
        return this.http.put(url, body, {
            headers: headersS,
            observe: 'response',
            responseType: 'json'
        });
    }

    /**
     * Envia una peticion DELETE a la url indicada
     * @param {string} url
     * @returns {Observable<any>}
     */
    public _delete(url: string): Observable<any> {
        url = this.urlBaseWS + url;
        var headers = this._obtenerEncabezados();
        return this.http.delete(url, {
            headers: headers,
            observe: 'response',
            responseType: 'json'
        });
    }

    /**
     * Obtiene los encabezados para una peticion con seguridad
     * @returns {HttpHeaders}
     */
    public obtenerEncabezados(): HttpHeaders {
        //Header Authorization indica si se requiere o no token
        var headers = new HttpHeaders()
            .set('Access-Control-Allow-Methods', 'GET, HEAD, POST, PUT, PATCH, DELETE, OPTIONS')    
            .set('Access-Control-Allow-Headers', 'Content-Type, Accept, Authorization')
            .set('Access-Control-Allow-Origin', '*')
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json')
            .set('Authorization', 'true');
        return headers;
    }

    /**
    * Obtiene los encabezados para una peticion con seguridad
    * @returns {HttpHeaders}
    */
    public obtenerEncabezadosArchivo(): HttpHeaders {
        //Header Authorization indica si se requiere o no token
        var headers = new HttpHeaders()
            .set('Access-Control-Allow-Methods', 'GET, HEAD, POST, PUT, PATCH, DELETE, OPTIONS')
            .set('Access-Control-Allow-Headers', 'Content-Type, Accept, Authorization')
            .set('Access-Control-Allow-Origin', '*')
            .set('Accept', 'application/json')
            .set('Authorization', 'true');
        return headers;
    }

    /**
    * Obtiene los encabezados para una peticion con seguridad
    * @returns {HttpHeaders}
    */
    public _obtenerEncabezadosArchivo(): HttpHeaders {
        //Header Authorization indica si se requiere o no token
        var headers = new HttpHeaders()
            .set('Access-Control-Allow-Methods', 'GET, HEAD, POST, PUT, PATCH, DELETE, OPTIONS')
            .set('Access-Control-Allow-Headers', 'Content-Type, Accept, Authorization')
            .set('Access-Control-Allow-Origin', '*')
            .set('Accept', 'application/json')
        return headers;
    }

    /**
     * Obtiene los encabezados para una peticion simple
     * @returns {HttpHeaders}
     */
    private _obtenerEncabezados(): HttpHeaders {
        //Header Authorization indica si se requiere o no token
        var headers = new HttpHeaders()
            .set('Access-Control-Allow-Headers', 'Content-Type, Accept, Authorization')
            .set('Access-Control-Allow-Origin', '*')
            .set('Access-Control-Allow-Methods', 'GET,  HEAD, POST, PUT,  PATCH, DELETE, OPTIONS')
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json')
            .set('Authorization', 'false');
        return headers;
    }

    /**
     * Regresa una url para paginacion
     * @param {string} urlBase 
     * @param {number} pagina 
     * @param {number} cantidadRegistros 
     * @param {Ordenamiento} ordenamiento 
     */
    public formarUrlPaginacion(urlBase: string, pagina: number, cantidadRegistros: number, ordenamiento: Ordenamiento): string {
        var url = urlBase
            + "?page=" + pagina
            + "&size=" + cantidadRegistros
            + "&sort=" + ordenamiento.equivalencia + "," + ordenamiento.direccion;
        return url;
    }
}