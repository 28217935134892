import { Component } from '@angular/core';

/**
 * LoadingListComponent
 * @author MG
 */
@Component({
    selector: 'loading-list',
    templateUrl: './loading-list.component.html'
})
export class LoadingListComponent {
    
}