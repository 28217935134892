import { Injectable } from '@angular/core';

@Injectable()
export class SessionStorangeService {

    private variableIdioma = "B_Idioma";

    /**
     * Guarda un objeto en el sessionStorage del navegador
     * @param nombre Nombre con el que se almacena
     * @param valor Valor que se almacen
     */
    public guardar(nombre: string, valor: any): void {
        sessionStorage.setItem(nombre, JSON.stringify(valor));
    }

    /**
     * Obtiene un objeto guardado en el sessionStorage del navegador
     * @param nombre Nombre con el que se almacena
     * @returns {any}
     */
    public obtener(nombre: string): any {
        var value: string = sessionStorage.getItem(nombre);
        if (value === null || value === "")
            return null;
        else
            return JSON.parse(value);
    }

    /**
     * Elimina un objeto guardado en el sessionStorage del navegador
     * @param nombre Nombre con el que se almacena
     */
    public eliminar(nombre: string): void {
        sessionStorage.removeItem(nombre);
    }

    /**
     * Limpia todos los objetos guardados en el sessionStorage
     * @param idioma 
     */
    public eliminarTodo() {
        sessionStorage.clear();
    }
}