import { Component, Input } from '@angular/core';

/**
 * NoDataListComponent
 * @author MG
 */
@Component({
    selector: 'no-data-list',
    templateUrl: './no-data-list.component.html'
})
export class NoDataListComponent {

    @Input() muestra: boolean;
    

}