import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestService } from '../general/request.service';
import { Ordenamiento } from '../../clases/general/ordenamiento';
import { Alumno } from '../../clases/seguridad/alumno';

/**
 * AlumnoService
 * @author MG
 */
@Injectable()
export class AlumnoService {

    private urlAlumnosConsulta: string = "/alumno/all";
    private urlAlumnosConsultaGrupo: string = "/alumno/allXGrupo";
    private urlAlumnos: string = "/alumno";
    private urlAlumnosAlta: string = "/addAlumno";
    private urlAlumnosPorId: string = "/alumno/xid";
    private urlAlumnosPorUserId: string = "/alumno/user";
    private urlAlumnoAgregarLibro: string = "/alumno/libro";
    private urlAlumnosGrupos: string = "/alumno/infoGrupos";
    private urlAlumnosCodigos: string = "/alumno/infoCodigos";
    private urlAlumnosCodigosAlumnos: string = "/alumno/infoCodigoAlumno";
    private urlAlumnosPorProfesor: string = "/alumno/XProfesor";

    constructor(
        private requestService: RequestService
    ) { }

    /**
     * Obtener la lista de los alumnos
     * @param {number} pagina 
     * @param {number} cantidadRegistros
     * @param {Ordenamiento} ordenamiento
     * @param {string} busqueda
     * @return {Observable<any>} response
     */
    public obtenerLista(pagina: number, cantidadRegistros: number, ordenamiento: Ordenamiento, busqueda: string): Observable<any> {
        var body = {
            busqueda: busqueda
        };
        var url = this.requestService.formarUrlPaginacion(this.urlAlumnosConsulta, pagina, cantidadRegistros, ordenamiento);
        return this.requestService.post(url, body);
    }

    /**
    * Obtener la lista de los alumnos
    * @param {number} idGrupo
    * @param {number} pagina 
    * @param {number} cantidadRegistros
    * @param {Ordenamiento} ordenamiento
    * @param {string} busqueda
    * @return {Observable<any>} response
    */
    public obtenerListaGrupo(idGrupo: number, pagina: number, cantidadRegistros: number, ordenamiento: Ordenamiento, busqueda: string): Observable<any> {
        var body = {
            grupo: {
                id: idGrupo
            },
            busqueda: busqueda
        };
        var url = this.requestService.formarUrlPaginacion(this.urlAlumnosConsultaGrupo, pagina, cantidadRegistros, ordenamiento);
        return this.requestService.post(url, body);
    }

    /**
     * Obtener la informacion del alumno indicado
     * @param {number} id Id del alumno
     * @return {Observable<any>} Response
     */
    public obtenerPorId(id: number): Observable<any> {
        var url = this.urlAlumnosPorId + "/" + id;
        var body = {
        };
        return this.requestService.post(url, body);
    }

    /**
     * Obtener la informacion del alumno indicado
     * @return {Observable<any>} Response
     */
    public obtenerPorUserId(): Observable<any> {
        return this.requestService.get(this.urlAlumnosPorUserId);
    }

    /**
     * Crea un nuevo alumno
     * @param {Alumno} alumno
     * @return {Observable<any>} Response
     */
    public crear(alumno: Alumno): Observable<any> {
        var body = {
            planteles: alumno.planteles,
            grupos: alumno.grupos,
            turno: alumno.turno,
            codigosLibros: alumno.codigosLibros,
            user: {
                username: alumno.user.username,
                nombre: alumno.user.nombre,
                apellidos: alumno.user.apellidos,
                email: alumno.user.email,
                enabled: alumno.user.enabled,
                password: alumno.user.password
            }
        };
        return this.requestService._post(this.urlAlumnosAlta, body);
    }

    /**
     * Guardar la informacion de un profesor
     * @param {Alumno} alumno
     * @return {Observable<any>} Response
     */
    public guardar(alumno: Alumno): Observable<any> {
        var body = {
            id: alumno.id,
            planteles: alumno.planteles,
            grupos: alumno.grupos,
            turno: alumno.turno,
            user: {
                id: alumno.user.id,
                username: alumno.user.username,
                nombre: alumno.user.nombre,
                apellidos: alumno.user.apellidos,
                email: alumno.user.email,
                enabled: alumno.user.enabled,
                password: alumno.user.password
            }
        };
        return this.requestService.put(this.urlAlumnos, body);
    }

    /**
     * Permite eliminar el alumno indicado
     * @param {number} id 
     * @return {Observable<any>} Response
     */
    public eliminar(id: number): Observable<any> {
        var url = this.urlAlumnos + "/" + id;
        return this.requestService.delete(url);
    }

    /**
     * Crea un nuevo alumno
     * @param {number} idGrupo
     * @param {string} codigo
     * @return {Observable<any>} Response
     */
    public agregarCodigo(idGrupo: number, codigo: string): Observable<any> {
        var body = {
            grupo: {
                id: idGrupo
            },
            codigosLibros: {
                codigo: codigo
            }
        };
        return this.requestService.post(this.urlAlumnoAgregarLibro, body);
    }
    
    /**
     * Obtener la informacion del alumno indicado
     * @return {Observable<any>} Response
     */
    public obtenerGrupos(): Observable<any> {
        return this.requestService.get(this.urlAlumnosGrupos);
    }

    /**
     * Obtener la informacion del alumno indicado
     * @return {Observable<any>} Response
     */
    public obtenerCodigos(): Observable<any> {
        return this.requestService.get(this.urlAlumnosCodigos);
    }

    /**
     * Obtiene el total de alumnos por profesor
     */
    public totalAlumnosPorProfesor(): Observable<any> {
        return this.requestService.get(this.urlAlumnosPorProfesor);
    }

    /**
     * Obtener la informacion del alumno indicado
     * @param {number} id Id del alumno
     * @return {Observable<any>} Response
     */
    public obtenerCodigosPorId(id: number): Observable<any> {
        var url = this.urlAlumnosCodigosAlumnos + "/" + id;
        return this.requestService.get(url);
    }
}