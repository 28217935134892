import { AbstractControl } from '@angular/forms';

/**
 * Valida que el control tenga un formato para contraseñas correcto
 * @author MG
 * @param {AbstractControl} control
 */
export function validPasswordFormat(control: AbstractControl): { [key: string]: boolean } | null {
    //var expreg = new RegExp("[a-zA-Z\d$@$!%*_.-;:?&][^'\s]");
    var expreg = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@_#\-\$%\^&\*-\.])(?=.{7,}[^'\s])");
    if (!expreg.test(control.value)) {
        return { 'passwordformat': true };
    }
    return null; 
}