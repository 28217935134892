import { NgModule, ApplicationRef } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
//import { NotFoundModule } from './not-found/not-found.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule } from './layout/layout.module';
import { MySharedModule } from './my-shared/my-shared.module';
import { LoginModule } from './login/login.module'
import { CoreModule } from './core/core.module'
import { HTTP_INTERCEPTORS } from '@angular/common/http';
//Interceptores
import { EmptyResponseBodyErrorInterceptor } from './empty-response-body-error-interceptor';
/* Cargar archivos de traduccion */
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}
@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    FormsModule,
    LayoutModule,
    //NotFoundModule,
    MySharedModule.forRoot(),
    LoginModule,
    AppRoutingModule,
    HttpClientModule
  ],
  declarations: [
    AppComponent
  ],
  bootstrap: [AppComponent],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: EmptyResponseBodyErrorInterceptor,
    multi: true,
  }],
})

export class AppModule {
  constructor(public appRef: ApplicationRef) { }
}
