import { Directive, ElementRef, HostListener, Input } from '@angular/core';

/**
 * OnlyDecimalNumbersDirective
 * @author MG
 */
@Directive({
  selector: '[OnlyDecimalNumbers]'
})
export class OnlyDecimalNumbersDirective {

  constructor(private el: ElementRef) { }

  private regex: RegExp = new RegExp(/^[0-9]+(\.[0-9]{0,})?$/);

  @HostListener('keydown', ['$event']) onKeyDown(event) {
    let e = <KeyboardEvent>event;
    if ([46, 8, 9, 27, 13, 110].indexOf(e.keyCode) !== -1 ||
      // Permitir: Ctrl+A
      (e.keyCode == 65 && e.ctrlKey === true) ||
      // Permitir: Ctrl+C
      (e.keyCode == 67 && e.ctrlKey === true) ||
      // Permitir: Ctrl+V
      (e.keyCode == 86 && e.ctrlKey === true) ||
      // Permitir: Ctrl+X
      (e.keyCode == 88 && e.ctrlKey === true) ||
      // Permitir: home, end, left, right
      (e.keyCode >= 35 && e.keyCode <= 39)) {
      return;
    }
    
    let current: string = this.el.nativeElement.value;
    let next: string = current.concat(event.key);
    if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105) && e.keyCode != 190) {
      event.preventDefault();
    }
    else if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105)) {
      
    }
    else if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }

  }
}
