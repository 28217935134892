import { Injectable, Injector } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpEvent, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from './core/servicios/seguridad/auth.service';
import { RefreshingSessionService } from './core/servicios/seguridad/refreshing-session.service';
import { STORANGE } from './config/storange';
import { APPCONFIG } from './config';

@Injectable()
export class EmptyResponseBodyErrorInterceptor implements HttpInterceptor {

    private isRefreshingToken: boolean = false;
    private urlBaseWS: string = APPCONFIG.urlBaseWS;
    private urlActualizar: string = "/refresh";
    private pila: Array<HttpRequest<any>> = [];
    private variableToken: string = STORANGE.token;

    constructor(
        private injector: Injector
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        //Verifica si es necesario agregar el header Authorization
        if (req.headers.get('Authorization') === 'true') {
            var token = JSON.parse(localStorage.getItem(this.variableToken));
            if (token === null) {
                var loginService = this.injector.get(AuthService);
                loginService.limpiarInfoAuth();
            }
            req = req.clone({
                setHeaders: {
                    Authorization: token
                }
            });
        }
        return next.handle(req).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    //console.log('event--->>>', event);
                }
                return event;
            }),
            catchError((error, caught) => {
                if (error instanceof HttpErrorResponse) {
                    if (error.status === 401) {
                        var refreshingService = this.injector.get(RefreshingSessionService);
                        if (refreshingService.obtener()) {

                        }
                        else {
                            refreshingService.enviar(true);
                            //redireccionar
                            var router = this.injector.get(Router);
                            var loginService = this.injector.get(AuthService);
                            loginService.limpiarInfoAuth();
                        }
                    }
                    else {
                        return throwError(error);
                    }
                }
                return new Observable<HttpEvent<any>>();
            })
        );
    }
}