import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment-timezone';

@Injectable()
export class DateService {

    private formatoFechaWS = "YYYY-MM-DD"; //Formato para enviar una fecha al api
    private formatoFecha = "DD-MM-YYYY"; //Formato para convertir en la fecha de la app
    private formatoFechaHoraWS = "YYYY-MM-DD HH:mm:ss"; //Formato para enviar la fecha hora a la api
    private formatoFechaApp = "DD-MM-YYYY HH:mm:ss";
    private formatoArchivo = "DDMMYYYYHHmm";

    constructor(
        private translateService: TranslateService
    ) { }


    /**
     * Obtiene las traducciones para el componente de fecha 
     * @returns {any}
     */
    public obtenerTraduccionesFechas(): any {
        var traducciones = {
            firstDayOfWeek: 0,
            dayNames: [
                this.translateService.instant("FDDomingo"),
                this.translateService.instant("FDLunes"),
                this.translateService.instant("FDMartes"),
                this.translateService.instant("FDMiercoles"),
                this.translateService.instant("FDJueves"),
                this.translateService.instant("FDViernes"),
                this.translateService.instant("FDSabado"),
            ],
            dayNamesShort: [
                this.translateService.instant("FDSDomingo"),
                this.translateService.instant("FDSLunes"),
                this.translateService.instant("FDSMartes"),
                this.translateService.instant("FDSMiercoles"),
                this.translateService.instant("FDSJueves"),
                this.translateService.instant("FDSViernes"),
                this.translateService.instant("FDSSabado")
            ],
            dayNamesMin: [
                this.translateService.instant("FDMDomingo"),
                this.translateService.instant("FDMLunes"),
                this.translateService.instant("FDMMartes"),
                this.translateService.instant("FDMMiercoles"),
                this.translateService.instant("FDMJueves"),
                this.translateService.instant("FDMViernes"),
                this.translateService.instant("FDMSabado")
            ],
            monthNames: [
                this.translateService.instant("FMEnero"),
                this.translateService.instant("FMFebrero"),
                this.translateService.instant("FMMarzo"),
                this.translateService.instant("FMAbril"),
                this.translateService.instant("FMMayo"),
                this.translateService.instant("FMJunio"),
                this.translateService.instant("FMJulio"),
                this.translateService.instant("FMAgosto"),
                this.translateService.instant("FMSeptiembre"),
                this.translateService.instant("FMOctubre"),
                this.translateService.instant("FMNoviembre"),
                this.translateService.instant("FMDiciembre"),
            ],
            monthNamesShort: [
                this.translateService.instant("FMSEnero"),
                this.translateService.instant("FMSFebrero"),
                this.translateService.instant("FMSMarzo"),
                this.translateService.instant("FMSAbril"),
                this.translateService.instant("FMSMayo"),
                this.translateService.instant("FMSJunio"),
                this.translateService.instant("FMSJulio"),
                this.translateService.instant("FMSAgosto"),
                this.translateService.instant("FMSSeptiembre"),
                this.translateService.instant("FMSOctubre"),
                this.translateService.instant("FMSNoviembre"),
                this.translateService.instant("FMSDiciembre"),
            ],
            today: this.translateService.instant("FToday"),
            clear: this.translateService.instant("FClear")
        };
        return traducciones;
    }

    /**
     * Obtiene la fecha actual en el formato DD-MM-YYYY HH:mm:ss
     * @returns {string}
     */
    public obtenerFechaActual(): string {
        var actual = new Date();
        return moment(actual).format(this.formatoFechaApp);
    }

    /**
     * Obtiene la fecha actual con el ultimo minuto del dia
     */
    public obtenerFechaActualFinDelDia(): Date {
        var actual = new Date();
        var dateF = moment(actual).endOf('day');
        return dateF.toDate();
    }

    /**
     * Obtiene la fecha actual en el formato DD-MM-YYYY
     * @returns {string}
     */
    public obtenerFechaActualDatePicker(): string {
        var actual = new Date();
        return moment(actual).format(this.formatoFecha);
    }

    /**
     * Convierte una fecha de la aplicacion a una fecha valida para el WS
     * @param {string} fecha
     * @returns {string}
     */
    public convertirFechaAFechaWS(fecha: string): string {
        if (fecha) {
            var date = moment(fecha).format(this.formatoFechaWS);
            return date;
        }
        return "";
    }

    /**
     * Convierte una fecha de la aplicacion a una fecha/Hora valida para el WS
     * (Fecha con tiempo 00:00:00)
     * @param {string} fecha
     * @returns {string}
     */
    public convertirFechaAFechaHoraWS(fecha: string): string {
        if (fecha) {
            var dateF = moment(fecha).startOf('day');
            var date = dateF.utc().format(this.formatoFechaHoraWS);
            return date;
        }
        return "";
    }

    /**
     * Convierte una fecha de la aplicacion a una fecha/Hora valida para el WS
     * (Fecha en que se debe de contemplar el tiempo como 23:59:59 y luego centralizarse)
     * @param {string} fecha
     * @returns {string}
     */
    public convertirFechaFinAFechaHoraWS(fecha: string): string {
        if (fecha) {
            //Sumar un dia a la fecha y restar un segundo para obtener el ultimo rango
            var dateF = moment(fecha).endOf('day');
            var date = dateF.utc().format(this.formatoFechaHoraWS);
            return date;
        }
        return "";
    }

    /**
     * Convierte un DATE a una fecha valida para el WS
     * (Fecha en que se debe de contemplar el tiempo como 23:59:59 y luego centralizarse)
     *  @param {Date} fecha
     */
    public convertirDateFechaFinAFechaHoraWS(fecha: Date): string {
        if (fecha) {
            //Sumar un dia a la fecha y restar un segundo para obtener el ultimo rango
            var dateF = moment(fecha).endOf('day');
            var date = dateF.utc().format(this.formatoFechaHoraWS);
            return date;
        }
        return "";
    }

    /**
     * Convierte un DATE a una fecha valida para el WS
     * (Fecha en que se debe de contemplar el tiempo como 00:00:00 y luego centralizarse)
     * @param {Date} fecha 
     */
    public convertirDateFechaInicioAFechaHoraWS(fecha: Date): string {
        if (fecha) {
            //Sumar un dia a la fecha y restar un segundo para obtener el ultimo rango
            var dateF = moment(fecha).startOf('day');
            var date = dateF.utc().format(this.formatoFechaHoraWS);
            return date;
        }
        return "";
    }

    /**
     * Convierte un DATE a una fecha valida para el WS
     * (Fecha en que se debe de contemplar el tiempo como 00:00:00 y luego centralizarse)
     * @param {Date} fecha 
     */
    public convertirDateAFecha(fecha: Date): string {
        if (fecha) {
            //Sumar un dia a la fecha y restar un segundo para obtener el ultimo rango
            var dateF = moment(fecha).startOf('day');
            var date = dateF.utc().format(this.formatoFecha);
            return date;
        }
        return "";
    }

    /**
     * Convierte una fecha hora de la aplicacion a una fecha/Hora central valida para el WS --
     * @param {string} fecha
     * @param {boolean} setSecondsToZero
     */
    public convertirFechaHoraAFechaHoraWS(fecha: string, setSecondsToZero: boolean): string {
        var date = "";
        if (fecha) {
            //Centralizar
            if (setSecondsToZero) {
                date = moment(fecha).seconds(0).utc().format(this.formatoFechaHoraWS);
            }
            else {
                date = moment(fecha).utc().format(this.formatoFechaHoraWS);
            }
            return date;
        }
        return date;
    }

    /**
     * Convierte una fecha en formato numerico a un objeto Date
     * @param {number} fecha
     * @returns {string}
     */
    public convertirFechaNumericaWSAFecha(fecha: number): string {
        if (fecha) {
            var date = moment(fecha).format(this.formatoFechaHoraWS);
            var dateOb = new Date(date);
            return date;
        }
        return "";
    }

    /**
     * Convierte un objeto Date a un string con el formato DD-MM-YYYY
     * @param {Date} date
     * @returns {string}
     */
    public convertirDateToFechaApp(date: Date): string {
        return moment(date).format(this.formatoFecha);
    }

    /**
     * Convierte un objeto Date a un string con el formato DD-MM-YYYY HH:mm:ss
     * @param {Date} date
     * @returns {string}
     */
    public convertirDateToFechaHoraApp(date: Date): string {
        return moment(date).format(this.formatoFechaApp);
    }

    /**
     * Convierte una fecha y hora proveniente del ws a un string con formato para la aplicacion -- 
     * @param {string} fecha
     * @returns {string}
     */
    public convertirFechaHoraWSAFechaHoraApp(fecha: string): string {
        var date = "";
        if (fecha) {
            var timezone = moment.tz.guess();
            date = moment.utc(fecha).tz(timezone).format(this.formatoFechaApp);
        }    
        return date;
    }

    /**
     * Convierte una fecha y hora proveniente del ws a un string con formato de solo fecha para la aplicacion
     * @param {string} fecha
     * @returns {string}
     */
    public convertirFechaHoraWSAFechaApp(fecha: string): string {
        var date = "";
        if (fecha) {
            var timezone = moment.tz.guess();
            date = moment.utc(fecha).tz(timezone).format(this.formatoFecha);
        }
        return date;
    }

    /**
     * 
     * @param {string} fecha
     * @returns {string}
     */
    public convertirFechaHoraWSAFechaAppSinCambioZona(fecha: string): string {
        var date = "";
        if (fecha) {
            date = moment.utc(fecha).format(this.formatoFechaWS);
        }
        return date;
    }

    /**
     * 
     * @param {string} fecha
     * @returns {string}
     */
    public convertirFechaHoraWSASinCambioZonaFechaApp(fecha: string): Date {
        return moment(fecha).toDate();
    }
    
    /**
     * Convierte una fecha del WS a una fecha para la aplicacion
     * @param {string} fecha
     * @returns {Date}
     */
    public convertirFechaWSAFecha(fecha: string): Date {
        var timezone = moment.tz.guess();
        var date = moment.utc(fecha).tz(timezone);
        var dateOb = date.toDate();
        return dateOb;
    }


    /**
     *
     * @returns {string}
     */
    public restarDiasAFechaActual(dias: number): string {
        var dateOb = new Date();
        var fecha = moment(dateOb).subtract(dias, 'days').format(this.formatoFecha);
        return fecha;
    }

    /**
     * 
     * @returns {string}
     */
    public restarDiasAFechaActualDate(dias: number): Date {
        var dateOb = new Date();
        var fecha = moment(dateOb).subtract(dias, 'days').format(this.formatoFechaHoraWS);
        dateOb = new Date(fecha);
        return dateOb;
    }


    /**
     * Verifica si una fecha es mayor o igual a otra
     * @param {string} fechaAComparar
     * @param {string} fechaReferencia
     * @returns {boolean}
     */
    public fechaMayorOIgualAFecha(fechaAComparar: string, fechaReferencia: string): boolean {
        if (moment(fechaAComparar).isSameOrAfter(moment(fechaReferencia))) {
            return true;
        }
        return false;
    }

    /**
     * Verifica si una fecha es menor o igual a otra
     * @param {string} fechaAComparar
     * @param {string} fechaReferencia 
     * @returns {boolean}
     */
    public fechaHorarMenorOIgualAFecha(fechaAComparar: string, fechaReferencia: string): boolean {
        if (moment(fechaAComparar).isSameOrBefore(moment(fechaReferencia))) {
            return true;
        }
        return false;
    }

    /**
     * Obtiene la diferencia en meses
     * @param {string} fechaAComparar
     * @returns {number}
     */
    public diferenciaEnMesesFechaActual(fechaAComparar: string): number {
        var diferenciaMeses: number = moment(new Date()).diff(fechaAComparar, "months");
        return diferenciaMeses;
    }

    /**
     * Convierte un objeto de tipo DiferenciaFecha a su formato en string
     * @author MG
     * @public
     * @param diferencia 
     * @returns {string}
     */
    public obtenerFormatoFecha(dias: number, horas: number, minutos: number, soloSiExisten: boolean): string {
        var diferenciaS = "";
        if (dias == null) {
            dias = 0;
        }
        if (horas == null) {
            horas = 0;
        }
        if (minutos == null) {
            minutos = 0;
        }
        //Dias
        if (dias === 0) {
            if (!soloSiExisten) {
                diferenciaS = dias + " " + this.translateService.instant("AbrevDias") + " ";
            }
        }
        else if (dias === 1) {
            diferenciaS = dias + " " + this.translateService.instant("AbrevDia") + " ";
        }
        else {
            diferenciaS = dias + " " + this.translateService.instant("AbrevDias") + " ";
        }
        //Horas
        if (horas === 0) {
            if (!soloSiExisten) {
                diferenciaS = diferenciaS + horas + " " + this.translateService.instant("AbrevHoras") + " ";
            }
        }
        else if (horas === 1) {
            diferenciaS = diferenciaS + horas + " " + this.translateService.instant("AbrevHora") + " ";
        }
        else {
            diferenciaS = diferenciaS + horas + " " + this.translateService.instant("AbrevHoras") + " ";
        }
        //Minutos
        if (minutos === 0) {
            if (!soloSiExisten) {
                diferenciaS = diferenciaS + minutos + " " + this.translateService.instant("AbrevMinutos") + " ";
            }
        }
        else if (minutos === 1) {
            diferenciaS = diferenciaS + minutos + " " + this.translateService.instant("AbrevMinuto") + " ";
        }
        else {
            diferenciaS = diferenciaS + minutos + " " + this.translateService.instant("AbrevMinutos") + " ";
        }
        return diferenciaS;
    }

    /**
     * Convierte una cantidad de horas a un formato dias horas
     * @param {string} horasTotal
     */
    public convertirHorasADiasHoras(horasTotal: number): string {
        var dias: number = 0;
        var horas: number = 0;
        if (horasTotal >= 24) {
            dias = horasTotal / 24;
            dias = parseInt(String(dias));
        }
        if (dias >= 0) {
            horas = horasTotal % 24;
        }
        else {
            horas = horasTotal;
        }
        return this.obtenerFormatoFecha(dias, horas, 0, true);
    }

    /**
     * Convierte una cantidad de minutos a un formato dias horas minutos
     * @param {string} minutosTotal
     */
    public convertirMinutosAMinutosDiasHoras(minutosTotal: number): string {
        var dias: number = 0;
        var horas: number = 0;
        var minutos: number = 0;
        var resto: number = minutosTotal;
        var totalMinutosEnUnDia = 60 * 24;
        //Verificar cuantos dias
        if (resto >= totalMinutosEnUnDia) {
            dias = minutosTotal / totalMinutosEnUnDia;
            dias = parseInt(String(dias));
            resto = minutosTotal % totalMinutosEnUnDia;
        }
        //Verificar cuantas horas
        if (resto >= 60) {
            horas = resto / 60;
            horas = parseInt(String(horas));
            resto = resto % 60;
        }
        minutos = resto;
        return this.obtenerFormatoFecha(dias, horas, minutos, true);
    }

    /**
     * Obtiene un Date con el primer dia del mes en transcurso
     */
    public obtenerPrimerDiaMesActual(): Date {
        var dateF = moment(new Date()).startOf('month');
        return dateF.toDate();
    }

    /**
     * 
     */
    public obtenerParaArchivo(): string {
        var actual = new Date();
        return moment(actual).format(this.formatoArchivo);
    }
}