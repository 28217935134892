import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GtTexts, GtInformation, GtConfigSetting, GtConfig, GtOptions } from '@angular-generic-table/core';
import { Paginacion } from '../../clases/paginacion/paginacion';
import { Ordenamiento } from '../../clases/general/ordenamiento';
import { REGISTROPORPAGINADEFAULT } from '../../../config/constants';


@Injectable()
export class AngularGenericTableService {

    private listaCantidadRegistros = [10, 25, 50, 100];

    constructor(
        private translateService: TranslateService
    ) { }
    
    /**
     * Obtienes los texto necesarios para la visualizacion de las tablas con su traduccion
     * @returns {Promise<GtTexts>}
     */
    public obtenerTextos(): Promise<GtTexts> {
        var genericTableGtText = {
            loading: this.translateService.instant('Cargando') + '...',
            noData: this.translateService.instant('NoExistenRegistros'),
            noMatchingData: this.translateService.instant('NoExistenRegistrosBusqueda'),
            noVisibleColumnsHeading: this.translateService.instant('I00009'),
            noVisibleColumns: this.translateService.instant('I00012'),
            tableInfo: this.translateService.instant('Total') + ': #recordsAfterSearch ' + this.translateService.instant('Registros') + '. (#recordFrom - #recordTo)',
            tableInfoAfterSearch: this.translateService.instant('Total') + ': #recordsAfterSearch ' + this.translateService.instant('Registros') + ' (#recordFrom - #recordTo)',
            csvDownload: '',
            sortLabel: '',
            paginateNext: 'Siguiente',
            paginatePrevious: 'Anterior',
            inlineEditEdited: ''
            //inlineEditEdited: this.translateService.instant('PresionaEnterParaGuardar')
        };
        return Promise.resolve(genericTableGtText);
    }

    /**
     * Obtiene las cantidades para la paginacion de los registros en las tablas
     * @returns {Array<number>}
     */
    public obtenerListaCantidadRegistros(): Array<number> {
        return this.listaCantidadRegistros;
    }

    /**
     * Obtiene la configuracion del gtOptions para una tabla con paginacion lazy
     */
    public obtenerOptions(): GtOptions {
        var gtOptions = {
            stack: true
        };
        return gtOptions;
    }

    /**
     * Obtiene la configuracion del gtOptions para una tabla con paginacion lazy
     */
    public obtenerOptionsHighlightSearch(): GtOptions {
        var gtOptions = {
            stack: true,
            highlightSearch: true
        };
        return gtOptions;
    }

    /**
     * Obtiene la configuracion del gtOptions para una tabla con paginacion lazy
     */
    public obtenerOptionsPaginacionLazy(): GtOptions {
        var gtOptions = {
            stack: true,
            lazyLoad: true
        };
        return gtOptions;
    }

    /**
    * Obtiene la configuracion del gtOptions para una tabla con paginacion lazy
    */
    public obtenerOptionsPaginacionLazyYSeleccionMultiple(): GtOptions {
        var gtOptions = {
            stack: true,
            lazyLoad: true,
            rowSelection: true,
            rowSelectionAllowMultiple: true
        };
        return gtOptions;
    }
    
    public obtenerOptionsSeleccionMultiple(): GtOptions {
        var gtOptions = {
            stack: true,
            rowSelection: true,
            rowSelectionAllowMultiple: true
        };
        return gtOptions;
    }

    public obtenerOptionsSeleccionUnica(): GtOptions {
        var gtOptions = {
            stack: true,
            rowSelection: true,
            rowSelectionAllowMultiple: false
        };
        return gtOptions;
    }

    /**
     * Obtiene el GtConfig inicializado
     */
    public obtenerConfiguracionInicial(): GtConfig<any> {
        var configObject: GtConfig<any> = {
            settings: [],
            fields: [],
            data: [],
            info: this.obtenerInformacionDefault()
        };
        return configObject;
    }

    /**
     * Obtiene el GtInformation con la informacion por default
     */
    private obtenerInformacionDefault(): GtInformation {
        var info: GtInformation = {
            pageCurrent: 0,
            recordLength: REGISTROPORPAGINADEFAULT,
            recordsAll: 0,
            recordsAfterFilter: 0,
            recordsAfterSearch: 0,
            recordFrom: 0,
            recordTo: 0,
            pageTotal: 0,
            pagePrevious: 0,
            pageNext: 0,
            searchTerms: ''
        }
        return info;
    }

    /**
     * Convierte un objeto paginacion a un GtInformation para la tabla
     * @param {Paginacion<any} paginacion
     * @returns {GtInformation}
     */
    public obtenerInformacion(paginacion: Paginacion<any>): GtInformation {
        var info: GtInformation;
        if (paginacion) {
                info = {
                    pageCurrent: paginacion.number + 1,
                    recordLength: paginacion.size,
                    recordsAll: paginacion.totalElements,
                    recordsAfterFilter: 0,
                    recordsAfterSearch: 0,
                    recordFrom: 0,
                    recordTo: 0
                }
                info.pageTotal = paginacion.totalPages;
                info.recordsAfterFilter = info.recordsAll;
                info.recordsAfterSearch = info.recordsAll;

                if (info.pageCurrent > 1) {
                    info.pagePrevious = info.pageCurrent - 1;
                }
                else {
                    info.pagePrevious = null;
                }

                if (info.pageCurrent < info.pageTotal) {
                    info.pageNext = info.pageCurrent + 1;
                }
                else {
                    info.pageNext = null;
                }
                if (info.recordLength > 0) {
                    info.recordFrom = (info.pageCurrent - 1) * info.recordLength;
                    info.recordTo = info.recordFrom + info.recordLength;
                }
                info.searchTerms = '';
        }
        else {
            info = this.obtenerInformacionDefault();
        }
        return info;
    }

    /**
     * Busca la columna por la cual esta ordenado y la direccion del mismo
     * @param {Array<GtConfigSetting>} listaSettings
     * @param {Array<any>} equivalencias
     * @returns {Ordenamiento}
     */
    public obtenerDireccionOrdenamiento(listaSettings: Array<GtConfigSetting>, equivalencias: Array<any>): Ordenamiento {
        var ordenamiento = new Ordenamiento();
        ordenamiento.direccion = "asc";
        if (listaSettings && equivalencias) {
            for (var i = 0; i < listaSettings.length; i++) {
                var setting = listaSettings[i];
                if (setting.sort === "asc" || setting.sort === "desc") {
                    //Buscar equivalencia
                    ordenamiento.campo = setting.objectKey;
                    for (var j = 0; j < equivalencias.length; j++) {
                        if (equivalencias[j].nombre === ordenamiento.campo) {
                            ordenamiento.equivalencia = equivalencias[j].equivalente;
                            break;
                        }
                    }
                    //Indicar direccion
                    ordenamiento.direccion = setting.sort;
                    break;
                }
            }
        }
        else {
            console.log("Error en configuración de tabla");
        }
        return ordenamiento;
    }

    /**
     * Busca el index correspondiente al objectKey enviado en los campos
     * @param {Array<any>} fields
     * @param {key} key
     */
    public obtenerIndexByObjectKey(fields: Array<any>, key: string): number {
        var index = null;
        if (fields) {
            for (var i = 0; i < fields.length; i++) {
                if (fields[i].objectKey === key) {
                    return i;
                }
            }
        }
        return index;
    }
}