import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorangeService {

    private variableIdioma = "B_Idioma";

    /**
     * Guarda un objeto en el localStorage del navegador
     * @param nombre Nombre con el que se almacena
     * @param valor Valor que se almacen
     */
    public guardar(nombre: string, valor: any): void {
        localStorage.setItem(nombre, JSON.stringify(valor));
    }

    /**
     * Obtiene un objeto guardado en el localStorage del navegador
     * @param nombre Nombre con el que se almacena
     * @returns {any}
     */
    public obtener(nombre: string): any {
        var value: string = localStorage.getItem(nombre);
        if (value === null || value === "")
            return null;
        else
            return JSON.parse(value);
    }

    /**
     * Elimina un objeto guardado en el localStorage del navegador
     * @param nombre Nombre con el que se almacena
     */
    public eliminar(nombre: string): void {
         localStorage.removeItem(nombre);
    }
    
    /**
     * Limpia todos los objetos guardados en el localStorage
     * @param idioma 
     */
    public eliminarTodo(idioma: string) {
        localStorage.clear();
        this.guardar(this.variableIdioma, idioma);
    }
}