import { RequestService } from '../general/request.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

/**
 * AuxiliarService
 * @author MG
 */
@Injectable()
export class AuxiliarService {

    private urlAuxiliares: string = "/auxiliar";
    private urlAuxiliaresEliminar: string = "/auxiliar/delete";

    constructor(
        private requestService: RequestService
    ) { }

    /**
     * Agregar alumno auxiliar
     * @param {number} idGrupo
     * @param {number} idAlumno
     */
    public agregar(idGrupo: number, idAlumno: number): Observable<any> {
        var body = {
            grupo: {
                id: idGrupo
            },
            alumno: {
                id: idAlumno
            }
        };
        return this.requestService.post(this.urlAuxiliares, body);
    }

    /**
     * Eliminar alumno auxiliar
     * @param {number} idGrupo
     * @param {number} idAlumno
     */
    public eliminar(idGrupo: number, idAlumno: number): Observable<any> {
        var body = {
            grupo: {
                id: idGrupo
            },
            alumno: {
                id: idAlumno
            }
        };
        return this.requestService.post(this.urlAuxiliaresEliminar, body);
    }
}