import { Component, OnInit } from '@angular/core';
import { QuestionBase } from '../../core/clases/forms/question-base';
import { Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

/**
 * AppQuestionComponent
 * @author MG
 */
@Component({
    selector: 'app-question',
    templateUrl: './app-question.component.html'
})
export class AppQuestionComponent implements OnInit {

   
    @Input() question: QuestionBase<string>;
    @Input() form: FormGroup;
    get isValid() { return this.form.controls[this.question.key].valid; }
   
    /**
     * Inicializa el componente
     */
    public ngOnInit(): void {
        this.form.controls[this.question.key].setValue(this.question.value);
    }

}  
