import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestService } from '../general/request.service';
import { Ordenamiento } from '../../clases/general/ordenamiento';
import { Examen } from '../../clases/actividades/examen';
import { Grupo } from '../../clases/administracion/grupos';
import { PreguntaExamen } from '../../clases/actividades/pregunta-examen';

/**
 * ExamenService
 * @author MG
 */
@Injectable()
export class ExamenService {

    private urlExamenesPagiancion: string = "/examen/all";
    private urlExamenes: string = "/examen";
    private urlExamenesMateria: string = "/examen/XMateria";
    private urlExamenGenerar: string = "/examen/generar";
    private urlExamenActivar: string = "/examen/ActivarExamen";

    constructor(
        private requestService: RequestService
    ) { }
    
    /**
     * Obtener la lista 
     * @param {number} pagina 
     * @param {number} cantidadRegistros
     * @param {Ordenamiento} ordenamiento
     * @param {string} busqueda
     * @return {Observable<any>} response
     */
    public obtenerLista(pagina: number, cantidadRegistros: number, ordenamiento: Ordenamiento, busqueda: string): Observable<any> {
        var body = {
            busqueda: busqueda
        };
        var url = this.requestService.formarUrlPaginacion(this.urlExamenesPagiancion, pagina, cantidadRegistros, ordenamiento);
        return this.requestService.post(url, body);
    }

    /**
     *  Obtener la lista por materia para profesor
     * @param {string} busqueda
     * @return {Observable<any>}
     */
    public obtenerListaPlantillasMateria(idMateria: number): Observable<any> {
        var body = {
            id: idMateria
        };
        return this.requestService.post(this.urlExamenesMateria, body);
    }

    /**
     * Crea
     * @param {Examen} examen
     * @return {Observable<any>} 
     */
    public crear(examen: Examen): Observable<any> {
        var body = {
            nombre: examen.nombre,
            bloque: examen.bloque
        };
        return this.requestService.post(this.urlExamenes, body);
    }

    /**
     * Guardar
     * @param {Examen} examen
     * @return {Observable<any>}
     */
    public guardar(examen: Examen): Observable<any> {
        var body = {
            id: examen.id,
            nombre: examen.nombre,
            bloque: examen.bloque
        };
        return this.requestService.put(this.urlExamenes, body);
    }

    /**
     * Permite eliminar
     * @param  {number} id
     * @return {Observable<any>} 
     */
    public eliminar(id: number): Observable<any> {
        var url = this.urlExamenes + "/" + id;
        return this.requestService.delete(url);
    }

    /**
     * Genera una vista previa para el examen
     * @param {number} idExamen
     * @param {number} numero
     */
    public generarExamen(idExamen: number, numero: number): Observable<any> {
        var body = {
            examen: {
                id: idExamen
            },
            numero: numero
        };
        return this.requestService.post(this.urlExamenGenerar, body);
    }

    /**
     * Activa un examen para el profesor
     * @param {number} idExamen
     * @param {Array<Grupo>} grupos
     * @param {Array<PreguntaExamen>} preguntas
     */
    public activarExamen(idExamen: number, grupos: Array<Grupo>, preguntas: Array<PreguntaExamen>): Observable<any> {
        var body = {
            examen: {
                id: idExamen
            },
            grupos: grupos,
            preguntaExamenDtos: preguntas
        };
        return this.requestService.post(this.urlExamenActivar, body);
    }
} 