import { Component} from '@angular/core';
import { GtCustomComponent } from '@angular-generic-table/core';

/**
 * GrupoIconComponent
 * @author MG
 */
@Component({
    selector: 'grupo-icon',
    templateUrl: './grupo-icon.component.html'
})
export class GrupoIconComponent extends GtCustomComponent<any>  {

    constructor() {
        super();
    }
}