import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestService } from '../general/request.service';
import { Usuario } from '../../clases/seguridad/usuario';
import { Ordenamiento } from '../../clases/general/ordenamiento';

/**
 * UsuarioService
 * @author MG
 */
@Injectable()
export class UsuarioService {

    private urlUsuariosConsulta: string = "/user/all";
    private urlUsuarios: string = "/user";
    private urlUsuariosCorto: string = "/user/all/corto";
    private urlUsuariosPass: string = "/user/reset";
    private urlTiposUsuarios: string = "/authority";
    private urlUsuariosDetalle: string = "/user/detalle";
    private urlActualizarInfo: string = "/user/updateMyInfo";
    private urlActualizarImagenUsuario: string = "/files/user";
    private urlActualizarImagenRegistro: string = "/userImage";
    private urlUsuariosValidarCorreo: string = "/correo";

    constructor(
        private requestService: RequestService
    ) { }

    /**
     * Obtener la lista de los usuarios
     * @param {number} pagina 
     * @param {number} cantidadRegistros
     * @param {Ordenamiento} ordenamiento
     * @param {string} busqueda
     * @return {Observable<any>} response
     */
    public obtenerLista(pagina: number, cantidadRegistros: number, ordenamiento: Ordenamiento, busqueda: string): Observable<any> {
        var body = {
            busqueda: busqueda
        };
        var url = this.requestService.formarUrlPaginacion(this.urlUsuariosConsulta, pagina, cantidadRegistros, ordenamiento);
        return this.requestService.post(url, body);
    }

    /**
     * Obtener la informacion del usuario logueado
     * @return {Observable<any>} response
     */
    public obtener(): Observable<any> {
        return this.requestService.get(this.urlUsuarios);
    }

    /**
     * Obtener la informacion de los usuarios
     * @return {Observable<any>} response
     */
    public obtenerListaCorto(): Observable<any> {
        var body = {
        };
        return this.requestService.post(this.urlUsuariosCorto, body);
    }

    /**
     * Obtener la informacion del usuario indicado
     * @param {number} id Id del usuario
     * @return {Observable<any>} Response
     */
    public obtenerPorId(id: number): Observable<any> {
        var url = this.urlUsuarios + "/" + id;
        var body = {
        };
        return this.requestService.post(url, body);
    }

    /**
     * Obtiene el detalle del usuario con el id
     * @param {number} id
     * @return {Observable<any>}
     */
    public obtenerDetalle(id: number): Observable<any> {
        var body = {
            id: id
        };
        return this.requestService.post(this.urlUsuariosDetalle, body);
    }

    /**
     * Crea un nuevo usuario
     * @param {usuario} usuario
     * @return {Observable<any>} Response
     */
    public crear(usuario: Usuario): Observable<any> {
        var body = {
            username: usuario.username,
            nombre: usuario.nombre,
            apellidos: usuario.apellidos,
            telefono: usuario.telefono,
            email: usuario.email,
            enabled: usuario.enabled,
            password: usuario.password,
            perfil: {
                id: usuario.perfil.id
            }
        };
        return this.requestService.post(this.urlUsuarios, body);
    }

    /**
     * Guardar la informacion de un usuario
     * @param {usuario} usuario
     * @return {Observable<any>} Response
     */
    public guardar(usuario: Usuario): Observable<any> {
        var body = {
            id: usuario.id,
            username: usuario.username,
            nombre: usuario.nombre,
            apellidos: usuario.apellidos,
            telefono: usuario.telefono,
            email: usuario.email,
            enabled: usuario.enabled,
            password: usuario.password,
            perfil: {
                id: usuario.perfil.id
            }
        };
        return this.requestService.put(this.urlUsuarios, body);
    }

    /**
     * Actualiza los datos del usuario firmado
     * @param {string} imagen
     */
    public actualizarDatosUsuarioFirmado(usuario: Usuario): Observable<any> {
        var body = {
            id: usuario.id,
            nombre: usuario.nombre,
            apellidos: usuario.apellidos,
            telefono: usuario.telefono,
            email: usuario.email,
            celular: usuario.celular
        };
        return this.requestService.post(this.urlActualizarInfo, body);
    }

    /**
     * Permite eliminar el usuario indicado
     * @param {number} id Id del usuario
     * @return {Observable<any>} Response
     */
    public eliminar(id: number): Observable<any> {
        var url = this.urlUsuarios + "/" + id;
        return this.requestService.delete(url);
    }

    /**
    * Crea un nuevo usuario
    * @param {usuario} usuario
    * @return {Observable<any>} Response
    */
    public cambiarContrasena(idUsuario: number, contrasena: string): Observable<any> {
        var url = this.urlUsuariosPass + "/" + idUsuario; 
        var body = contrasena;
        return this.requestService.post(url, body);
    }

    /**
     * Obtener la lista de los tipos de usuarios
     * @return {Observable<any>} response
     */
    public obtenerTipos(): Observable<any> {
        return this.requestService.get(this.urlTiposUsuarios);
    }

    /**
     * Carga imagen a usuario
     * @param {number} idUsuario
     * @param {any} archivo 
     */
    public subirImagen(idUsuario: number, archivo: any, nombre: string): Observable<any> {
        var formData: FormData = new FormData();
        formData.append('file', archivo, nombre);
        formData.append("user", idUsuario + "");
        return this.requestService.postFile(this.urlActualizarImagenUsuario, formData);
    }

    /**
     * Carga imagen a usuario
     * @param {number} idUsuario
     * @param {any} archivo 
     */
    public subirImagenRegistro(idUsuario: number, archivo: any, nombre: string): Observable<any> {
        var formData: FormData = new FormData();
        formData.append('file', archivo, nombre);
        formData.append("user", idUsuario + "");
        return this.requestService._postFile(this.urlActualizarImagenRegistro, formData);
    }
    
    /**
     * Valida si un correo ya existe
     * @param {string} email
     * @return {Observable<any>} Response
     */
    public existeCorreo(email: string): Observable<any> {
        var body = {
            email: email
        };
        return this.requestService._post(this.urlUsuariosValidarCorreo, body);
    }
}