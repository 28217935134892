import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';

/**
 * ConfirmDialogComponent
 * @author MG
 */
@Component({
  selector: 'confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
})
export class ConfirmDialogComponent {

  public titulo: string;
  public mensaje: string;

  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>) { }
  
  /**
   * Envia respuesta en true 
   */
  private aceptar(): void {
    this.dialogRef.close(true);
  }

  /**
   * Envia respuesta en false 
   */
  private cancelar(): void {
    this.dialogRef.close(false);
  }
}
