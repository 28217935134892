import { Component, OnInit } from '@angular/core';
import { GtCustomComponent } from '@angular-generic-table/core';

/**
 * MyProgressBarComponent
 * @author
 */
@Component({
    selector: 'my-progress-bar',
    templateUrl: './my-progress-bar.component.html'
})
export class MyProgressBarComponent extends GtCustomComponent<any> implements OnInit {

    private value: number = 0;
    private class: string = "my-progress-bar";

    constructor() {
        super();
    }

    /**
     * Inicializa el componente
     */
    public ngOnInit(): void {
        this.value = this.row.porcentaje;
        //Segun el pocentaje 
        if (this.value > 100) {
            this.class = 'my-progress-bar-indigo';
        }
        else if (this.value === 100) {
            this.class = 'my-progress-bar-green';
        }
        else if(this.value > 75) {
            this.class = 'my-progress-bar-blue';
        }
        else if (this.value > 50) {
            this.class = 'my-progress-bar-yellow';
        }    
        else {
            this.class = 'my-progress-bar-red';
        }
    }


}