import { Component, Input, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorService } from '../../core/servicios/general/error.service';
import { finalize } from 'rxjs/operators';
import { PreguntaTareaService } from '../../core/servicios/actividades/pregunta-tarea.service';
import { TareaProfesorService } from '../../core/servicios/actividades/tarea-profesor.service';
import { PreguntaTareaProfesor } from '../../core/clases/actividades/pregunta-tareaprofesor';
import { TareaProfesor } from '../../core/clases/actividades/tareaprofesor';
import { PreguntaTareaProfesorService } from '../../core/servicios/actividades/pregunta-tarea-profesor.service';

/**
 * TareaProfesorVistaPreviaComponent
 * @author MG
 */
@Component({
    selector: 'tareaprofesor-vistaprevia',
    templateUrl: './tareaprofesor-vistaprevia.component.html'
})
export class TareaProfesorVistaPreviaComponent implements OnInit {
    

    @Input() tarea: TareaProfesor = new TareaProfesor();

    private listaPreguntas: Array<PreguntaTareaProfesor> = [];
    private cargandoDatos: boolean;
    private cargandoDatosTarea: boolean;

    constructor(
        private errorService: ErrorService,
        private tareaProfesorService: TareaProfesorService,
        private preguntaTareaProfesorService: PreguntaTareaProfesorService
    ) {
        
    }

    /**
     * Inicializa el componente
     */
    ngOnInit(): void {
        this.buscarTarea();
        this.buscarLista();
    }

    private buscarTarea(): void {
        this.cargandoDatosTarea = true;
        this.tareaProfesorService.obtenerPorId(this.tarea.id)
            .pipe(
                finalize(() => {
                    this.cargandoDatosTarea = false;
                })
            )
            .subscribe(
                res => {
                    var tarea: TareaProfesor = res.body;
                    this.tarea.contenido = tarea.contenido;
                },
                (error: HttpErrorResponse) => {
                    this.errorService.validate(error, this.errorService.HttpType.Generico);
                });
    }
    
    
    /**
     * Obtiene las preguntas del examen
     */
    private buscarLista(): void {
        this.cargandoDatos = true;
        this.preguntaTareaProfesorService.obtenerLista(this.tarea.id)
            .pipe(
                finalize(() => {
                    this.cargandoDatos = false;
                })
            )
            .subscribe(
                res => {
                    if (res.body) {
                        var lista: Array<PreguntaTareaProfesor> = res.body;
                        var listaAux: Array<PreguntaTareaProfesor> = [];
                        var contador: number = 1;
                        if (lista) {
                            lista.forEach(p => {
                                p.consecutivo = contador;
                                listaAux.push(p);
                                p.pregunta = contador + ".- " + p.pregunta;
                                contador = contador + 1;
                            });
                        }
                        this.listaPreguntas = listaAux;
                    }
                },
                (error: HttpErrorResponse) => {
                    this.errorService.validate(error, this.errorService.HttpType.Consulta);
                });
    }

}