import { Component, Input } from '@angular/core';

/**
 * LoadingDataComponent
 * @author MG
 */
@Component({
    selector: 'loading-data',
    templateUrl: './loading-data.component.html'
})
export class LoadingDataComponent {

    @Input() classDiv: string;
    
}