import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../servicios/seguridad/auth.service';
import { ROUTES } from '../../config/routes';
import { LoginService } from '../servicios/seguridad/login.service';

/**
 * PublicGuard. Proporciana la verificacion de rutas para usuarios no autentificados
 * @author MG
 * 
 */
@Injectable()
export class PublicGuard implements CanActivate {
    
    constructor(
        private router: Router,
        private authService: AuthService,
        private loginService: LoginService
    ) { }

    /**
     * Verifica si el usuario no esta autentificado para poder acceder a la ruta
     * @author MG
     */
    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        var autentificado = this.authService.estaAutentificado();
        if (autentificado) {
            if (route && route.url && route.url[0].path === String(ROUTES.restaurarContrasena).replace("/", "")) {
                this.authService.limpiarInfoAuth();
                this.loginService.cerrarSesion();
                return true;
            }
            else {
                this.router.navigate([ROUTES.principal]);
                return false;
            }
        }
        return true;
    }
}