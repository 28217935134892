import { Directive, ElementRef, HostListener, Input } from '@angular/core';

/**
 * OnlyNumbersDirective
 * @author MG
 */
@Directive({
  selector: '[OnlyNumbers]'
})
export class OnlyNumbersDirective {

  constructor(private el: ElementRef) { }

  @HostListener('keydown', ['$event']) onKeyDown(event) {
    let e = <KeyboardEvent>event;
      if ([46, 8, 9, 27, 13, 110].indexOf(e.keyCode) !== -1 ||
        // Permitir: Ctrl+A
        (e.keyCode == 65 && e.ctrlKey === true) ||
        // Permitir: Ctrl+C
        (e.keyCode == 67 && e.ctrlKey === true) ||
        // Permitir: Ctrl+V
        (e.keyCode == 86 && e.ctrlKey === true) ||
        // Permitir: Ctrl+X
        (e.keyCode == 88 && e.ctrlKey === true) ||
        // Permitir: home, end, left, right
        (e.keyCode >= 35 && e.keyCode <= 39)) {
          return;
        }
        //Asegurar que es un numero y detener el evento
        if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
            e.preventDefault();
        }
  }
}
