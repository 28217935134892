import { Pipe } from '@angular/core';
import { DateService } from '../../core/servicios/general/date.service';

@Pipe({ name: 'dateWSToDateApp' })
export class DateWSToDateApp {

    constructor(private dateService: DateService) { }

    transform(fecha) {
        return this.dateService.convertirFechaHoraWSAFechaHoraApp(fecha);
    }
}