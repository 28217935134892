import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { APPCONFIG } from '../config';
import { ComunicacionService } from './servicios/comunicacion.service';
import { ConfiguracionWebService } from '../core/servicios/configuracion/configuracion-web.service';
import { Idioma } from '../core/clases/configuracion/idioma';
import { LanguageService } from '../core/servicios/configuracion/language-service';


/**
 * LoginComponent
 * @author MG
 * 
 */
@Component({
    selector: 'login',
    templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit, OnDestroy {

    private AppConfig: any;
    private mostrarLogoEsq: boolean;
    private subscription: Subscription;
    //Idiomas disponibles
    private idiomasDisponibles: Array<Idioma> = [];

    constructor(
        private translate: TranslateService,
        private comunicacionService: ComunicacionService,
        private configuracionWebService: ConfiguracionWebService,
        private languageService: LanguageService
    ) {
        this.AppConfig = APPCONFIG;
        this.mostrarLogoEsq = false;
        this.idiomasDisponibles = this.languageService.obtieneIdiomasDisponibles();
    }

    /**
     * Inicializar componente
     */
    public ngOnInit(): void {
        this.subscription = this.comunicacionService.obtenerMostrarLogoEsq().subscribe(res => {
            this.mostrarLogoEsq = res;
        });
        
    }

    /**
     * Destruccion del componente
     */
    public ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }    
    }

    /**
     * Se ejecuta cuando cambia el lenguaje
     */
    private cambiaLenguaje(): void {
        this.translate.use(this.AppConfig.idioma);
        this.configuracionWebService.guardarUltimoIdioma(this.AppConfig.idioma);
    }
}