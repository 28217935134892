import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import { IMAGENDEFAULT, IMAGENDEFAULTUSUARIO, PREFIJOBASE64, IMAGEN_DEFAULT_ERROR, EXT_PNG } from '../../../config/constants';
import { FileService } from './file.service';


@Injectable()
export class ImagenService {

    private urlDefault;
    private prefijoImg;

    constructor(private fileService: FileService) {
        this.urlDefault = IMAGENDEFAULT;
        this.prefijoImg = PREFIJOBASE64;
    }

    /**
     * Obtiene la imagen en Base64 con el prefijo requerido o si no existe imagen, la imagen por default
     * @param {string} base64 Base64 de la imagen
     * @returns {string}
     */
    public obtenerImagenBase64(base64: string): string  {
        var img = "";
        if (base64) {
            img = this.prefijoImg + base64;
        }
        else {
            img = this.urlDefault;
        }
        return img;
    }

    /**
     * Obtiene la imagen del usuario en Base64 con el prefijo requerido o si no existe imagen, la imagen por default
     * @param base64 
     * @returns {string}
     */
    public obtenerImagenUsuarioBase64(base64: string): string {
        var img = "";
        if (base64) {
            img = this.prefijoImg + base64;
        }
        else {
            img = IMAGENDEFAULTUSUARIO;
        }
        return img;
    }

    /**
     * Obtiene la imagen por default para indicar que hubo un error en la carga de una imagen solicitada
     * @returns {string}
     */
    public obtenerImagenError(): string {
        var img = IMAGEN_DEFAULT_ERROR;
        return img;
    }

    /**
     * Descarga una imagen en Base64 a un .png
     * @param imagen 
     * @param nombre 
     */
    public descargarImagen(imagen: string, nombre: string): void {
        var nombreArchivo = nombre + ".png";
        var content = this.fileService.base64ToFile(imagen, nombreArchivo, EXT_PNG);
        FileSaver.saveAs(content, nombreArchivo);
    }
}