import { Component, OnInit,  } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { LoginService } from '../../core/servicios/seguridad/login.service';
import { AuthService } from '../../core/servicios/seguridad/auth.service';
import { MensajeService } from '../../core/servicios/general/mensaje.service';
import { ComunicacionService } from '../servicios/comunicacion.service';
import { RefreshingSessionService } from '../../core/servicios/seguridad/refreshing-session.service';
import { empty } from '../../core/validations/empty';
import { ROUTES } from '../../config/routes';
import { MESSAGES } from '../../config/messages';
import { APPCONFIG } from '../../config';

/**
 * ValidacionUsuarioComponent
 * @author MG
 * 
 */
@Component({
    selector: 'login-user',
    templateUrl: './validacion-usuario.component.html'
})
export class ValidacionUsuarioComponent implements OnInit {
    
    private AppConfig: any;
    private recuperar = "";
    private loginForm: FormGroup;
    private usuarioNoExiste: boolean;
    private usuario: string;
    private contador: number;
    private validando: boolean;
    private errorGenerico: boolean;
    private valido: boolean;

    constructor(
        private router: Router,
        private loginService: LoginService,
        private comunicacionService: ComunicacionService,
        private mensajeService: MensajeService,
        private authService: AuthService,
        private refreshingSessionService: RefreshingSessionService,
        private fb: FormBuilder
    ) { 
        this.AppConfig = APPCONFIG;
        this.validando = false;
        this.usuario = "";
        this.usuarioNoExiste = false;
        this.contador = 0;
        this.recuperar = ROUTES.recuperarUsuario;
    }

    /**
     * Inicializar el componente
     */
    public ngOnInit(): void {
        this.comunicacionService.mostrarLogoEsq(false);
        this.inicializaForm();
        this.loginForm.valueChanges.subscribe(val => {
            this.usuarioNoExiste = false;
        });
    }

    /**
     * Inicializar el formulario y las reglas de validacion
     */
    private inicializaForm(): void {
        this.loginForm = this.fb.group({
            nombre: ["", [Validators.required, empty]]
        });
        this.loginForm.controls['nombre'].statusChanges.subscribe(val => {
            this.errorGenerico = false;
            this.usuarioNoExiste = false;
        });
    }
    
    /**
     * Realizar la validacion del usuario
     */
    private validar(): void {
        this.valido = true;
        this.refreshingSessionService.enviar(false);
        this.validando = true;
        setTimeout(function () {
            //do what you need here
        }, 1000000);
        //Si el formulario es valido
        if (this.loginForm.valid) {
            //verificacion de un logueo previo en otra pestaña
            if (this.authService.estaAutentificado()) {
                this.authService.limpiarInfoAuth();
                this.router.navigate([ROUTES.validarUsuario]);
            }
            //Buscar el usuario ingresado
            var usuario = this.loginForm.value.nombre;
            this.loginService.buscarUsuario(usuario).subscribe(
                res => {
                    //Descomentar una vez que este lista la validacion con los datos
                    var info = res.body;
                    if (info && info.nombre !== null) {
                        this.comunicacionService.guardarUsuario(usuario);
                        this.comunicacionService.guardarNombreUsuario(info.nombre);
                        this.comunicacionService.guardarImagenUsuario(info.url);
                        this.usuarioNoExiste = false;
                        this.validando = false;
                        this.router.navigate([ROUTES.validarContrasena]);
                    }
                    else {
                        this.validando = false;
                        this.usuarioNoExiste = true;
                    }
                },
                (err: HttpErrorResponse) => {
                    this.validando = false;
                    if (err.error && err.error.message === MESSAGES.usuarioInactivo) {
                        this.errorGenerico = true;
                    }
                    else {   
                        this.mensajeService.enviar("E00001", "Error", 1);
                    }
                });
        }
        else {
            this.loginForm.controls["nombre"].markAsTouched();
            this.validando = false;
        }  
    }
}