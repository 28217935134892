import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { fileNameFormat } from '../../../core/validations/fileNameFormat';
import { empty } from '../../../core/validations/empty';

/**
 * NameDialogComponent
 * @author MG
 * 
 */
@Component({
  selector: 'file-name-dialog',
  templateUrl: './file-name-dialog.component.html',
})
export class FileNameDialogComponent implements OnInit {

  public nombre: string = "";
  private form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<FileNameDialogComponent>
  ) { }
  
  /**
   * 
   */
  public ngOnInit(): void {
    this.inicializarForm();
  }

  /**
   * Inicializa el formulario vacio con las reglas que se evaluaran
   */
  private inicializarForm(): void {
    this.form = this.fb.group({
      nombre: [this.nombre, [Validators.required, empty, fileNameFormat, Validators.maxLength(300)]],
    });
  }
  
  /**
   * Obtiene el nombre y cierra el dialogo
   */
  private aceptar(): void {
    if (this.form.valid) {
      this.dialogRef.close({ resultado: true, nombre: this.form.value.nombre});
    }
    else {
      this.form.controls["nombre"].markAsTouched();
    }
  }

  /**
   *  Cierra el dialogo
   */
  private cancelar() {
    this.dialogRef.close({ resultado: false, nombre: this.form.value.nombre });
  }

}
