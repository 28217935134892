import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Usuario } from '../../../core/clases/seguridad/usuario';
import { Menu } from '../../../core/clases/seguridad/menu';
import { Permiso } from '../../../core/clases/seguridad/permiso';
import { ItemMenu } from '../../../core/clases/general/item-menu';
import { AuthService } from '../../../core/servicios/seguridad/auth.service';
import { PermisoService } from '../../../core/servicios/seguridad/permisos.service';
import { MenuService } from '../../../core/servicios/seguridad/menu.service';
import { ErrorService } from '../../../core/servicios/general/error.service';
import { MENU_WEB } from '../../../config/menu-web';
import { FiltroGrupoService } from '../../../core/servicios/layout/filtro-grupo.sevice';
import { Grupo } from '../../../core/clases/administracion/grupos';
import { TIPO_USUARIO } from '../../../config/constants';

/**
 * AppSidenavMenuComponent
 */
@Component({
  selector: 'my-app-sidenav-menu',
  templateUrl: './sidenav-menu.component.html'
})
export class AppSidenavMenuComponent implements OnInit {

  private listMenuOptions: Array<ItemMenu> = [];
  private listaPermisos: Array<Permiso> = [];
  private usuario: Usuario;
  private listaMenusPermitidos: Array<Menu> = [];
  private listaMenusCompleta: Array<Menu> = [];
  private clave = "Web";
  private cargandoMenu: boolean;
  private auxiliar: boolean;
  private grupo: Grupo;

  constructor(
    private authService: AuthService,
    private permisoService: PermisoService,
    private menuService: MenuService,
    private errorService: ErrorService,
    private filtroGrupoService: FiltroGrupoService,
  ) {
    this.usuario = this.authService.obtenerUsuario();
    this.obtienePermisosPerfilUsuarioFirmado();
    this.cargandoMenu = false;
   }

  /**
   * Inicializar el componente
   */
  public ngOnInit(): void {
    this.getMenuOptions();
    if (this.usuario.authorities[0].id === TIPO_USUARIO.ALUMNO) {
      this.filtroGrupoService.obtenerFiltro().subscribe(grupo => {
        if (grupo) {
          if (grupo.auxiliar) {
            this.auxiliar = true;
            this.grupo = grupo;
          }
          else {
            this.auxiliar = false;
          }
        }
      });
    }
  }

  /**
    * Obtiene los permisos correspondientes al perfil del usuario firmado
    */
  private obtienePermisosPerfilUsuarioFirmado(): void {
    this.permisoService.obtenerPorPerfil(this.usuario.perfil.id).subscribe(
      res => {
        this.listaPermisos = res.body;
        this.obtieneListaDeMenus();
      },
      (error: HttpErrorResponse) => {
        this.errorService.validate(error, this.errorService.HttpType.Consulta);
      });
  }

  /**
    * Obtiene la lista completa de los menus
    */
  private obtieneListaDeMenus(): void {
    this.menuService.obtener().subscribe(
      res => {
        this.listaMenusCompleta = res.body;
        this.obtieneMenusHabilitados();
      },
      (error: HttpErrorResponse) => {
        this.errorService.validate(error, this.errorService.HttpType.Consulta);
      });
  }


  /**
    * Habilita del menu default las opciones para las cuales tiene acceso el usuario firmado
    */
  private obtieneMenusHabilitados(): void {
    if (this.listaPermisos.length > 0) {
      this.listaPermisos.forEach(permiso => {
        //Para cada permiso ver
        if (permiso.acciones.nombre.toUpperCase() === "VER") {
          var menu = permiso.acciones.menu;
          if (menu.idPadre != null) {
            //buscar menu padre
            var menuPadre1 = this.buscaMenuPadre(menu.idPadre);
            if (menuPadre1 != null) {
              //verificar si ya se agrego el padre 
              if (!this.seAgregoMenu(menuPadre1.id)) {
                this.listaMenusPermitidos.push(menuPadre1);
                //Verifica si tiene un menu padre
                if (menuPadre1.idPadre != null) {
                  var menuPadre2 = this.buscaMenuPadre(menuPadre1.idPadre);
                  if (menuPadre2 != null) {
                    if (!this.seAgregoMenu(menuPadre2.id)) {
                      this.listaMenusPermitidos.push(menuPadre2);
                    }
                  }
                }
              }
            }
          }
          //Agregar a lista menu
          this.listaMenusPermitidos.push(menu);
        }
      });
      this.habilitar();
    }
    else {
      this.cargandoMenu = true;
      this.listMenuOptions = [];
    }
  }

  private buscaMenuPadre(id: number): Menu {
    var menu: Menu = null;
    for (var i = 0; i < this.listaMenusCompleta.length; i++) {
      if (id === this.listaMenusCompleta[i].id) {
        menu = this.listaMenusCompleta[i];
        break;
      }
    }
    return menu;
  }

  private seAgregoMenu(id: number): Boolean {
    for (var i = 0; i < this.listaMenusPermitidos.length; i++) {
      if (id === this.listaMenusPermitidos[i].id) {
        return true;
      }
    }
    return false;
  }

  private habilitar() {
    for (var i = 0; i < this.listMenuOptions.length; i++) {
      var menuNivel1 = this.listMenuOptions[i];
      //Verifica que tiene permisos para el menu
      var menu = this.obtieneMenu(menuNivel1.nombre);
      if (menu != null) {
        this.listMenuOptions[i].id = menuNivel1.id;
        this.listMenuOptions[i].habilitado = true;
        //buscar submenus habilitados
        if (this.listMenuOptions[i].habilitado && menuNivel1.submenu){
          for (var j = 0; j < this.listMenuOptions[i].submenu.length; j++) {
            var menuNivel2 = this.listMenuOptions[i].submenu[j];
            var menu2 = this.obtieneMenu(menuNivel2.nombre);
            if (menu2 != null) {
              this.listMenuOptions[i].submenu[j].id = menuNivel2.id;
              this.listMenuOptions[i].submenu[j].habilitado = true;
              //buscar submenus habilitados
              if (this.listMenuOptions[i].submenu[j].habilitado && menuNivel2.submenu) {
                for (var k = 0; k < this.listMenuOptions[i].submenu[j].submenu.length; k++) {
                  var menuNivel3 = this.listMenuOptions[i].submenu[j].submenu[k];
                  var menu3 = this.obtieneMenu(menuNivel3.nombre);
                  if (menu3 != null) {
                    this.listMenuOptions[i].submenu[j].submenu[k].id = menuNivel3.id;
                    this.listMenuOptions[i].submenu[j].submenu[k].habilitado = true;
                  }
                  else {
                    this.listMenuOptions[i].submenu[j].submenu[k].habilitado = false;
                  }
                }
              }
            }
            else {
              this.listMenuOptions[i].submenu[j].habilitado = false;
            }
          }
        }
      }
      else {
        this.listMenuOptions[i].habilitado = false;
      }
    }
    this.cargandoMenu = true;
  }

  private obtieneMenu(nombre: string): Menu {
    var menu: Menu = null
    for (var i = 0; i < this.listaMenusPermitidos.length; i++) {
      if (nombre === this.listaMenusPermitidos[i].nombre) {
        menu = this.listaMenusPermitidos[i];
        break;
      }
    }
    return menu;
  }
  
  getMenuOptions() {
    this.listMenuOptions = MENU_WEB;
  }

}
