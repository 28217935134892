import { Component, Input} from '@angular/core';

@Component({
    selector: 'box-dashboard',
    templateUrl: './box-dashboard.component.html'
})
export class BoxDashboardComponent {

    @Input() title: string;
    @Input() value: string;
    @Input() classIcon: string;
    @Input() icon: string;
    @Input() cargando: boolean = false;
}
