import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutRoutingModule } from './layout-routing.module';
import { MySharedModule } from '../my-shared/my-shared.module';
import { CoreModule } from '../core/core.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
/* ***** Componentes ***** */
// -- Layout
import { LayoutComponent } from './layout.component';
// -- Directiva necesaria para LayoutComponent
import { PreloaderDirective } from './preloader.directive';
// Customizer 
import { AppCustomizerComponent } from './customizer/customizer.component';
// -- Directiva necesaria para AppCustomizerComponent
import { ToggleQuickviewDirective } from './customizer/toggle-quickview.directive';
//Footer
import { AppFooterComponent } from './footer/footer.component';
//header
import { AppHeaderComponent } from './header/header.component';
//Sidenav
import { AppSidenavComponent } from './sidenav/sidenav.component';
import { AppSidenavMenuComponent } from './sidenav/sidenav-menu/sidenav-menu.component';
// -- Directivas necesarias para AppSideBarComponent
import { ToggleOffcanvasNavDirective } from './sidenav/toggle-offcanvas-nav.directive';
import { AutoCloseMobileNavDirective } from './sidenav/auto-close-mobile-nav.directive';
// -- Directivas necesarias para AppSidevarMenuComponent
import { AccordionNavDirective } from './sidenav/sidenav-menu/accordion-nav.directive';
import { AppendSubmenuIconDirective } from './sidenav/sidenav-menu/append-submenu-icon.directive';
import { HighlightActiveItemsDirective } from './sidenav/sidenav-menu/highlight-active-items.directive';
//Cerrar sesion
import { CerrarSesionComponent } from './header/cerrar-sesion/cerrar-sesion.component';
// -- Directiva para fullscreen
import { ToggleFullscreenDirective } from './header/toggleFullscreenDirective';

@NgModule({
  imports: [
    CommonModule,
    LayoutRoutingModule,
    CoreModule,
    FormsModule,
    ReactiveFormsModule,
    MySharedModule.forRoot()
  ],
  declarations: [
    //Componentes
    LayoutComponent,
    AppCustomizerComponent,
    AppFooterComponent,
    AppHeaderComponent,
    AppSidenavComponent,
    AppSidenavMenuComponent,
    CerrarSesionComponent,
    //Directivas
    PreloaderDirective,
    ToggleQuickviewDirective,
    ToggleOffcanvasNavDirective,
    AutoCloseMobileNavDirective,
    AccordionNavDirective,
    AppendSubmenuIconDirective,
    HighlightActiveItemsDirective,
    ToggleFullscreenDirective
  ],
  exports: [
    //Componentes
    LayoutComponent,
    AppCustomizerComponent,
    AppHeaderComponent,
    AppFooterComponent,
    AppSidenavComponent,
    AppSidenavMenuComponent,
    //Directivas
    PreloaderDirective,
    ToggleQuickviewDirective,
    ToggleOffcanvasNavDirective,
    AutoCloseMobileNavDirective,
    AccordionNavDirective,
    AppendSubmenuIconDirective,
    HighlightActiveItemsDirective
  ],
  providers: [
     //LayoutService
  ],
  entryComponents: [
    CerrarSesionComponent
  ]
})
export class LayoutModule {}
