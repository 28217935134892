import { Injectable } from '@angular/core';
import { Turno } from '../../clases/catalogos/turno';

/**
 * TurnoService
 * @author MG
 */
@Injectable()
export class TurnoService {

    private listaTurnos: Array<Turno> = [
        { id: 1, nombre: "Matutino" },
        { id: 2, nombre: "Vespertino" },
        { id: 3, nombre: "Mixto" }
    ];

    constructor(
    ) { }
    
    /**
     * Obtener la lista 
     * @return {Array<Turno>}
     */
    public obtenerLista(): Array<Turno> {
        return this.listaTurnos;
    }

    /**
     * 
     * @param {number} id
     */
    public buscar(id: number): Turno {
        var turno = new Turno();
        this.listaTurnos.forEach(t => {
            if (t.id === id) {
                turno = t;
            }
        });
        return turno;
    }
    
} 