import { Component} from '@angular/core';
import { GtCustomComponent } from '@angular-generic-table/core';

/**
 * DeleteIconComponent
 */
@Component({
    selector: 'delete-icon',
    templateUrl: './delete-icon.component.html'
})
export class DeleteIconComponent extends GtCustomComponent<any>  {

    constructor() {
        super();
    }
}