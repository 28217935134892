import { Injectable }   from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { QuestionBase } from '../../clases/forms/question-base';
import { empty } from '../../validations/empty';
import { TIPOSCONTROLES } from '../../../config/constants';

@Injectable()
export class QuestionControlService {

  constructor() { }

  /**
   * Obtiene la lista conformada por FormControl en base a una lista enviada de QuestionBase, formando un FormGroup
   * @param questions 
   */
  public toFormGroup(questions: QuestionBase<any>[]): FormGroup {
    let group: any = {};
    questions = questions.sort((a, b) => a.order - b.order);
    questions.forEach(question => {
      if (question.required) {
        if (question.controlType === TIPOSCONTROLES.TEXTBOX) {
          group[question.key] = new FormControl(question.value || '', [Validators.required, empty]);
        } 
        else if (question.controlType === TIPOSCONTROLES.DROPDOWN) {
          group[question.key] = new FormControl(question.value || '', [Validators.required]);
        }
      }
      else {
        group[question.key] = new FormControl(question.value || '');
      }
    });
    return new FormGroup(group);
  }
  
}