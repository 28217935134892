/**
 * Perfil
 * @author MG
 * 
 */
export class Perfil {
    id: number;
    nombre: string;
    descripcion: string;
    enabled: boolean;
    restringido: boolean;

    constructor() {
        this.id = null;
        this.nombre = "";
        this.descripcion = "";
        this.enabled = true;
    }
}