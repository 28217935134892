import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { HttpErrorResponse } from '@angular/common/http';
import { finalize } from 'rxjs/operators';
import { ComunicacionService } from '../servicios/comunicacion.service';
import { RecuperarInfoService } from '../../core/servicios/seguridad/recuperar-info.service';
import { ErrorService } from '../../core/servicios/general/error.service';
import { MensajeService } from '../../core/servicios/general/mensaje.service';
import { AcceptDialogComponent } from '../../my-shared/dialogs/accept-dialog/accept-dialog.component';
import { empty } from '../../core/validations/empty';
import { ROUTES } from '../../config/routes';
import { MESSAGES } from '../../config/messages';
import { APPCONFIG } from '../../config';

/**
 * RecuperarContrasenaComponent
 * @author MG
 * 
 */
@Component({
    selector: 'recuperar-contrasena',
    templateUrl: './recuperar-contrasena.component.html'
})
export class RecuperarContrasenaComponent implements OnInit {

    private recuperarForm: FormGroup;
    private validando: boolean;
    private error: boolean;
    private AppConfig: any;

    constructor(
        private router: Router,
        private comunicacionService: ComunicacionService,
        private recuperarInfoService: RecuperarInfoService,
        private errorService: ErrorService,
        private mensajeService: MensajeService,
        private fb: FormBuilder,
        private dialog: MatDialog
    ) { 
        this.AppConfig = APPCONFIG;
        this.validando = false;
        this.error = false;
    }

    /**
     * Inicializa el componente
     */
    public ngOnInit(): void {
        this.comunicacionService.mostrarLogoEsq(false);
        this.inicializaForm();
    }

    /**
     * Inicializa el formulario
     */
    private inicializaForm(): void {
        this.recuperarForm = this.fb.group({
            correo: ["", [Validators.required, empty, Validators.email]]
        });
        this.recuperarForm.controls["correo"].statusChanges.subscribe(val => {
            this.error = false;
        });
    }

    /**
     * Cancela la recuperacion de la contraseña
     */
    private cancelar(): void {
        this.router.navigate([ROUTES.validarUsuario]);
    }

    /**
     * Valida la informacion ingresada
     */
    private validar(): void {
        this.validando = true;
        this.error = false;
        if (this.recuperarForm.valid) {
            var correo = this.recuperarForm.value.correo;
            var username = this.comunicacionService.obtenerUsuario();
            this.recuperarInfoService.recuperarContrasena(username, correo)
                .pipe(
                    finalize(() => {
                        this.validando = false;
                    })    
                )    
                .subscribe(
                res => {
                    if (res.body) {
                        this.mostrarConfirmacion();
                    }
                    else {
                        this.mensajeService.enviar("E00030", "Error", 1);
                    }
                },
                (error: HttpErrorResponse) => {
                    //Validar el error para mostrar mensaje
                    if (this.errorService.validate(error, this.errorService.HttpType.Consulta)) {
                        if (error.error.message === MESSAGES.noExisteUsuario) {
                            this.error = true;
                        }
                        else {
                            this.mensajeService.enviar("E00001", "Error", 1);
                        }
                    }
                    else {
                        this.mensajeService.enviar("E00001", "Error", 1);
                    }
                });
        }
        else {
            this.validando = false;
        }
    }

    /**
     * Muestra la confirmacion del envio del correo
     */
    private mostrarConfirmacion(): void {
        //Crea la instancia del dialogo y la abre
        const dialogRef = this.dialog.open(AcceptDialogComponent, {
            width: '500px',
        });
        dialogRef.componentInstance.titulo = "EnvioExitoso";
        dialogRef.componentInstance.mensaje = "I00014";
        //Redirecciona al cerrar la confirmacion
        dialogRef.afterClosed().subscribe((result) => {
            this.router.navigate([ROUTES.validarUsuario]);
        });
    }

}