import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { LoginService } from '../../core/servicios/seguridad/login.service';
import { ComunicacionService } from '../servicios/comunicacion.service';
import { AuthService } from '../../core/servicios/seguridad/auth.service';
import { MensajeService } from '../../core/servicios/general/mensaje.service';
import { PerfilService } from '../../core/servicios/seguridad/perfil.service';
import { UsuarioService } from '../../core/servicios/seguridad/usuario.service';
import { Perfil } from '../../core/clases/seguridad/perfil';
import { Usuario } from '../../core/clases/seguridad/usuario';
import { TipoUsuario } from '../../core/clases/seguridad/tipo-usuario';
import { empty } from '../../core/validations/empty';
import { ROUTES } from '../../config/routes';
import { IMAGENDEFAULTUSUARIO } from '../../config/constants';
import { APPCONFIG } from '../../config';

/**
 * ValidacionContrasenaComponent
 * @author MG
 * 
 */
@Component({
    selector: 'login-pass',
    templateUrl: './validacion-contrasena.component.html'
})
export class ValidacionContrasenaComponent implements OnInit {

    private recuperar: string;
    private loginForm: FormGroup;
    private contrasenaincorrecta: boolean;
    private errorGenerico: boolean;
    private usuario: string;
    private nombre: string;
    private imagen: string;
    private validando: boolean;
    private muestraContrasena: boolean;
    private AppConfig: any;

    constructor(
        private router: Router,
        private loginService: LoginService,
        private comunicacionService: ComunicacionService,
        private authService: AuthService,
        private mensajeService: MensajeService,
        private perfilService: PerfilService,
        private usuarioService: UsuarioService,
        private fb: FormBuilder
    ) {
        this.AppConfig = APPCONFIG;
        this.validando = false;
        this.recuperar = ROUTES.recuperarContrasena;
        this.usuario = null;
        this.nombre = null;
        this.contrasenaincorrecta = false;
        this.comunicacionService.mostrarLogoEsq(true);
        this.muestraContrasena = false;
    }

    /**
     * Inicializar el componente
     */
    public ngOnInit(): void {
        this.inicializaForm();
        this.loginForm.valueChanges.subscribe(val => {
            this.contrasenaincorrecta = false;
        });
        this.usuario = this.comunicacionService.obtenerUsuario();
        this.nombre = this.comunicacionService.obtenerNombreUsuario();
        if (this.usuario == null) {
            this.router.navigate([ROUTES.validarUsuario]);
        }
        var img = this.comunicacionService.obtenerImagenUsuario();
        if (img == null || img === "") {
            this.imagen = IMAGENDEFAULTUSUARIO;
        } else {
            this.imagen = img;
        }
    }

    /**
     * Inicializar el formulario y las regals de validacion
     */
    private inicializaForm(): void {
        this.loginForm = this.fb.group({
            contrasena: ["", [Validators.required, empty]]
        });
        this.loginForm.controls['contrasena'].statusChanges.subscribe(val => {
            this.errorGenerico = false;
            this.contrasenaincorrecta = false;
        });
    }

    /**
     * Regresar a la pantalla anterior
     */
    private regresar(): void {
        this.comunicacionService.limpiarUsuario();
        this.comunicacionService.limpiarImagenUsuario();
        this.comunicacionService.limpiarNombreUsuario();
        this.router.navigate([ROUTES.validarUsuario]);
    }

    /**
     * Validar el usuario y la contraseña
     */
    private validar(): void {
        this.validando = true;
        if (this.loginForm.valid) {
            if (this.authService.estaAutentificado()) {
                this.authService.limpiarInfoAuth();
                this.router.navigate([ROUTES.validarUsuario]);
            }
            else {
                if (this.usuario != null) {
                    var contrasena = btoa(this.loginForm.value.contrasena);
                    this.loginService.autentificarse(this.usuario, contrasena).subscribe(
                        res => {
                            this.contrasenaincorrecta = false;
                            this.errorGenerico = false;
                            //Guardar el token
                            this.authService.guardar(res.body["token"]);
                            //Obtiene Info de usuario
                            this.obtenerUsuario();
                        },
                        (err: HttpErrorResponse) => {
                            this.validando = false;
                            if (err.error && err.error.message === "User is disabled") {
                                this.errorGenerico = true;
                            }
                            else {
                                this.contrasenaincorrecta = true;
                            }
                        });
                }
                else {
                    this.validando = false;
                    this.mensajeService.enviar("E00001", "", 1);
                    this.router.navigate([ROUTES.validarUsuario]);
                }
            }
        }
        else {
            this.loginForm.controls["contrasena"].markAsTouched();
            this.validando = false;
        }
    }

    /**
     * Obtiene la informacion necesaria para la aplicacion sobre el usuario logueado
     */
    private obtenerUsuario(): void {
        this.usuarioService.obtener().subscribe(
            res => {
                if (res.body != null) {
                    var usuario = new Usuario();
                    usuario.id = res.body.id;
                    usuario.nombre = res.body.nombre;
                    usuario.apellidos = res.body.apellidos;
                    usuario.url = res.body.url;
                    usuario.email = res.body.email;
                    usuario.telefono = res.body.telefono;
                    if (res.body.authorities && res.body.authorities.length > 0) {
                        var tipo = new TipoUsuario();
                        tipo.name = res.body.authorities[0].authority;
                        switch (tipo.name) {
                            case 'ADMIN':
                                tipo.id = 1;
                                break;
                            case 'PROFESOR':
                                tipo.id = 2;
                                break;
                            case 'ALUMNO':
                                tipo.id = 3;
                                break;
                        }
                        usuario.authorities.push(tipo);
                        this.obtenerPerfil(usuario);
                    }
                    else {
                        this.authService.limpiarInfoAuth();
                        this.mensajeService.enviar("E00017", "", 1)
                    }
                }
            },
            (error: HttpErrorResponse) => {
                this.authService.limpiarInfoAuth();
                this.mensajeService.enviar("E00001", "", 1)
                this.validando = false;
            });
    }

    /**
     * Obtiene la informacion del perfil del usuario firmado
     * @param {Usuario} usuario
     */
    private obtenerPerfil(usuario: Usuario): void {
        this.perfilService.obtenerPerfil().subscribe(
            res => {
                if (res.body) {
                    var perfil = new Perfil();
                    perfil = res.body;
                    usuario.perfil = perfil;
                    //guarda info de usuario
                    this.authService.guardarUsuario(usuario);
                    //Verifica que tenga un autoritie asignado (TipoUsuario)
                    if (usuario.authorities.length > 0) {
                        this.router.navigate([ROUTES.principal]);
                    }
                    else {
                        this.mensajeService.enviar("E00001", "", 1)
                        this.validando = false;
                    }
                }
                else {
                    this.mensajeService.enviar("E00001", "", 1)
                    this.validando = false;
                }
            },
            (error: HttpErrorResponse) => {
                this.authService.limpiarInfoAuth();
                this.mensajeService.enviar("E00001", "", 1)
                this.validando = false;
            });
    }

    /**
     * Permite visualizar la contraseña por un breve momento
     */
    private mostrarContrasena(): void {
        this.muestraContrasena = !this.muestraContrasena;    
    }
}