import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

/**
 * SearchComponent
 * @author MG
 */
@Component({
    selector: 'search',
    templateUrl: './search.component.html'
})
export class SearchComponent implements OnInit {

    private stringSearch: string;

    @Output() onSearch: EventEmitter<string> = new EventEmitter();
    @Input() textoBuscar: String = "";
   
    /**
     * Inicializa el componente
     */
    public ngOnInit(): void {
        this.clean();
        if (this.textoBuscar === "" || this.textoBuscar == null) {
            this.textoBuscar = "Buscar";
        }
    }

    /**
     * Limpia la busqueda y envia el evento para la realizacion de la busqueda
     */
    private clean(): void {
        this.stringSearch = "";
        this.search();
    }

    /**
     * Envia el evento para la realizacion de la busqueda
     */
    private search(): void {
        this.onSearch.emit(this.stringSearch);
    }

}