import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestService } from '../general/request.service';
import { Ordenamiento } from '../../clases/general/ordenamiento';
import { PreguntaTarea } from '../../clases/actividades/pregunta-tarea';

/**
 * PreguntaTareaService
 * @author MG
 */
@Injectable()
export class PreguntaTareaService {

    private urlPreguntaTareasPagiancion: string = "/preguntaTarea/all";
    private urlPreguntaTareas: string = "/preguntaTarea";
    private urlPreguntaTareasTodas: string = "/preguntaTarea/xTarea";

    constructor(
        private requestService: RequestService
    ) { }
    
    /**
     * Obtener la lista 
     * @param {number} idTarea
     * @param {number} pagina 
     * @param {number} cantidadRegistros
     * @param {Ordenamiento} ordenamiento
     * @param {string} busqueda
     * @return {Observable<any>} response
     */
    public obtenerLista(idTarea: number, pagina: number, cantidadRegistros: number, ordenamiento: Ordenamiento, busqueda: string): Observable<any> {
        var body = {
            busqueda: busqueda,
            id: idTarea
        };
        var url = this.requestService.formarUrlPaginacion(this.urlPreguntaTareasPagiancion, pagina, cantidadRegistros, ordenamiento);
        return this.requestService.post(url, body);
    }

    /**
     * Lista de todas las preguntas por tarea
     * @param {number} idTarea
     */
    public obtenerListaTodas(idTarea: number): Observable<any> {
        var body = {
            id: idTarea
        };
        return this.requestService.post(this.urlPreguntaTareasTodas, body);
    }

    /**
     * Crea
     * @param {PreguntaTarea} pregunta
     * @return {Observable<any>} 
     */
    public crear(pregunta: PreguntaTarea): Observable<any> {
        var body = {
            pregunta: pregunta.pregunta,
            respuesta1: pregunta.respuesta1,
            respuesta2: pregunta.respuesta2,
            respuesta3: pregunta.respuesta3,
            respuesta4: pregunta.respuesta4,
            correcta: pregunta.correcta,
            tareaDto: pregunta.tareaDto
        };
        return this.requestService.post(this.urlPreguntaTareas, body);
    }

    /**
     * Guardar
     * @param {PreguntaTarea} pregunta
     * @return {Observable<any>}
     */
    public guardar(pregunta: PreguntaTarea): Observable<any> {
        var body = {
            id: pregunta.id,
            pregunta: pregunta.pregunta,
            respuesta1: pregunta.respuesta1,
            respuesta2: pregunta.respuesta2,
            respuesta3: pregunta.respuesta3,
            respuesta4: pregunta.respuesta4,
            correcta: pregunta.correcta,
            tareaDto: pregunta.tareaDto
        };
        return this.requestService.put(this.urlPreguntaTareas, body);
    }

    /**
     * Permite eliminar
     * @param  {number} id
     * @return {Observable<any>} 
     */
    public eliminar(id: number): Observable<any> {
        var url = this.urlPreguntaTareas + "/" + id;
        return this.requestService.delete(url);
    }

    /**
     * Permite obtener el detalle
     * @param  {number} id
     * @return {Observable<any>} 
     */
    public obtenerDetalle(id: number): Observable<any> {
        var url = this.urlPreguntaTareas + "/" + id;
        return this.requestService.get(url);
    }

} 