import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { RequestService } from '../general/request.service';

/**
 * RecuperarInfoService
 * @author MG
 */
@Injectable()
export class RestaurarContrasenaService {

    private urlValidarToken: string = "/auth/validate";
    private urlCambiarPass: string = "/auth/reset";

    constructor(
        private requestService: RequestService
    ) { }

    /**
     * Valida el token para cambiar la contraseña
     * @return {Observable<any>} Response
     */
    public validarToken(token: string): Observable<any> {
        var body = {
            token: token
        }
        return this.requestService._post(this.urlValidarToken, body);
    }

    /**
     * Guarda la nueva contraseña recuperada
     * @return {Observable<any>} Response
     */
    public actualizarContrasena(contrasena: string, token: string): Observable<any> {
        var body = {
            contrasena: contrasena,
            token: token
        }
        return this.requestService._post(this.urlCambiarPass, body);
    }

   

}