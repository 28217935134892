import { Materia } from './materia';
import { Bloque } from './bloque';

export class Andamio {
    
    id: number;
    descripcion: string;
    bloque: Bloque;
    imagen: string;
    archivo: string; //nombre para validacion
    profesor: boolean;
}