import { Injectable } from '@angular/core';
import { LocalStorangeService } from '../general/local-storange.service';
import { Usuario } from '../../clases/seguridad/usuario';
import { NOMBREUSUARIOBL } from '../../../config/constants';
import { STORANGE } from '../../../config/storange';

/**
 * AuthService
 * @author MG
 */
@Injectable()
export class AuthService {

    private token: string;
    private variable = STORANGE.token;
    private variableUsuario = STORANGE.usuarioSeleccioando;
    private nombreUsuarioBL = NOMBREUSUARIOBL;
    
    constructor(
        private localStorangeService: LocalStorangeService
    ) { }

    /**
     * Guarda el token
     */
    public guardar(token: string): void {
        this.token = token;
        localStorage.setItem(this.variable, JSON.stringify(token));
    }
    
    /**
     * Obtiene el token
     * @returns {string}
     */
    public obtener(): string {
        this.token = JSON.parse(localStorage.getItem(this.variable));
        return this.token;
    }

    /**
     * Limpia el token
     */
    private limpiar(): void {
        this.localStorangeService.eliminar(this.variable);
        this.token = null;
    }

    /**
     * Guarda el Usuario seleccionado
     * @param {Usuario} usuario
     */
    public guardarUsuario(usuario: Usuario): void {
        this.limpiarUsuario();
        var usuarioGuardar = new Usuario();
        usuarioGuardar.id = usuario.id;
        usuarioGuardar.nombre = usuario.nombre;
        usuarioGuardar.apellidos = usuario.apellidos;
        usuarioGuardar.url = usuario.url;
        usuarioGuardar.email = usuario.email;
        usuarioGuardar.telefono = usuario.telefono;
        usuarioGuardar.authorities = usuario.authorities;
        usuarioGuardar.perfil.id = usuario.perfil.id;
        usuarioGuardar.perfil.nombre = usuario.perfil.nombre;
        usuarioGuardar.perfil.descripcion = usuario.perfil.descripcion;
        this.localStorangeService.guardar(this.variableUsuario, usuarioGuardar);
    }

    /**
     * Actualiza la imagen del usuario
     * @param {string} url Imagen del usuario
     */
    public actualizarImagenUsuario(url: string): void {
        var usuarioGuardar = this.obtenerUsuario();
        usuarioGuardar.url = url;
        this.localStorangeService.guardar(this.variableUsuario, usuarioGuardar);
    }

    /**
    * Actualiza la la informacion permitida del usuario
    * @param {string} url Imagen del usuario
    */
    public actualizarParametrosUsuario(nombre: string, apellidos: string): void {
        var usuarioGuardar = this.obtenerUsuario();
        usuarioGuardar.nombre = nombre;
        usuarioGuardar.apellidos = apellidos;
        this.localStorangeService.guardar(this.variableUsuario, usuarioGuardar);
    }

    /**
     * Obtiene el Usuario
     * @returns {Usuario}
     */
    public obtenerUsuario(): Usuario {
        var usuario = this.localStorangeService.obtener(this.variableUsuario);
        if (usuario == null)
            usuario = new Usuario();    
        return usuario;
    }

    /**
     * Limpia el Usuario
     */
    private limpiarUsuario(): void {
        this.localStorangeService.eliminar(this.variableUsuario);
    }

    /**
     * Verifica si el usuario esta autentificado 
     * @returns {boolean}
     */
    public estaAutentificado(): boolean {
        this.token = this.obtener();
        //Verifica token
        if (this.token !== null && this.token !== "") {
            var usuario: Usuario = this.obtenerUsuario();
            //Verifica si tiene usuario guardado
            if (usuario) {
                if (usuario.id) {
                    //verifica si tiene perfil guardado 
                    if (usuario.perfil.id) {
                        return true;
                    }
                }
            }    
        }
        return false;
    }

    /**
     * Verifica si tiene permiso de admin
     * @returns {boolean}
     */
    public permisoAdmin(): boolean {
        //Verifica si es usuario BL
        if (this.esUsuarioBL()) {
            //Verifica el permiso
            return true;
        }
        return false;
    }

    /**
     * Indica si el usuario firmado es un USUARIO BL
     * @returns {boolean} 
     */
    public esUsuarioBL(): boolean {
        var usuario: Usuario = this.obtenerUsuario();
        if (usuario.authorities) {
            for (var i = 0; i < usuario.authorities.length; i++) {
                var tipo = usuario.authorities[i];
                if (tipo.name.toUpperCase() === this.nombreUsuarioBL.toUpperCase()) {
                    return true;
                }
            }    
        }    
        return false;
    }

    /**
     * Limpia el ingreso como administrador
     */
    private limpiarIngreso(): void {
        this.localStorangeService.eliminar(this.variableUsuario);
    }

    /**
     * Limpia la sesion del usuario firmado
     */
    public limpiarInfoAuth (): void {
        this.limpiar();
        this.limpiarUsuario();
        this.limpiarIngreso();
    }

}