import { Component} from '@angular/core';
import { GtCustomComponent } from '@angular-generic-table/core';

/**
 * PreviewIconComponent
 * @author MG
 */
@Component({
    selector: 'preview-icon',
    templateUrl: './preview-icon.component.html'
})
export class PreviewIconComponent extends GtCustomComponent<any>  {

    constructor() {
        super();
    }
}