import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { Message } from 'primeng/primeng';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class MensajeService {

    private subject = new Subject<Message>();
    private subject$ = this.subject.asObservable();

    constructor(private translate: TranslateService) { }
        
    /**
     * Envia un mensaje
     * @param {string} mensaje
     * @param {string} titulo
     * @param {number} severityLevel
     */
    public enviar(mensaje: string, titulo: string, severityLevel: number): void {
        var msj: Message;
        var severity: string;
        if (titulo === null || titulo === "") {
            titulo = " ";
        }
        if (mensaje === null || mensaje === "") {
            titulo = " ";
        }
        switch (severityLevel){
            case 1:
                severity = "error";
                break;
            case 2:
                severity = "warn";
                break;
            case 3:
                severity = "success";
                break;
            case 4:
                severity = "info";
                break;
            default:
                severity = "info";
                break;
        }
        msj = {
                summary: this.translate.instant(titulo),
                detail: this.translate.instant(mensaje),
                severity: severity
        }
        this.subject.next(msj);
    }

    /**
     * Obtiene los mensajes enviados
     * @returns {Observable<Message>}
     */
    public obtener(): Observable<Message> {
        return this.subject$;
    }

}