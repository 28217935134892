import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class GeneralService {

    constructor(
        private translateService: TranslateService
    ) { }

    /**
     * Convierte un objeto de tipo DiferenciaFecha a su formato en string
     * @param {number} dias
     * @param {number} horas
     * @param {number} minutos
     * @param {number} soloSiExisten
     * @returns {string}
     */
    public obtenerFormatoFecha(dias: number, horas: number, minutos: number, soloSiExisten: boolean): string {
        var diferenciaS = "";
        if (dias == null) {
            dias = 0;
        }
        if (horas == null) {
            horas = 0;
        }
        if (minutos == null) {
            minutos = 0;
        }
        //Dias
        if (dias === 0) {
            if (!soloSiExisten) {
                diferenciaS = dias + " " + this.translateService.instant("AbrevDias") + " ";
            }
        }
        else if (dias === 1) {
            diferenciaS = dias + " " + this.translateService.instant("AbrevDia") + " ";
        }
        else {
            diferenciaS = dias + " " + this.translateService.instant("AbrevDias") + " ";
        }
        //Horas
        if (horas === 0) {
            if (!soloSiExisten) {
                diferenciaS = diferenciaS + horas + " " + this.translateService.instant("AbrevHoras") + " ";
            }
        }
        else if (horas === 1) {
            diferenciaS = diferenciaS + horas + " " + this.translateService.instant("AbrevHora") + " ";
        }
        else {
            diferenciaS = diferenciaS + horas + " " + this.translateService.instant("AbrevHoras") + " ";
        }
        //Minutos
        if (minutos === 0) {
            if (!soloSiExisten) {
                diferenciaS = diferenciaS + minutos + " " + this.translateService.instant("AbrevMinutos") + " ";
            }
        }
        else if (minutos === 1) {
            diferenciaS = diferenciaS + minutos + " " + this.translateService.instant("AbrevMinuto") + " ";
        }
        else {
            diferenciaS = diferenciaS + minutos + " " + this.translateService.instant("AbrevMinutos") + " ";
        }
        return diferenciaS;
    }

    /**
     * Obtiene el porcentaje equivalente
     * @param {number} total
     * @param {number} cantidad
     */
    public obtenerPorcentaje(total: number, cantidad: number): number {
        var porcentaje = 0;
        if (cantidad > 0 && total > 0) {
            porcentaje = (cantidad * 100) / total;
            porcentaje = parseFloat(porcentaje.toFixed(2));
        }    
        return porcentaje;
    }

    /**
     * Valida si la cadena enviada equivale a un numero
     * @param {string} numero
     */
    public validarSiNumero(numero: string): boolean {
        if (!/^([0-9])*$/.test(numero)) {
            return false;
        }
        return true;
    }

}