import { AbstractControl } from '@angular/forms';

/**
 * 
 * @author MG
 * @param {AbstractControl} control
 */
export function fileNameFormat(control: AbstractControl): { [key: string]: boolean } | null {
    var regex = new RegExp(/^[a-zA-Z0-9 -_]+$/);
    if (control.value != null) {
        if (String(control.value).length > 0) {
            if (!String(control.value).match(regex)) {
                return { 'format': true }
            }
        }
    }
    return null;
}