import { Component} from '@angular/core';
import { GtCustomComponent } from '@angular-generic-table/core';

/**
 * DeactivateIconComponent
 */
@Component({
    selector: 'deactivate-icon',
    templateUrl: './deactivate-icon.component.html'
})
export class DeactivateIconComponent extends GtCustomComponent<any>  {

    constructor() {
        super();
    }
}