import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestService } from '../general/request.service';
import { Ordenamiento } from '../../clases/general/ordenamiento';
import { Ciclo } from '../../clases/catalogos/ciclo';

/**
 * CicloService
 * @author MG
 */
@Injectable()
export class CicloService {

    private urlCicloPagiancion: string = "/ciclo/all";
    private urlCiclos: string = "/ciclo";
    private urlCicloCorto: string = "/ciclo/enabled";

    constructor(
        private requestService: RequestService
    ) { }
    
    /**
     * Obtener la lista 
     * @param {number} pagina 
     * @param {number} cantidadRegistros
     * @param {Ordenamiento} ordenamiento
     * @param {string} busqueda
     * @return {Observable<any>} response
     */
    public obtenerLista(pagina: number, cantidadRegistros: number, ordenamiento: Ordenamiento, busqueda: string): Observable<any> {
        var body = {
            busqueda: busqueda
        };
        var url = this.requestService.formarUrlPaginacion(this.urlCicloPagiancion, pagina, cantidadRegistros, ordenamiento);
        return this.requestService.post(url, body);
    }

    /**
     * Obtener la lista
     */
    public obtenerListaCorto(): Observable<any> {
        return this.requestService.post(this.urlCicloCorto, {});
    }

    /**
     * Crea
     * @param {Ciclo} ciclo
     * @return {Observable<any>} 
     */
    public crear(ciclo: Ciclo): Observable<any> {
        var body = {
            nombre: ciclo.nombre,
            enabled: ciclo.enabled
        };
        return this.requestService.post(this.urlCiclos, body);
    }

    /**
     * Guardar
     * @param {Ciclo} ciclo
     * @return {Observable<any>}
     */
    public guardar(ciclo: Ciclo): Observable<any> {
        var body = {
            id: ciclo.id,
            nombre: ciclo.nombre,
            enabled: ciclo.enabled
        };
        return this.requestService.put(this.urlCiclos, body);
    }

    /**
     * Permite eliminar
     * @param  {number} id
     * @return {Observable<any>} 
     */
    public eliminar(id: number): Observable<any> {
        var url = this.urlCiclos + "/" + id;
        return this.requestService.delete(url);
    }

} 