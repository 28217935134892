import { Component, OnInit } from '@angular/core';
import 'rxjs/add/operator/startWith';
import 'rxjs/add/operator/map';
import { TranslateService } from '@ngx-translate/core';
import { GraficService } from '../../core/servicios/general/grafic.service';
import { AuthService } from '../../core/servicios/seguridad/auth.service';
import { ConfiguracionWebService } from '../../core/servicios/configuracion/configuracion-web.service';
import { APPCONFIG } from '../../config';
import { ConfiguracionWeb } from '../../core/clases/configuracion/configuracion-web';
import { Usuario } from '../../core/clases/seguridad/usuario';
import { HttpErrorResponse } from '@angular/common/http';
import { LayoutService } from '../../core/servicios/general/layout.service';

/**
 * AppCustomizerComponent
 * @author template
 */
@Component({
  selector: 'my-app-customizer',
  templateUrl: './customizer.component.html',
})
export class AppCustomizerComponent implements OnInit {

  public AppConfig: any;
  private config: ConfiguracionWeb;
  private existeConfig: boolean;
  private usuario: Usuario;
  private errorConfig: boolean;

  constructor(
    private graficService: GraficService,
    private translate: TranslateService,
    private configuracionWebService: ConfiguracionWebService,
    private authService: AuthService,
    private layoutService: LayoutService,
  ) {
      this.AppConfig = APPCONFIG;
      this.config = new ConfiguracionWeb();
      this.usuario = new Usuario();
   }

  /**
   * Inicializar el componente
   */
  public ngOnInit(): void {
    this.usuario = this.authService.obtenerUsuario();
    this.errorConfig = false;
    //Verifica si existe una configuracion para el usuario
    this.configuracionWebService.obtener(this.usuario.id).subscribe(
      res => {
        if (res.body) {
          this.config = res.body;
          this.existeConfig = true;
        }  
        this.configurarApp();
      },
      (error: HttpErrorResponse) => {
        this.errorConfig = true;
        this.obtenerConfiguracionPredefinida();
        this.configurarApp();
      });
  }

  /**
   * Obtiene la configuracion predefinida
   */
  private obtenerConfiguracionPredefinida(): void {
    this.config = this.configuracionWebService.obtenerConfiguracionDefault();
  }

  /**
   * Configura la aplicacion con el objeto config
   */
  private configurarApp(): void {
    if (!this.existeConfig || this.errorConfig) {
      this.obtenerConfiguracionPredefinida();
    }
    this.AppConfig.theme = this.config.tema;
    this.AppConfig.colorOption = this.config.color;
    this.AppConfig.fixedHeader = this.config.encabezado;
    this.AppConfig.navCollapsed = this.config.contraer;
    this.cambiaConfiguracion();
  }
  
  /**
  * Se ejcuta cuando se cambia alguna de las configuraciones siguientes:
  * - Idioma
  * - Encabezado fijo
  * - Contraccion del menu lateral
  * - Color
  * - Tema
  */
  private cambiaConfiguracion() {
    if (!this.errorConfig) {
      this.creaOActualizarConfig();
    } 
  }

  /**
   * Se ejecuta cuando se cambia la opcion del color
   * @param color Codigo del color que se selecciono
   */
  private cambiaColor(color: string) {
    this.AppConfig.colorOption = color;
    this.cambiaConfiguracion();
  }

  /**
   * Se ejecuta cuando se cambia la opcion del tema
   * @param tema Tema que se selecciono
   */
  private cambiaTema(tema: string) {
    this.AppConfig.theme = tema;
    this.cambiaConfiguracion();
  }

  /**
   * Se ejecuta cuando cambia el lenguaje
   */
  private cambiaLenguaje(): void {
    this.translate.use(this.AppConfig.idioma);
    this.cambiaConfiguracion();
  }

  /**
   * Se ejecuta cuanda la opcion de contraer el menu lateral se modifica
   */
  private cambiaContraerMenu = () => {
    this.cambiaConfiguracion();
    this.graficService.updateEChartsState(true);
    this.layoutService.setHeaderLabelGroup(this.config.contraer);
  }

  /**
   * Crea o actualiza la configuracion de la aplicacion web para el usuario segun sea el caso
   */
  private creaOActualizarConfig(): void {
    this.config.idioma = this.AppConfig.idioma;
    this.config.tema = this.AppConfig.theme;
    this.config.color = this.AppConfig.colorOption;
    this.config.encabezado = this.AppConfig.fixedHeader;
    this.config.contraer = this.AppConfig.navCollapsed;
    this.config.user = this.usuario;
    if (this.config.id == null) {
      this.configuracionWebService.actualizar(this.config).subscribe(
        res => {
          this.config = res.body;
          this.configuracionWebService.guardarUltimoIdioma(this.config.idioma);
        },
        (error: HttpErrorResponse) => {

        });
    }
    else {
      this.configuracionWebService.actualizar(this.config).subscribe(
        res => {
          this.configuracionWebService.guardarUltimoIdioma(this.config.idioma);
        },
        (error: HttpErrorResponse) => {

        });
    }
  }

}
