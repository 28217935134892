import { Perfil } from './perfil';
import { TipoUsuario } from './tipo-usuario';

/**
 * Usuario
 * @author MG
 * 
 */
export class Usuario {

    id: number;
    nombre: string;
    apellidos: string;
    email: string;
    username: string;
    password: string;
    perfil: Perfil;
    authorities: Array<TipoUsuario>;
    telefono: string;
    url: string;
    enabled: boolean;
    celular: string;
    insertionDate: string;

    constructor(){
        this.id = null;
        this.nombre = "";
        this.apellidos = "";
        this.email ="";
        this.username = "";
        this.password = "";
        this.perfil = new Perfil();
        this.authorities = [];
        this.telefono = "";
        this.enabled = true;
    }

}