import { Component } from '@angular/core';
import { GtExpandedRow } from '@angular-generic-table/core';

@Component({
    selector: 'detalle-descripcion',
    templateUrl: './detalle-descripcion.component.html'
})
export class DetalleDescripcionComponent extends GtExpandedRow<any> {

    constructor() {
        super();
    }
}