/**
 * TipoUsuario
 * @author MG
 * 
 */
export class TipoUsuario {
    id: number;
    name: string;

    constructor(){
        this.id = null;
        this.name = "";
    }
}