import { AbstractControl } from '@angular/forms';

/**
 * Validacion si los campos de la contraseña y el confirmar contraseña coinciden
 * @author MG
 * @param {AbstractControl} control
 */
export function coincidenContrasenas(control: AbstractControl): { [key: string]: boolean } | null {
    var contrasena = control.get('contrasena');
    var confirmarContrasena = control.get('confirmarContrasena');
    if (contrasena.value != confirmarContrasena.value) {
        return { 'match': true };
    }
    return null;
}