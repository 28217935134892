import { Component} from '@angular/core';
import { GtCustomComponent } from '@angular-generic-table/core';

/**
 * RefreshIconComponent
 * @author MG
 */
@Component({
    selector: 'refresh-icon',
    templateUrl: './refresh-icon.component.html'
})
export class RefreshIconComponent extends GtCustomComponent<any>  {

    constructor() {
        super();
    }
}