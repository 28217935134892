import { Component } from '@angular/core';

/**
 * NoDataComponent
 * @author MG
 */
@Component({
    selector: 'no-data',
    templateUrl: './no-data.component.html'
})
export class NoDataComponent {

 
    

}