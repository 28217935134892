import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestService } from '../general/request.service';
import { Ordenamiento } from '../../clases/general/ordenamiento';
import { CodigoProfesor } from '../../clases/seguridad/codigo-profesor';

/**
 * CodigoProfesorService
 * @author MG
 */
@Injectable()
export class CodigoProfesorService {

    private urlCodigosPaginados: string = "/codigoProfesores/all";
    private urlCodigos: string = "/codigoProfesores";

    constructor(
        private requestService: RequestService
    ) { }

    /**
     * Obtener la lista
     * @return {Observable<any>} Response
     */
    public obtenerLista(pagina: number, cantidadRegistros: number, ordenamiento: Ordenamiento, busqueda: string): Observable<any> {
        var body = {
            busqueda: busqueda
        };
        var url = this.requestService.formarUrlPaginacion(this.urlCodigosPaginados, pagina, cantidadRegistros, ordenamiento);
        return this.requestService.post(url, body);
    }
    
    /**
     * Crea un nuevo
     * @param {CodigoProfesor} codigo
     * @return {Observable<any>} Response
     */
    public crear(codigo: CodigoProfesor): Observable<any> {
        var body = {
            codigo: codigo.codigo,
            nombre: codigo.nombre,
            apellidos: codigo.apellidos
        };
        return this.requestService.post(this.urlCodigos, body);
    }

    /**
     * Guardar 
     * @param {CodigoProfesor} codigo
     * @return {Observable<any>} Response
     */
    public guardar(codigo: CodigoProfesor): Observable<any> {
        var body = {
            id: codigo.id,
            codigo: codigo.codigo,
            nombre: codigo.nombre,
            apellidos: codigo.apellidos
        };
        return this.requestService.put(this.urlCodigos, body);
    }

    /**
     * Permite eliminar 
     * @param  {number} id 
     * @return {Observable<any>} Response
     */
    public eliminar(id: number): Observable<any> {
        var url = this.urlCodigos + "/" + id;
        return this.requestService.delete(url);
    }

} 