import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestService } from '../general/request.service';
import { Ordenamiento } from '../../clases/general/ordenamiento';
import { Grupo } from '../../clases/administracion/grupos';

/**
 * GrupoService
 * @author MG
 */
@Injectable()
export class GrupoService {

    private urlGrupoPagiancion: string = "/grupo/all";
    private urlGrupo: string = "/grupo";
    private urlGrupoProfesor: string = "/grupo/profesor";
    private urlGrupoProfesorExportar: string = "/grupo/profesor/export";
    private urlGrupoProfesorMateria: string = "/grupo/profesorMateria";
    private urlGrupoProfesorCodigo: string = "/grupoProfesor";
    private urlLimpiarGrupo: string = "/grupo/deleteByFecha";

    constructor(
        private requestService: RequestService
    ) { }
    
    /**
     * Obtener la lista 
     * @param {number} pagina 
     * @param {number} cantidadRegistros
     * @param {Ordenamiento} ordenamiento
     * @param {string} busqueda
     * @return {Observable<any>} response
     */
    public obtenerLista(pagina: number, cantidadRegistros: number, ordenamiento: Ordenamiento, busqueda: string): Observable<any> {
        var body = {
            busqueda: busqueda
        };
        var url = this.requestService.formarUrlPaginacion(this.urlGrupoPagiancion, pagina, cantidadRegistros, ordenamiento);
        return this.requestService.post(url, body);
    }

    /**
     * Obtener la lista
     */
    public obtenerListaProfesor(): Observable<any> {
        return this.requestService.get(this.urlGrupoProfesor);
    }

    /**
     * Obtener la lista
     * @param clave {string}
     */
    public obtenerListaProfesorCodigo(clave: string): Observable<any> {
        var body = {
            codigoProfesor: {
                codigo: clave
            }
        }
        return this.requestService._post(this.urlGrupoProfesorCodigo, body);
    }

    /**
     * Obtener la lista
     */
    public obtenerListaProfesorMateria(idMateria: number): Observable<any> {
        var body = {
            id: idMateria
        };
        return this.requestService.post(this.urlGrupoProfesorMateria, body);
    }

    /**
     * Crea
     * @param {Grupo} grupo
     * @return {Observable<any>} 
     */
    public crear(grupo: Grupo): Observable<any> {
        var body = {
            nombre: grupo.nombre,
            materia: grupo.materia
        };
        return this.requestService.post(this.urlGrupo, body);
    }

    /**
     * Guardar
     * @param {Grupo} anio
     * @return {Observable<any>}
     */
    public guardar(grupo: Grupo): Observable<any> {
        var body = {
            id: grupo.id,
            nombre: grupo.nombre,
            enabled: grupo.materia
        };
        return this.requestService.put(this.urlGrupo, body);
    }

    /**
     * Permite eliminar
     * @param  {number} id
     * @return {Observable<any>} 
     */
    public eliminar(id: number): Observable<any> {
        var url = this.urlGrupo + "/" + id;
        return this.requestService.delete(url);
    }

    /**
     * Permite eliminar
     * @param  {string} fecha
     * @return {Observable<any>} 
     */
    public eliminarGrupoFecha(fecha: string): Observable<any> {
        var body = {
            fechaFin: fecha
        }
        return this.requestService.post(this.urlLimpiarGrupo, body)
    }

    /**
     * Obtener la lista 
     * @param {number} idProfesor
     * @param {number} pagina 
     * @param {number} cantidadRegistros
     * @param {Ordenamiento} ordenamiento
     * @param {string} busqueda
     * @return {Observable<any>} response
     */
    public obtenerListaPorProfesor(idProfesor: number, pagina: number, cantidadRegistros: number, ordenamiento: Ordenamiento, busqueda: string): Observable<any> {
        var body = {
            busqueda: busqueda
        };
        var url = this.urlGrupoProfesor + "/" + idProfesor;
        url = this.requestService.formarUrlPaginacion(url, pagina, cantidadRegistros, ordenamiento);
        return this.requestService.post(url, body);
    }

    /**
    * Obtener la lista 
    * @param {number} idProfesor
    * @param {Ordenamiento} ordenamiento
    * @param {string} busqueda
    * @return {Observable<any>} response
    */
    public obtenerListaPorProfesorExport(idProfesor: number, ordenamiento: Ordenamiento, busqueda: string): Observable<any> {
        var body = {
            busqueda: busqueda
        };
        var url = this.urlGrupoProfesorExportar + "/" + idProfesor;
        url = this.requestService.formarUrlPaginacion(url, 0, 0, ordenamiento);
        return this.requestService.post(url, body);
    }

} 