import { Component, Input, OnInit } from '@angular/core';
import { APPCONFIG } from '../../config';
import { GraficService } from '../../core/servicios/general/grafic.service';
import { LayoutService } from '../../core/servicios/general/layout.service';

@Component({
  selector: 'my-app-sidenav',
  styles: [],
  templateUrl: './sidenav.component.html'
})
export class AppSidenavComponent implements OnInit {
  
  private AppConfig;

  constructor(
    private graficService: GraficService,
    private layoutService: LayoutService
  ) { }
  
  /**
   * Inicializar componente
   */
  public ngOnInit(): void {
    this.AppConfig = APPCONFIG;
  }

  /**
   * Toggle para Collapsar sidebar
   */
  private toggleCollapsedNav(): void {
    this.AppConfig.navCollapsed = !this.AppConfig.navCollapsed;    
    this.graficService.updateEChartsState(true);
    this.layoutService.setHeaderLabelGroup(this.AppConfig.navCollapsed);
  }
}
