import { RequestService } from '../general/request.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

/**
 * ChatGrupoService
 * @author MG
 */
@Injectable()
export class ChatGrupoService {

    private urlGruposChat: string = "/chatGrupo/all";
    private urlMensajesChat: string = "/MsgGrupo";
    private urlMensajesPorChat: string = "/MsgGrupo/xChat";
    private urlMensajesChatTotal: string = "/MsgGrupo/xChatNoLeidos";

    constructor(
        private requestService: RequestService
    ) { }

    /**
     * Busca los usuarios disponibles para enviar un mensaje
     */
    public obtenerLista(): Observable<any> {
        return this.requestService.post(this.urlGruposChat, {});
    }

    /**
    * Busca los usuarios disponibles para enviar un mensaje
    * @param {number} idChat
    * @param {string} mensaje
    */
    public enviarMensaje(idChat: number, mensaje: string) : Observable<any> {
        var body = {
            idChat: idChat,
            msg: mensaje
        };
        return this.requestService.post(this.urlMensajesChat, body);
    }

    /**
     * Busca los usuarios disponibles para enviar un mensaje
     * @param {number} idChat
     * @param {number} pagina
     * @param {number} cantidadRegistros
    */
    public obtenerListaMensajes(idChat: number, pagina: number, cantidadRegistros: number): Observable<any> {
        var url = this.urlMensajesPorChat
            + "/" + idChat
            + "?page=" + pagina
            + "&size=" + cantidadRegistros;
        return this.requestService.post(url, {});
    }
    
    /**
    * Obtener total de pendientes
    * @param {number} id
    */
    public totalNoLeidos(id: number): Observable<any> {
        var url = this.urlMensajesChatTotal + "/" + id;
        return this.requestService.get(url);
    }
}