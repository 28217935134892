import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { STORANGE } from '../../../config/storange';
import { SessionStorangeService } from '../general/session-storange.service';
import { Grupo } from '../../clases/administracion/grupos';

@Injectable()
export class FiltroGrupoService {

    private subject: BehaviorSubject<Grupo>;
    private variable = STORANGE.filtro;

    constructor(
        private sessionStorangeService: SessionStorangeService) {
        this.subject = new BehaviorSubject<Grupo>(sessionStorangeService.obtener(this.variable));
    }
    
    enviarFiltro(grupo: Grupo) {
        this.subject.next(grupo);
        this.sessionStorangeService.guardar(this.variable, grupo);
    }

    obtenerFiltro(): Observable<Grupo> {
        if(this.subject == null) {
            this.subject = new BehaviorSubject<Grupo>(this.sessionStorangeService.obtener(this.variable));
        }
        return this.subject.asObservable();
    }

    limpiarFiltro(): void {
        this.sessionStorangeService.eliminar(this.variable);
        this.subject = new BehaviorSubject<Grupo>(this.sessionStorangeService.obtener(this.variable));
    }

}