import { Component } from '@angular/core';
import { GtExpandedRow } from '@angular-generic-table/core';

/**
 * DetalleProveedorComponent
 * @author MG
 */
@Component({
    selector: 'detalle-contacto-direccion',
    templateUrl: './detalle-contacto-direccion.component.html'
})
export class DetalleContactoDireccionComponent extends GtExpandedRow<any> {

    constructor(
    ) {
        super();
    }
    
}