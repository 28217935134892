import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestService } from '../general/request.service';
import { Ordenamiento } from '../../clases/general/ordenamiento';
import { Materia } from '../../clases/catalogos/materia';

/**
 * MateriaService
 * @author MG
 */
@Injectable()
export class MateriaService {

    private urlMateriasPagiancion: string = "/materia/all";
    private urlMaterias: string = "/materia";
    private urlMateriasCorto: string = "/materia/notDelete";
    private urlMateriasArea: string = "/materia/area";
    private urlMateriasAreaPublico: string = "/materiaAll";
    private urlMateriasCicloAnio: string = "/materia/cicloAndAnno";
    private urlMateriasProfesor: string = "/materia/profesor";

    constructor(
        private requestService: RequestService
    ) { }
    
    /**
     * Obtener la lista 
     * @param {number} pagina 
     * @param {number} cantidadRegistros
     * @param {Ordenamiento} ordenamiento
     * @param {string} busqueda
     * @return {Observable<any>} response
     */
    public obtenerLista(pagina: number, cantidadRegistros: number, ordenamiento: Ordenamiento, busqueda: string): Observable<any> {
        var body = {
            busqueda: busqueda
        };
        var url = this.requestService.formarUrlPaginacion(this.urlMateriasPagiancion, pagina, cantidadRegistros, ordenamiento);
        return this.requestService.post(url, body);
    }

    /**
     * Obtener la lista
     */
    public obtenerListaPorArea(idArea: number): Observable<any> {
        var body = {
            id: idArea
        };
        return this.requestService.post(this.urlMateriasArea, body);
    }

    /**
     * Obtener la lista
     */
    public obtenerListaPorAreaRegistro(idArea: number): Observable<any> {
        var body = {
            id: idArea
        };
        return this.requestService._post(this.urlMateriasAreaPublico, body );
    }

    /**
     * Obtener la lista
     */
    public obtenerListaPorCicloAnio(idCiclo: number, idAnio: number): Observable<any> {
        var body = {
            ciclo: {
                id: idCiclo
            }, 
            anno: {
                id: idAnio
            }
        };
        return this.requestService.post(this.urlMateriasCicloAnio, body);
    }

    /**
     * Obtener la lista
     */
    public obtenerListaCorto(): Observable<any> {
        return this.requestService.post(this.urlMateriasCorto, {});
    }

    /**
     * Obtener la lista
     */
    public obtenerListaProfesor(): Observable<any> {
        return this.requestService.get(this.urlMateriasProfesor);
    }

    /**
     * Crea
     * @param {Materia} materia
     * @return {Observable<any>} 
     */
    public crear(materia: Materia): Observable<any> {
        var body = {
            nombre: materia.nombre,
            codigo: materia.codigo,
            areasDiciplinarias: materia.areasDiciplinarias,
            ciclo: materia.ciclo,
            anno: materia.anno
        };
        return this.requestService.post(this.urlMaterias, body);
    }

    /**
     * Guardar
     * @param {Materia} materia
     * @return {Observable<any>}
     */
    public guardar(materia: Materia): Observable<any> {
        var body = {
            id: materia.id,
            nombre: materia.nombre,
            codigo: materia.codigo,
            areasDiciplinarias: materia.areasDiciplinarias,
            ciclo: materia.ciclo,
            anno: materia.anno
        };
        return this.requestService.put(this.urlMaterias, body);
    }

    /**
     * Permite eliminar
     * @param  {number} id
     * @return {Observable<any>} 
     */
    public eliminar(id: number): Observable<any> {
        var url = this.urlMaterias + "/" + id;
        return this.requestService.delete(url);
    }

} 