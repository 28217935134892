import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestService } from '../general/request.service';
import { Ordenamiento } from '../../clases/general/ordenamiento';
import { PreguntaExamen } from '../../clases/actividades/pregunta-examen';

/**
 * PreguntaExamenService
 * @author MG
 */
@Injectable()
export class PreguntaExamenService {

    private urlPreguntaExamenesPagiancion: string = "/preguntaExamen/all";
    private urlPreguntaExamenes: string = "/preguntaExamen";
    private urlPreguntaExamenesTodas: string = "/preguntaExamen/xExamen";

    constructor(
        private requestService: RequestService
    ) { }
    
    /**
     * Obtener la lista 
     * @param {number} idExamen
     * @param {number} pagina 
     * @param {number} cantidadRegistros
     * @param {Ordenamiento} ordenamiento
     * @param {string} busqueda
     * @return {Observable<any>} response
     */
    public obtenerLista(idExamen: number, pagina: number, cantidadRegistros: number, ordenamiento: Ordenamiento, busqueda: string): Observable<any> {
        var body = {
            busqueda: busqueda,
            id: idExamen
        };
        var url = this.requestService.formarUrlPaginacion(this.urlPreguntaExamenesPagiancion, pagina, cantidadRegistros, ordenamiento);
        return this.requestService.post(url, body);
    }

    /**
    * Lista de todas las preguntas por examen
    * @param {number} idExamen
    */
    public obtenerListaTodas(idExamen: number): Observable<any> {
        var body = {
            id: idExamen
        };
        return this.requestService.post(this.urlPreguntaExamenesTodas, body);
    }

    /**
     * Crea
     * @param {PreguntaExamen} pregunta
     * @return {Observable<any>} 
     */
    public crear(pregunta: PreguntaExamen): Observable<any> {
        var body = {
            pregunta: pregunta.pregunta,
            respuesta1: pregunta.respuesta1,
            respuesta2: pregunta.respuesta2,
            respuesta3: pregunta.respuesta3,
            respuesta4: pregunta.respuesta4,
            correcta: pregunta.correcta,
            examen: pregunta.examen
        };
        return this.requestService.post(this.urlPreguntaExamenes, body);
    }

    /**
     * Guardar
     * @param {PreguntaExamen} pregunta
     * @return {Observable<any>}
     */
    public guardar(pregunta: PreguntaExamen): Observable<any> {
        var body = {
            id: pregunta.id,
            pregunta: pregunta.pregunta,
            respuesta1: pregunta.respuesta1,
            respuesta2: pregunta.respuesta2,
            respuesta3: pregunta.respuesta3,
            respuesta4: pregunta.respuesta4,
            correcta: pregunta.correcta,
            examen: pregunta.examen
        };
        return this.requestService.put(this.urlPreguntaExamenes, body);
    }

    /**
     * Permite eliminar
     * @param  {number} id
     * @return {Observable<any>} 
     */
    public eliminar(id: number): Observable<any> {
        var url = this.urlPreguntaExamenes + "/" + id;
        return this.requestService.delete(url);
    }

    /**
     * Permite obtener el detalle
     * @param  {number} id
     * @return {Observable<any>} 
     */
    public obtenerDetalle(id: number): Observable<any> {
        var url = this.urlPreguntaExamenes + "/" + id;
        return this.requestService.get(url);
    }

} 