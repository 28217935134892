import { Component} from '@angular/core';
import { GtCustomComponent } from '@angular-generic-table/core';

/**
 * ProductsIconComponent
 * @author MG
 */
@Component({
    selector: 'products-icon',
    templateUrl: './products-icon.component.html'
})
export class ProductsIconComponent extends GtCustomComponent<any>  {

    constructor() {
        super();
    }
}