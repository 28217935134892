import { Component, Input, OnInit } from '@angular/core';

/**
 * ValueCardComponent
 * @author MG
 */
@Component({
    selector: 'value-card',
    templateUrl: './value-card.component.html'
})
export class ValueCardComponent implements OnInit {

    @Input() title: string;
    @Input() classCard: string;
    @Input() classIcon: string;
    @Input() value: string;
    @Input() icon: string;
    @Input() cargando: boolean = false;
    
    /**
     * Inicializar el componente
     */
    public ngOnInit() {
    
    }

}
