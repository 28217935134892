import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestService } from '../general/request.service';
import { Ordenamiento } from '../../clases/general/ordenamiento';
import { Andamio } from '../../clases/catalogos/andamio';
import { HttpParams } from '@angular/common/http';

/**
 * AndamioService
 * @author MG
 */
@Injectable()
export class AndamioService {

    private urlAndamioPagiancion: string = "/andamios/all";
    private urlAndamio: string = "/andamios";
    private urlAndamioDetalle: string = "/andamios/detalle";
    private urlAndamiosProfesor: string = "/andamios/Grupo";
    private urlAndamiosAlumno: string = "/andamios/GrupoAlumno";
    private obtenerUrlAndamio: string = "/andamios/urlXid";
    private urlArchivoAndamio: string = "/files/andamio";

    constructor(
        private requestService: RequestService
    ) { }
    
    /**
     * Obtener la lista 
     * @param {number} pagina 
     * @param {number} cantidadRegistros
     * @param {Ordenamiento} ordenamiento
     * @param {string} busqueda
     * @return {Observable<any>} response
     */
    public obtenerLista(pagina: number, cantidadRegistros: number, ordenamiento: Ordenamiento, busqueda: string): Observable<any> {
        var body = {
            busqueda: busqueda
        };
        var url = this.requestService.formarUrlPaginacion(this.urlAndamioPagiancion, pagina, cantidadRegistros, ordenamiento);
        return this.requestService.post(url, body);
    }

    /**
     * Crea
     * @param {Andamio} andamio
     * @return {Observable<any>} 
     */
    public crear(andamio: Andamio): Observable<any> {
        var body = {
            descripcion: andamio.descripcion,
            bloque: andamio.bloque,
            imagen: andamio.imagen,
            profesor: andamio.profesor,
            archivo: andamio.archivo
        };
        return this.requestService.post(this.urlAndamio, body);
    }

    /**
     * Guardar
     * @param {Andamio} andamio
     * @return {Observable<any>}
     */
    public guardar(andamio: Andamio): Observable<any> {
        var body = {
            id: andamio.id,
            descripcion: andamio.descripcion,
            bloque: andamio.bloque,
            imagen: andamio.imagen,
            profesor: andamio.profesor
        };
        return this.requestService.put(this.urlAndamio, body);
    }

    /**
     * Permite eliminar
     * @param  {number} id
     * @return {Observable<any>} 
     */
    public eliminar(id: number): Observable<any> {
        var url = this.urlAndamio + "/" + id;
        return this.requestService.delete(url);
    }

    /**
     * Encontrar detalle
     * @param {number} id
     * @return {Observable<any>}
     */
    public buscarDetalle(id: number): Observable<any> {
        var body = {
            id: id
        };
        return this.requestService.post(this.urlAndamioDetalle, body);
    }

    /**
     * Busca la lista de andamios para el profesor para el grupo
     * @param {number} idGrupo
     */
    public buscarListaProfesor(idGrupo: number): Observable<any> {
        var body = {
            id: idGrupo
        };
        return this.requestService.post(this.urlAndamiosProfesor, body);
    }

    /**
     * Busca la lista de andamios para el alumno para el grupo
     * @param {number} idGrupo
     */
    public buscarListaAlumno(idGrupo: number): Observable<any> {
        var body = {
            id: idGrupo
        };
        return this.requestService.post(this.urlAndamiosAlumno, body);
    }

    /**
     * Encontrar detalle
     * @param {number} id
     * @return {Observable<any>}
     */
    public buscarUrlArchivo(id: number): Observable<any> {
        var body = {
            id: id
        };
        return this.requestService.post(this.obtenerUrlAndamio, body);
    }

    /**
     * Carga archivo a andamio
     * @param {number} idAndamio 
     * @param {any} archivo 
     */
    public crearArchivo(andamio: Andamio, archivo: any, nombre: string): Observable<any> {
        var formData: FormData = new FormData();
        formData.append('file', archivo, nombre);
        formData.append("descripcion", andamio.descripcion);
        formData.append("bloque", "" + andamio.bloque.id);
        formData.append("imagen", andamio.imagen);
        formData.append("profesor", "" + andamio.profesor);
        formData.append("archivo", andamio.archivo);
        return this.requestService.postFile(this.urlArchivoAndamio, formData);
    }

    
    /**
     * Carga archivo a andamio
     * @param {number} idAndamio 
     * @param {any} archivo 
     */
    /*public crearArchivo(idAndamio: number, archivo: any, nombre: string): Observable<any> {
        var formData: FormData = new FormData();
        formData.append('file', archivo, nombre);
        formData.append("andamio", idAndamio + "");
        return this.requestService.postFile(this.urlArchivoAndamio, formData);
    }*/

    /**
     * Elimina el archivo
     * @param {string} nombreArchivo 
     */
    /*public eliminarArchivo(nombreArchivo: string): Observable<any>{
        var params = new HttpParams();
        params.append('file_name', nombreArchivo)
        return this.requestService.deleteParams(this.urlArchivoAndamio, params);
    }*/
} 