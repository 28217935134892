import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { APPCONFIG } from './config';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, timer } from 'rxjs';
import { Message } from 'primeng/primeng';
import * as $ from 'jquery';
//Servicios
import { ConfiguracionWebService } from './core/servicios/configuracion/configuracion-web.service';
import { LoginService } from './core/servicios/seguridad/login.service';
import { MensajeService } from './core/servicios/general/mensaje.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy  {

  public AppConfig: any;
  private timer: any;
  private subscriptionTimer: Subscription;

  private msgs: Message[] = [];
  private subscription: Subscription;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private configuracionWebService: ConfiguracionWebService,
    private loginService: LoginService,
    private mensajeService: MensajeService
  ) {
    this.AppConfig = APPCONFIG;
    var idioma = this.configuracionWebService.obtenerUltimoIdioma();
    if (idioma == null) {
      this.translate.setDefaultLang(this.AppConfig.idioma);
    }
    else {
      this.translate.setDefaultLang(idioma);
    }
    this.subscription = this.mensajeService.obtener().subscribe(message => {
      this.msgs.push(message);
    });
  }

  /**
   * Inicializar componente
   */
  public ngOnInit(): void {
    // Scroll to top on route change
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      document.body.scrollTop = 0;
    });

    //Verifica desconexion de la red
    this.timer = timer(30 * 1000, 30 * 1000);
    this.subscriptionTimer = this.timer.subscribe(t => {
      var online: boolean = navigator.onLine;
      if (!online) {
        this.mensajeService.enviar("E00022", "", 4);
      }
    });
  }

  /**
   * Destruccion del componente
   */
  public ngOnDestroy() {
    this.loginService.cerrarSesion();
    if (this.subscriptionTimer) {
      this.subscriptionTimer.unsubscribe();
    }
  }

}
