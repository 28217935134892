import { RequestService } from '../general/request.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

/**
 * ChatService
 * @author MG
 */
@Injectable()
export class ChatService {
    
    private urlUsuariosChat: string = "/user/chat";
    private urlChat: string = "/chat";
    private urlChatLista: string = "/chat/all";
    private urlChatTotal: string = "/chat/total";
    private urlMensaje: string = "/MsgUser";
    private urlMensajesLista: string = "/MsgUser/xChat";
    private urlMensajesListaNoLeidos: string = "/MsgUser/xChatNoLeidos";

    constructor(
        private requestService: RequestService
    ) { }

    /**
     * Busca los usuarios disponibles para enviar un mensaje
     * @param {string} busqueda
     */
    public buscarUsuarios(busqueda: string): Observable<any> {
        var body = {
            busqueda: busqueda
        };
        return this.requestService.post(this.urlUsuariosChat, body);
    }

    /**
     * Crear un nuevo chat
     * @param {number} idUser 
     */
    public crear(idUser: number): Observable<any> {
        var body = {
            id: idUser
        };
        return this.requestService.post(this.urlChat, body);
    }

    /**
     * Obtener lista de chats
     */
    public obtenerLista(): Observable<any> {
        return this.requestService.post(this.urlChatLista, {});
    }

    /**
     * Enviar mensaje 
     * @param {number} idUsuario
     * @param {number} idChat
     * @param {string} msg
     */
    public enviarMensaje(idUsuario: number, idChat: number, msg: string): Observable<any> {
        var body = {
            userr: {
                id: idUsuario
            },
            idChat: idChat,
            msg: msg
        };
        return this.requestService.post(this.urlMensaje, body);
    }

    /**
     * Obtener lista de mensajes
     * @param {number} idChat
     * @param {number} pagina
     * @param {number} cantidadRegistros
     */
    public obtenerListaMensajes(idChat: number, pagina: number, cantidadRegistros: number): Observable<any> {
        var url = this.urlMensajesLista
            + "/" + idChat
            + "?page=" + pagina
            + "&size=" + cantidadRegistros;
        return this.requestService.post(url, {});
    }

    /**
     * Obtener total de mensajes no leidos para el chat 
     * @param {number} idChat
     */
    public totalNoLeidos(idChat: number): Observable<any> {
        var url = this.urlMensajesListaNoLeidos + "/" + idChat;
        return this.requestService.get(url);
    }


    /**
     * Obtener total de pendientes
     */
    public obtenerTotalPendientes(): Observable<any> {
        return this.requestService.post(this.urlChatTotal, {});
    }
}