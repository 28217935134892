import { Component, OnInit} from '@angular/core';
import { GtCustomComponent } from '@angular-generic-table/core';

/**
 * TipoAndamioComponent
 * @author MG
 */
@Component({
    selector: 'tipo-andamio',
    templateUrl: './tipo-andamio.component.html'
})
export class TipoAndamioComponent extends GtCustomComponent<any> implements OnInit {
    
    private tipo: string = "";

    constructor() {
        super();
    }

    /**
     * 
     */
    public ngOnInit() {
        if (this.row) {
            if (this.row.profesor) {
                this.tipo = "Profesor";
            }
            else {
                this.tipo = "Alumno";
            }
        }
    }
}