import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '../servicios/seguridad/auth.service';
import { PermisoService } from '../servicios/seguridad/permisos.service';
import { ErrorService } from '../servicios/general/error.service';
import { MensajeService } from '../servicios/general/mensaje.service';
import { ROUTES } from '../../config/routes';
import { MESSAGES } from '../../config/messages';

/**
 * AuthenticatedGuard. Proporciana la verificacion de rutas para usuarios autentificados
 * @author MG
 * 
 */
@Injectable()
export class AuthUserRouteGuard implements CanActivate {
    
    constructor(
        private router: Router,
        private authService: AuthService,
        private permisoService: PermisoService,
        private mensajeService: MensajeService,
        private errorService: ErrorService
    ) { }

    /**
     * Verifica si el usuario esta autentificado y si tiene el permiso para acceder
     * a la ruta seleccionada
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     */
    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        var autentificado = this.authService.estaAutentificado();
        //Verifica si el usuario esta autenticado
        if (autentificado) {
            //Verifica si tiene permiso para la ruta
            this.permisoService.tienePermisoRuta(state.url)
                .subscribe(
                    res => {
                        if (res.body) {
                            return true;
                        }
                        else {
                            this.mensajeService.enviar("E00023", "AccesoDenegado", 1);
                            this.router.navigate([ROUTES.principal]);
                            return false;
                        }
                    },
                    (error: HttpErrorResponse) => {
                        if (this.errorService.validate(error, this.errorService.HttpType.Consulta)) {
                            if (error.error.message === MESSAGES.urlAccesoDenegado) {
                                this.mensajeService.enviar("E00023", "AccesoDenegado", 1);
                            }
                        }
                        this.router.navigate([ROUTES.principal]);
                        return false;
                    });  
        }
        else {
            this.router.navigate([ROUTES.validarUsuario]);
            return false;
        }    
        return true;
    }

}