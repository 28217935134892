import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { finalize } from 'rxjs/operators';
import { ComunicacionService } from '../servicios/comunicacion.service';
import { MensajeService } from '../../core/servicios/general/mensaje.service';
import { ErrorService } from '../../core/servicios/general/error.service';
import { RestaurarContrasenaService } from '../../core/servicios/seguridad/restaurar-contrasena.service';
import { empty } from '../../core/validations/empty';
import { coincidenContrasenas } from '../../core/validations/coincidenContrasenas';
import { validPasswordFormat } from '../../core/validations/formato-contrasena';
import { ROUTES } from '../../config/routes';
import { MESSAGES } from '../../config/messages';
import { APPCONFIG } from '../../config';

/**
 * RestaurarContrasenaComponent
 * @author MG
 * 
 */
@Component({
    selector: 'restaurar-contrasena',
    templateUrl: './restaurar-contrasena.component.html'
})
export class RestaurarContrasenaComponent implements OnInit {

    private recuperar: string;
    private recuperarForm: FormGroup;
    private imagen: string;
    private validandoToken: boolean;
    private validando: boolean;
    private muestraContrasena: boolean;
    private token: string;
    private correcto: boolean;
    private AppConfig: any;

    constructor(
        private router: Router,
        private comunicacionService: ComunicacionService,
        private mensajeService: MensajeService,
        private errorService: ErrorService,
        private restaurarContrasenaService: RestaurarContrasenaService,
        private fb: FormBuilder,
        private route: ActivatedRoute,
    ) {
        this.AppConfig = APPCONFIG;
        this.validando = false;
        this.validandoToken = true;
        this.correcto = false;
        this.comunicacionService.mostrarLogoEsq(false);
        this.muestraContrasena = false;
    }

    /**
     * Inicializar el componente
     */
    public ngOnInit(): void {
        this.inicializaForm();
        this.validandoToken = true;
        this.token = this.route.snapshot.paramMap.get('token');
        //Validar token
        this.restaurarContrasenaService.validarToken(this.token)
            .pipe(
                finalize(() => {
                    this.validandoToken = false;
                })    
            )    
            .subscribe(
                res => {
                    if (res.body) {
                        this.correcto = true;
                    }
                },
                (error: HttpErrorResponse) => {
                    //Validar el error para mostrar mensaje
                    if (this.errorService.validate(error, this.errorService.HttpType.Consulta)) {
                        if (error.error.message === MESSAGES.tokenUtilizado) {
                            this.mensajeService.enviar("E00027", "Error", 1);
                        }
                        else if (error.error.message === MESSAGES.tokenExpiro) {
                            this.mensajeService.enviar("E00029", "Error", 1);
                        }
                        else if (error.error.message === MESSAGES.tokenNoExiste) {
                            this.mensajeService.enviar("E00028", "Error", 1);
                        }    
                        else {
                            this.mensajeService.enviar("E00001", "Error", 1);
                        }
                    }
                    else {
                        this.mensajeService.enviar("E00001", "Error", 1);
                    }
                    this.router.navigate([ROUTES.validarUsuario]);
                });
    }

    /**
     * Inicializar el formulario y las regals de validacion
     */
    private inicializaForm(): void {
        this.recuperarForm = this.fb.group({
            credenciales: this.fb.group({
                contrasena: [null, [Validators.required, Validators.maxLength(50), empty, validPasswordFormat
                ]],
                confirmarContrasena: [null, [Validators.required, empty]]
            }, { validator: coincidenContrasenas })
        });
    }

    /**
     * Regresar a la pantalla anterior
     */
    private regresar(): void {
        this.router.navigate([ROUTES.validarUsuario]);
    }

    /**
     * Validar el usuario y la contraseña
     */
    private validar(): void {
        this.validando = true;
        if (this.recuperarForm.valid) {
            var contrasena = btoa(this.recuperarForm.value.credenciales.contrasena);
            this.restaurarContrasenaService.actualizarContrasena(contrasena, this.token)
                .pipe(
                    finalize(() => {
                        this.validando = false;
                        this.router.navigate([ROUTES.validarUsuario]);
                    })
                )    
                .subscribe(
                    res => {
                        if (res.body) {
                            this.correcto = true;
                            this.mensajeService.enviar("I00015", "Actualizada", 3);
                        }
                    },
                (error: HttpErrorResponse) => {
                    //Validar el error para mostrar mensaje
                    if (this.errorService.validate(error, this.errorService.HttpType.Consulta)) {
                        if (error.error.message === MESSAGES.tokenUtilizado) {
                            this.mensajeService.enviar("E00027", "Error", 1);
                        }
                        else if (error.error.message === MESSAGES.tokenExpiro) {
                            this.mensajeService.enviar("E00029", "Error", 1);
                        }
                        else if (error.error.message === MESSAGES.tokenNoExiste) {
                            this.mensajeService.enviar("E00028", "Error", 1);
                        }
                        else {
                            this.mensajeService.enviar("E00001", "Error", 1);
                        }
                    }
                    else {
                        this.mensajeService.enviar("E00001", "Error", 1);
                    }
                });
        }
        else {
            this.recuperarForm.controls["contrasena"].markAsTouched();
            this.validando = false;
        }
    }

    /**
     * Permite ocultar o mostrar la contraseña
     */
    private mostrarContrasena(): void {
        this.muestraContrasena = !this.muestraContrasena;
    }
}