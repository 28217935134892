import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { RequestService } from '../general/request.service';

/**
 * RecuperarInfoService
 * @author MG
 */
@Injectable()
export class RecuperarInfoService {

    private urlRecuperarUsuario: string = "/auth/recoveryuser";
    private urlRecuperarPass: string = "/auth/recovery";
    
    constructor(
        private requestService: RequestService
    ) { }

    /**
     * Envia la solicitud para recuperar el usuario
     * @return {Observable<any>} Response
     */
    public recuperarUsuario(correo: string): Observable<any> {
        var body = {
            email: correo
        };
        return this.requestService._post(this.urlRecuperarUsuario, body);
    }

    /**
     * Envia la solicitud para recuperar la contraseña
     * @return {Observable<any>} Response
     */
    public recuperarContrasena(username: string, correo: string): Observable<any> {
        var body = {
            username: username,
            email: correo
        };
        return this.requestService._post(this.urlRecuperarPass, body);
    }
}