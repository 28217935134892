export const CHARTCONFIG = {
  primary:  'rgba(33,150,243,.85)', // #2196F3
  success:  'rgba(102,187,106,.85)', // #66BB6A
  info:     'rgba(0,188,212,.85)', // #00BCD4
  infoAlt:  'rgba(126,87,194,.85)', // #7E57C2
  warning:  'rgba(255,202,40,.85)', // #FFCA28
  danger:   'rgba(233,75,59,.85)', // #E94B3B
  gray:     'rgba(221,221,221,.3)', //#dddddd
  textColor: '#848c91',
  button: '#2196F3',
  colors: [
    'rgba(33,150,243,.85)', // #2196F3
    'rgba(102,187,106,.85)', // #66BB6A
    'rgba(0,188,212,.85)', // #00BCD4
    'rgba(126,87,194,.85)', // #7E57C2
    'rgba(255,202,40,.85)', // #FFCA28
    'rgba(233,75,59,.85)', // #E94B3B
    'rgba(221,221,221,.3)', //dddddd
    'rgba(99, 166, 245, 1)', //#63a6f5
    'rgba(130, 199, 130, 1)', //#82c782
    'rgba(90, 199, 219, 1)', //#5ac7db
    'rgba(149, 114, 205, 1)', //#9572cd
    'rgba(255, 210, 86, 1)', //#ffd256
    'rgba(250, 140, 120, 1)', //#Ff36d59
    'rgba(180, 180, 180, 1)', //#b4b4b4
    'rgba(137, 183, 248, 1)', //#89b7f8
    'rgba(156, 210, 154, 1)', //#9cd29a
    'rgba(131, 211, 226, 1)', //#83d3e2
    'rgba(171, 141, 215, 1)', //#ab8dd7
    'rgba(255, 219, 122, 1)', //#ffdb7a
    'rgba(250, 140, 120, 1)', //#fa8c78
    'rgba(229, 229, 229, 1)', //#e5e5e5
    'rgba(170, 201, 250, 1)', //#aac9fa
    'rgba(181, 222, 179, 1)', //#b5deb3
    'rgba(165, 222, 233, 1)', //#a5dee9
    'rgba(193, 169, 225, 1)', //#c1a9e1
    'rgba(255, 228, 156, 1)', //#ffe49c
    'rgba(255, 169, 152, 1)', //#ffa998
    'rgba(141, 141, 141, 1)', //#8d8d8d
    'rgba(200, 218, 252, 1)', //#c8dafc
    'rgba(206, 233, 204, 1)', //#cee9cc
    'rgba(196, 233, 241, 1)', //#c4e9f1
    'rgba(214, 197, 235, 1)', //#d6c5eb
    'rgba(255, 237, 189, 1)', //#ffedbd
    'rgba(255, 198, 186, 1)', //#ffc6ba
    'rgba(204, 204, 204, 1)', //#cccccc
    'rgba(38, 123, 198, 1)', //#267bc6
    'rgba(85, 153, 88, 1)', //#559958
    'rgba(24, 154, 173, 1)', //#189aad
    'rgba(104, 73, 159, 1)', //#68499f
    'rgba(239, 192, 68, 1)', //#efc044
    'rgba(222, 92, 73, 1)', //#de5c49
    'rgba(157, 157, 157, 1)', //#9d9d9d
  ],
  splitLineColor: 'rgba(0,0,0,.05)',
  splitAreaColor: ['rgba(250,250,250,0.035)', 'rgba(200,200,200,0.1)'],
  nombreArchivoDescarga: 'BlackLight',

};

