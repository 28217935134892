import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

/**
 * ToolbarComponent
 * @author MG
 */
@Component({
    selector: 'dialog-toolbar',
    templateUrl: './dialog-toolbar.component.html'
})
export class DialogToolbarComponent implements OnInit {

    @Input() seleccionaTodos: boolean = false; //Muestra el boton para seleccionar todos
    @Input() expande: boolean = false; //Muestra el boton para expandir la tabla
    @Input() busca: boolean = true; //Muestra el Componente de busqueda

    @Output() oExpandir: EventEmitter<boolean> = new EventEmitter();
    @Output() oBuscar: EventEmitter<string> = new EventEmitter();
    @Output() oSeleccionarTodos: EventEmitter<void> = new EventEmitter();
   
    private stringSearch: string;
    private expandido: boolean;

    constructor( ) {
        this.expandido = false;
        this.stringSearch = "";
    }

    /**
     * Inicializacion del componente
     */
    public ngOnInit(): void {
    }

    /**
     * Detecta la solicitud de busqueda en SearchComponent y envia la solicitud
     * @param {string} string Cadena a buscar
     */
    private buscar($event: string): void {
        this.oBuscar.emit($event);
    }

    /**
     * Detecta el click sobre el boton de expandir y envia la solicitud
     */
    private expandir(): void {
        this.expandido = !this.expandido;
        this.oExpandir.emit(this.expandido);
    }

    /**
     * Detecta el click sobre el boton de seleccionar todos y envia la solicitud
     */
    private seleccionarTodos(): void {
        this.oSeleccionarTodos.emit();
    }

    /**
     * Regresa la cadena con la cual se esta realizando la busqueda
     */
    public obtenerCadenaBusqueda(): string {
        return this.stringSearch;
    }

}