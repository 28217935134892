import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestService } from '../general/request.service';
import { Ordenamiento } from '../../clases/general/ordenamiento';
import { Sistema } from '../../clases/catalogos/sistema';

/**
 * SistemaService
 * @author MG
 */
@Injectable()
export class SistemaService {

    private urlSistemasPagiancion: string = "/sistema/all";
    private urlSistemas: string = "/sistema";
    private urlSistemasCorto: string = "/sistema/notDelate";
    private urlSistemasCortoPublico: string = "/sistemaAll";

    constructor(
        private requestService: RequestService
    ) { }
    
    /**
     * Obtener la lista 
     * @param {number} pagina 
     * @param {number} cantidadRegistros
     * @param {Ordenamiento} ordenamiento
     * @param {string} busqueda
     * @return {Observable<any>} response
     */
    public obtenerLista(pagina: number, cantidadRegistros: number, ordenamiento: Ordenamiento, busqueda: string): Observable<any> {
        var body = {
            busqueda: busqueda
        };
        var url = this.requestService.formarUrlPaginacion(this.urlSistemasPagiancion, pagina, cantidadRegistros, ordenamiento);
        return this.requestService.post(url, body);
    }

    /**
     * Obtener la lista
     */
    public obtenerListaCorto(): Observable<any> {
        return this.requestService.post(this.urlSistemasCorto, {});
    }

    /**
     * Obtener la lista
     */
    public obtenerListaCortoRegistro(): Observable<any> {
        return this.requestService._post(this.urlSistemasCortoPublico, {});
    }

    /**
     * Crea
     * @param {Sistema} sistema
     * @return {Observable<any>} 
     */
    public crear(sistema: Sistema): Observable<any> {
        var body = {
            nombre: sistema.nombre,
        };
        return this.requestService.post(this.urlSistemas, body);
    }

    /**
     * Guardar
     * @param {Sistema} sistema
     * @return {Observable<any>}
     */
    public guardar(sistema: Sistema): Observable<any> {
        var body = {
            id: sistema.id,
            nombre: sistema.nombre
        };
        return this.requestService.put(this.urlSistemas, body);
    }

    /**
     * Permite eliminar
     * @param  {number} id
     * @return {Observable<any>} 
     */
    public eliminar(id: number): Observable<any> {
        var url = this.urlSistemas + "/" + id;
        return this.requestService.delete(url);
    }

} 