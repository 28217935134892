import { Component, OnInit, OnDestroy} from '@angular/core';
import { APPCONFIG } from '../../config';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { CerrarSesionComponent } from './cerrar-sesion/cerrar-sesion.component';
import { LoginService } from '../../core/servicios/seguridad/login.service';
import { AuthService } from '../../core/servicios/seguridad/auth.service';
import { LayoutService } from '../../core/servicios/general/layout.service';
import { Usuario } from '../../core/clases/seguridad/usuario';
import { ROUTES } from '../../config/routes';
import { ImagenService } from '../../core/servicios/general/imagen.service';
import { finalize } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorService } from '../../core/servicios/general/error.service';
import { ChatService } from '../../core/servicios/chat/chat.service';
import { Subscription, timer } from 'rxjs';
import { UsuarioService } from '../../core/servicios/seguridad/usuario.service';
import { FiltroGrupoService } from '../../core/servicios/layout/filtro-grupo.sevice';
import { Grupo } from '../../core/clases/administracion/grupos';
import { TIPO_USUARIO } from '../../config/constants';

/**
 * AppHeaderComponent
 * @author template
 */
@Component({
  selector: 'my-app-header',
  styles: [],
  templateUrl: './header.component.html'
})
export class AppHeaderComponent implements OnInit, OnDestroy {

  public AppConfig: any;
  private pantallaCompleta: boolean;
  private img: string;
  private totalMensajes: string;
  private usuario: Usuario;
  private timer: any;
  private subscriptionTimer: Subscription;
  private idAuthority: number;
  private mostrarFiltro: boolean;
  private grupo: Grupo;
  private collapsed: boolean = false;
  
  constructor(
    private router: Router,
    private dialog: MatDialog,
    private loginService: LoginService,
    private authService: AuthService,
    private layoutService: LayoutService,
    private imagenService: ImagenService,
    private errorService: ErrorService,
    private usuarioService: UsuarioService,
    private filtroGrupoService: FiltroGrupoService,
    private chatService: ChatService
  ) {
    this.AppConfig = APPCONFIG;
    this.pantallaCompleta = false;
    this.usuario = new Usuario();
    this.img = this.imagenService.obtenerImagenUsuarioBase64(null);
    //Recibe la indicacion de actualizar el usuario desde otro componente
    this.layoutService.getUpdateUser().subscribe(update => {
      if (update) {
        this.obtenerInfoUsuario();
      }
    });
    this.usuario = this.authService.obtenerUsuario();
    this.idAuthority = this.usuario.authorities[0].id;
    if (this.idAuthority === TIPO_USUARIO.PROFESOR || this.idAuthority === TIPO_USUARIO.ALUMNO) {
      this.mostrarFiltro = true;
    }
    this.filtroGrupoService.obtenerFiltro().subscribe(grupo => {
      this.grupo = grupo;
    });
    this.layoutService.getHeaderLabelGroup().subscribe(update => {
      this.collapsed = update;
    });
  }

  /**
   * Inicializa el componente
   */
  public ngOnInit(): void {
    this.obtenerInfoUsuario();
    //Buscar los mensajes pendientes
    this.obtenerMensajesPendientes();
    this.timer = timer(60 * 1000, 30 * 1000);
    this.subscriptionTimer = this.timer.subscribe(t => {
      this.obtenerMensajesPendientes();
    });
  }

  public ngOnDestroy(): void {
    if (this.subscriptionTimer) {
      this.subscriptionTimer.unsubscribe();
    }
  }

  private obtenerMensajesPendientes(): void {
    this.chatService.obtenerTotalPendientes()
      .pipe(
        finalize(() => {
         
        })
      )
      .subscribe(
        res => {
          this.totalMensajes = res.body;
        },
        (error: HttpErrorResponse) => {
          //this.errorService.validate(error, this.errorService.HttpType.Actualizacion)
        });
  }

  /**
   * Inicializa la imagen del usuario para ser mostrada
   */
  private obtenerInfoUsuario(): void {
    //Obtener la imagen del usuario
    this.usuarioService.obtenerDetalle(this.usuario.id)
      .pipe(
        finalize(() => {
          
        })
      )
      .subscribe(
        res => {
          if (res.body) {
            var usuario: Usuario = res.body;
            if (usuario.url && usuario.url != "") {
              this.img = usuario.url;
            }
          }
        },
        (error: HttpErrorResponse) => {
          this.errorService.validate(error, this.errorService.HttpType.Actualizacion)
        });
  }

  /**
   * Muestra el mensaje para la confirmacion de cerrar sesion
   */
  private confirmarCerrarSesion(): void {
      const dialogRef = this.dialog.open(CerrarSesionComponent);
      dialogRef.afterClosed().subscribe(
        resultado => {
          if (resultado) {
            this.salir();
          }
      });
  }

  /**
   * Realiza la salida del sistema
   */
  private salir(): void {
    this.authService.limpiarInfoAuth();
    this.loginService.cerrarSesion();
    this.router.navigate([ROUTES.validarUsuario]);
  }

  /**
   * Lleva al componente dode se visualiza la informacion del usuario asi como su cofiguracion
   */
  private irAMiUsuario(): void {
    this.router.navigate([ROUTES.misuaurio]);
  }

  /**
   * Ir a mis mensajes
   */
  private irAMensajes(): void {
    this.router.navigate([ROUTES.mensajes]);
  }
  
}