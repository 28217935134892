import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestService } from '../general/request.service';

/**
 * TareaProfesorService
 * @author MG
 */
@Injectable()
export class TareaProfesorService {

    private urlTareas: string = "/tareaProfesor";
    private urlTareaPagiancion: string = "/tareaProfesor/all";
    private urlTareaDesactivar: string = "/tareaProfesor/desactivar";
    private urlTareaActivar: string = "/tareaProfesor/activar";

    constructor(
        private requestService: RequestService
    ) { }
    
    /**
     * Obtener la lista 
     * @param  {number} idMateria
     * @return {Observable<any>} response
     */
    public obtenerLista(idMateria: number): Observable<any> {
        var body = {
           id: idMateria
        };
        return this.requestService.post(this.urlTareaPagiancion, body);
    }

    /**
     * Permite desactivar una tarea
     * @param  {number} id
     * @return {Observable<any>} 
     */
    public desactivar(id: number): Observable<any> {
        var url = this.urlTareaDesactivar + "/" + id;
        return this.requestService.post(url, {});
    }

    /**
     * Permite desactivar una tarea
     * @param  {number} id
     * @return {Observable<any>} 
     */
    public activar(id: number): Observable<any> {
        var url = this.urlTareaActivar + "/" + id;
        return this.requestService.post(url, {});
    }

    /**
     * Obtener por id
     * @param {number} id 
     */
    public obtenerPorId(id: number): Observable<any> {
        var url = this.urlTareas + "/" + id;
        return this.requestService.get(url);
    }

    /**
     * Permite eliminar una tarea
     * @param  {number} id
     * @return {Observable<any>} 
     */
    public eliminar(id: number): Observable<any> {
        var url = this.urlTareas + "/" + id;
        return this.requestService.delete(url);
    }
} 