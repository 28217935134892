import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestService } from '../general/request.service';

/**
 * AlumnoTareaService
 * @author MG
 */
@Injectable()
export class AlumnoTareaService {

    private urlTareasActivos: string = "/alumnoTarea/all";
    private urlTareasEvaluaciones: string = "/alumnoTarea/evaluaciones";
    private urlTareasCalificaciones: string = "/alumnoTarea/calificaciones";
    private urlTareasReactivar: string = "/alumnoTarea/reactivar";

    constructor(
        private requestService: RequestService
    ) { }
    
    /**
     * Obtener la lista 
     * @param  {number} idGrupo
     * @return {Observable<any>} response
     */
    public obtenerListaActivos(idGrupo: number): Observable<any> {
        var body = {
            grupo: {
                id: idGrupo
            }
        };
        return this.requestService.post(this.urlTareasActivos, body);
    }

    /**
     * Obtener la lista de las evaluaciones
     * @param  {number} idGrupo
     * @param  {number} idBloque
     * @return {Observable<any>} response
     */
    public obtenerListaEvaluaciones(idGrupo: number, idBloque: number): Observable<any> {
        var body = {
            grupo: {
                id: idGrupo
            },
            bloque: {
                id: idBloque
            }
        };
        return this.requestService.post(this.urlTareasEvaluaciones, body);
    }

    /**
     * Obtener la lista de las evaluaciones
     * @param  {number} idGrupo
     * @return {Observable<any>} response
     */
    public obtenerListaCalificaciones(idGrupo: number): Observable<any> {
        var body = {
            grupo: {
                id: idGrupo
            }
        };
        return this.requestService.post(this.urlTareasCalificaciones, body);
    }

    /**
     * Reactivar tarea
     * @param {number} id 
     */
    public reactivar(id: number): Observable<any> {
        var url = this.urlTareasReactivar + "/" + id;
        return this.requestService.post(url, {});
    }

} 