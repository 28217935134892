import { Observable } from 'rxjs';
import { RequestService } from '../general/request.service';
import { Injectable } from '@angular/core';
import { Ordenamiento } from '../../clases/general/ordenamiento';

/**
 * CodigoLibroService
 * @author MG
 */
@Injectable()
export class CodigoLibroService {

    private urlCodigos: string = "/codigoLibro";
    private urlCodigosExportar: string = "/codigoLibro/exportar";
    private urlCodigosLiberar: string = "/codigoLibro/liberar";
    private urlCodigosLista: string = "/codigoLibro/all";
    private urlCodigosListaExportar: string = "/codigoLibro/all/export";
    private urlCodigosPorGrupo: string = "/codigoLibro/byGrupo";
    private urlCodigosPorGrupoExportar: string = "/codigoLibro/byGrupo/export";

    constructor(
        private requestService: RequestService
    ) { }

    /**
     * Genera los codigos de los libros
     * @param {number} idCiclo
     * @param {number} idAnio
     * @param {number} idMateria
     * @param {number} cantidad
     */
    public generarCodigos(idCiclo: number, idAnio: number, idMateria: number, cantidad: number): Observable<any> {
        var body = {
            ciclo: {
                id: idCiclo
            },
            anno: {
                id: idAnio
            },
            materia: {
                id: idMateria
            },
            cantidad: cantidad
        };
        return this.requestService.post(this.urlCodigos, body);
    }

    /**
     * Exportar los codigos de los libros
     * @param idMateria 
     */
    public exportarCodigos(idMateria: number): Observable<any> {
        var body = {
            id: idMateria
        };
        return this.requestService.post(this.urlCodigosExportar, body);
    }

    /**
     * Liberar codigos
     * @param {number} id
     */
    public liberarCodigo(id: number): Observable<any> {
        var url = this.urlCodigosLiberar + "/" + id;
        return this.requestService.get(url);
    }

    /**
     * Obtener la lista 
     * @param {number} idCiclo
     * @param {number} idAnno
     * @param {number} idMateria
     * @param {boolean} soloUsados
     * @param {boolean} soloNoUsados
     * @param {number} pagina 
     * @param {number} cantidadRegistros
     * @param {Ordenamiento} ordenamiento
     * @param {string} busqueda
     * @return {Observable<any>} response
     */
    public obtenerLista(idCiclo: number, idAnno: number, idMateria: number, soloUsados: boolean, soloNoUsados: boolean, pagina: number, cantidadRegistros: number, ordenamiento: Ordenamiento, busqueda: string): Observable<any> {
        var body : any = {
            busqueda: busqueda,
            soloUsados: soloUsados,
            soloNoUsados: soloNoUsados
        };
        if (idCiclo != null) {
            body.ciclo = {
                id: idCiclo
            }
        }
        if (idAnno != null) {
            body.anno = {
                id: idAnno
            }
        }
        if (idMateria != null) {
            body.materia = {
                id: idMateria
            }
        }
        var url = this.requestService.formarUrlPaginacion(this.urlCodigosLista, pagina, cantidadRegistros, ordenamiento);
        return this.requestService.post(url, body);
    }

    /**
     * Obtener la lista 
     * @param {Ordenamiento} ordenamiento
     * @param {string} busqueda
     * @return {Observable<any>} response
     */
    public exportarLista(idCiclo: number, idAnno: number, idMateria: number, soloUsados: boolean, soloNoUsados: boolean, ordenamiento: Ordenamiento, busqueda: string): Observable<any> {
        var body: any = {
            busqueda: busqueda,
            soloUsados: soloUsados,
            soloNoUsados: soloNoUsados
        };
        if (idCiclo != null) {
            body.ciclo = {
                id: idCiclo
            }
        }
        if (idAnno != null) {
            body.anno = {
                id: idAnno
            }
        }
        if (idMateria != null) {
            body.materia = {
                id: idMateria
            }
        }
        var url = this.requestService.formarUrlPaginacion(this.urlCodigosListaExportar, 0, 0, ordenamiento);
        return this.requestService.post(url, body);
    }

    /**
     * Obtener la lista 
     * @param {number} idGrupo
     * @param {number} pagina 
     * @param {number} cantidadRegistros
     * @param {Ordenamiento} ordenamiento
     * @param {string} busqueda
     * @return {Observable<any>} response
     */
    public obtenerListaPorGrupo(idGrupo: number,  pagina: number, cantidadRegistros: number, ordenamiento: Ordenamiento, busqueda: string): Observable<any> {
        var body: any = {
            busqueda: busqueda
        };
        if (idGrupo != null) {
            body.grupo = {
                id: idGrupo
            }
        }
        var url = this.requestService.formarUrlPaginacion(this.urlCodigosPorGrupo, pagina, cantidadRegistros, ordenamiento);
        return this.requestService.post(url, body);
    }

    /**
     * Obtener la lista 
     * @param {number} idGrupo
     * @param {Ordenamiento} ordenamiento
     * @param {string} busqueda
     * @return {Observable<any>} response
     */
    public obtenerListaPorGrupoExportar(idGrupo: number, ordenamiento: Ordenamiento, busqueda: string): Observable<any> {
        var body: any = {
            busqueda: busqueda
        };
        if (idGrupo != null) {
            body.grupo = {
                id: idGrupo
            }
        }
        var url = this.requestService.formarUrlPaginacion(this.urlCodigosPorGrupoExportar, 0, 0, ordenamiento);
        return this.requestService.post(url, body);
    }
}