import { Component, Input, OnInit } from '@angular/core';
import { CHARTCONFIG } from '../charts.config';
import { ItemGraficaBarrasSimple } from '../../../core/clases/general/item-grafica-barras-simple';

/**
 * BasicAreaComponent
 * @author MG
 */
@Component({
    selector: 'basic-area-chart',
    templateUrl: './basic-area.component.html'
})
export class BasicAreaComponent implements OnInit {

    @Input() legend: boolean = false; //Si muestra leyenda
    @Input() saveAsImage: boolean = true; //Si permite guardar la grafica como imagen
    @Input() data: Array<ItemGraficaBarrasSimple> = []; //Informacion a mostrar (X)
    @Input() style: string = ""
    @Input() titleTooltip: string = "";
    @Input() title: string = "";
    
    private config = CHARTCONFIG;
    
    private options = {
        title: {
            show: false,
            text: ''
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        legend: {
            orient: 'vertical',
            show: false,
            data: [],
            x: 'right',
            y: 'top',
            textStyle: {
                color: this.config.textColor
            }
        },
        toolbox: {
            show: true,
            feature: {
                saveAsImage: { show: true, title: ' ', name: this.config.nombreArchivoDescarga }
            },
            bottom: 30
        },
        grid: {
            x: 40,
            y: 40,
            x2: 40,
            y2: 80,
            borderWidth: 0
        },
        calculable: true,
        xAxis: [
            {
                type: 'category',
                boundaryGap: false,
                data: []
            }
        ],
        yAxis: [
            {
                show: true,
                type: 'value',
                data: []
            }
        ],
        color: this.config.colors,
        series: []
    };

    /**
     * Inicialiar componente
     */
    public ngOnInit(): void {
        //Verificar si se mostraran las leyendas
        if (this.legend) {
            this.options.legend.show = true;
            this.options.legend.data = [this.legend]; 
        }
         //Verificar si se especifico un titulo
        if (this.title) {
            this.options.title.text = this.title;
            this.options.title.show = true;
        }
        //Verificar configuracion de guardar imagen
        this.options.toolbox.feature.saveAsImage.show = this.saveAsImage;
        this.options.series = [];
        //Definir datos
        if (this.data.length > 0) {
            this.data.forEach(fila => {
                if (fila.tooltip && fila.tooltip != "") {
                    this.titleTooltip = fila.tooltip;
                }
                var elemento = {
                    name: this.titleTooltip,
                    type: 'line',
                    data: fila.valores,
                    areaStyle: {}
                }
                this.options.series.push(elemento);
            });
            this.options.xAxis[0].data = this.data[0].nombres;
        }
    }

}
