import { Injectable } from '@angular/core';

@Injectable()
export class DrawService {

    /**
     * Retorna el icono correspondiente segun el valor booleano enviado
     * @param value 
     */
    public iconoSiONo(value: boolean): string {
        if (value)
            return '<i class="fa fa-check icon-yes"></i>';
        return '<i class="fa fa-ban icon-no"></i>';
    }

}