import { Component, Input, OnInit } from '@angular/core';
import { Andamio } from '../../core/clases/catalogos/andamio';
import { IMAGEN_DEFAULT_ANDAMIO } from '../../config/constants';
import { AndamioService } from '../../core/servicios/catalogos/andamio.service';
import { finalize } from 'rxjs/operators';
import { ImagenService } from '../../core/servicios/general/imagen.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorService } from '../../core/servicios/general/error.service';
import { AndamioUrl } from '../../core/clases/catalogos/andamio-url';
import * as FileSaver from 'file-saver';
import { MensajeService } from '../../core/servicios/general/mensaje.service';

@Component({
    selector: 'descarga-andamio',
    templateUrl: './descarga-andamio.component.html',

})
export class DescargaAndamioComponent implements OnInit {

    @Input() andamio: Andamio;
    @Input() buscar: boolean = false;

    private imagenDefault: string;
    private imagen: string;
    private cargandoDatos: boolean;
    private descargandoDatos: boolean;

    constructor(
        private andamioService: AndamioService,
        private imagenService: ImagenService,
        private errorService: ErrorService,
        private mensajeService: MensajeService
    ) {
        this.imagenDefault = IMAGEN_DEFAULT_ANDAMIO;
    }
    
    /**
     * Inicializa el componente
     */
    public ngOnInit(): void {
        if (!this.andamio)
            this.andamio = new Andamio();
        if (this.buscar) {
            this.buscarAndamio();
        }
        else {
            if (this.andamio.imagen) {
                this.imagen = this.imagenService.obtenerImagenBase64(this.andamio.imagen);
            }
        }
    }

    /**
     * Descragar info del andamio
     * @param {Andamio} andamio
     */
    private descargar(andamio: Andamio): void {
        this.descargandoDatos = true;
        this.andamioService.buscarUrlArchivo(andamio.id)
            .pipe(
                finalize(() => {
                    this.descargandoDatos = false;
                })
            )
            .subscribe(
                res => {
                    var urls: Array<AndamioUrl> = res.body;
                    if (urls.length) {
                        urls.forEach(archivo => {
                            window.open(archivo.url, '_blank')
                        });
                    }
                    else {
                        this.mensajeService.enviar("I00033", "NoExistenArchivos", 4);
                    }
                },
                (error: HttpErrorResponse) => {
                    this.errorService.validate(error, this.errorService.HttpType.Generico);
                });
    }

    /**
     * Busca el detalle correspondiente al Andamio
     */
    private buscarAndamio(): void {
        this.cargandoDatos = true;
        this.andamioService.buscarDetalle(this.andamio.id)
            .pipe(
                finalize(() => {
                    this.cargandoDatos = false;
                    if (this.andamio.imagen) {
                        this.imagen = this.imagenService.obtenerImagenBase64(this.andamio.imagen);
                    }
                })
            )
            .subscribe(
                res => {
                    var andamioAux: Andamio = res.body;
                    this.andamio.imagen = andamioAux.imagen;
                },
                (error: HttpErrorResponse) => {
                    this.errorService.validate(error, this.errorService.HttpType.Generico);
                });
    }
}
