import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';

/**
 * CerrarSesionComponent
 * @author MG
 */
@Component({
  selector: 'cerrar-sesion-dialog',
  templateUrl: './cerrar-sesion.component.html',
})
export class CerrarSesionComponent {
  
  constructor(
    public dialogRef: MatDialogRef<CerrarSesionComponent>
  ) { }
  
  /**
   * Confirma el cierre de sesion
   */
  private aceptar(): void {
    this.dialogRef.close(true);
  }

  /**
   * Cancela el cierre de sesion
   */
  private cancelar(): void {
    this.dialogRef.close(false)
  }

}
