import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateService } from './date.service';
import { EncabezadoExcel } from '../../clases/export/encabezado-excel';
import { GeneralService } from './general.service';

@Injectable()
export class JsonConverterService {

    constructor(
        private translate: TranslateService,
        private dateService: DateService,
        private generalService: GeneralService
    ) {

    }

    /**
     * 
     * @param listaExportar 
     */
    public encabezadoExcelDtoToJson(json: Array<any>, listaExportar: Array<EncabezadoExcel>) : Array<any> {
        listaExportar.forEach(elemento => {
            for (var i = 0; i < listaExportar.length; i++ ) {
                var elementoJson = {
                    columna1: this.translate.instant(elemento.titulo),
                    columna2: elemento.valor
                };
            }
            json.push(elementoJson);
        });
        return json;
    }

    /**
     * 
     * @param json 
     */
    public espacio(json: Array<any>): Array<any> {
        var elementoJson = {
            columna1: ""
        };
        json.push(elementoJson);
        return json;
    }

    /**
     * 
     * @param {Array<any>} json
     * @param {string} fechaConsulta
     */
    public obtenerEncabezadosFechas(json: Array<any>, fechaConsulta: string): Array<any> {
        var elementoJson = {
            columna1: this.translate.instant('FechaConsulta'),
            columna2: fechaConsulta
        };
        var elementoJson2 = {
            columna1: this.translate.instant('FechaGeneracionReporte'),
            columna2: this.dateService.obtenerFechaActual()
        };
        json.push(elementoJson);
        json.push(elementoJson2);
        return json;
    }

    /**
     * @param {Array<any>} json
     */
    public obtenerEncabezadosFechaGeneracion(json: Array<any>): Array<any> {
        var elementoJson2 = {
            columna1: this.translate.instant('FechaGeneracionReporte'),
            columna2: this.dateService.obtenerFechaActual()
        };
        json.push(elementoJson2);
        return json;
    }

}