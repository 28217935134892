import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './layout.component';
import { AuthGuard } from '../core/guards/auth-guard';
import { AuthUserRouteGuard } from '../core/guards/auth-user-route-guard';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      //General
      { path: '', redirectTo: "/principal", pathMatch: "full" },
      { path: 'principal', loadChildren: '../dashboard/dashboard.module#DashboardModule', canActivate: [AuthGuard] },
      { path: 'miusuario', loadChildren: '../mi-usuario/mi-usuario.module#MiUsuarioModule', canActivate: [AuthGuard] },
      //Seguridad
      { path: 'perfiles', loadChildren: '../perfiles/perfiles.module#PerfilesModule', canActivate: [AuthUserRouteGuard] },
      { path: 'usuarios', loadChildren: '../usuarios/usuarios.module#UsuariosModule', canActivate: [AuthUserRouteGuard] },
      { path: 'claves-profesores', loadChildren: '../claves-profesores/clave-profesores.module#ClavesProfesoresModule', canActivate: [AuthUserRouteGuard] },
      { path: 'profesores', loadChildren: '../profesores/profesores.module#ProfesoresModule', canActivate: [AuthUserRouteGuard] },
      { path: 'alumnos', loadChildren: '../alumnos/alumnos.module#AlumnosModule', canActivate: [AuthUserRouteGuard] },
      //Catalogos
      { path: 'anios', loadChildren: '../anios/anios.module#AniosModule', canActivate: [AuthUserRouteGuard]  },
      { path: 'sistemas', loadChildren: '../sistemas/sistemas.module#SistemasModule', canActivate: [AuthUserRouteGuard]  },
      { path: 'ciclos', loadChildren: '../ciclo/ciclo.module#CiclosModule', canActivate: [AuthUserRouteGuard]  },
      { path: 'areas', loadChildren: '../area-diciplinaria/area-diciplinaria.module#AreaDiciplinariaModule', canActivate: [AuthUserRouteGuard]  },
      { path: 'planteles', loadChildren: '../planteles/planteles.module#PlantelesModule', canActivate: [AuthUserRouteGuard]  },
      { path: 'materias', loadChildren: '../materias/materias.module#MateriasModule', canActivate: [AuthUserRouteGuard]  },
      { path: 'bloques', loadChildren: '../bloques/bloques.module#BloquesModule', canActivate: [AuthUserRouteGuard]  },
      { path: 'andamios', loadChildren: '../andamios/andamios.module#AndamiosModule', canActivate: [AuthUserRouteGuard] },
      { path: 'videotutoriales', loadChildren: '../videotutoriales/videotutoriales.module#VideotutorialesModule', canActivate: [AuthUserRouteGuard] },
      //Administracion
      { path: 'codigos-libros', loadChildren: '../codigos-libro/codigos-libros.module#CodigosLibrosModule', canActivate: [AuthUserRouteGuard] },
      { path: 'examenes', loadChildren: '../examenes/examenes.module#ExamenesModule', canActivate: [AuthUserRouteGuard] },
      { path: 'tareas', loadChildren: '../tareas/tareas.module#TareasModule', canActivate: [AuthUserRouteGuard] },
      { path: 'grupos', loadChildren: '../grupos/grupos.module#GruposModule', canActivate: [AuthUserRouteGuard] },
      { path: 'alumnos-profesor', loadChildren: '../alumnos-profesor/alumnos-profesor.module#AlumnosProfesorModule', canActivate: [AuthUserRouteGuard]   },
      { path: 'examenes-profesor', loadChildren: '../examenes-profesor/examenes-profesor.module#ExamenesProfesorModule', canActivate: [AuthUserRouteGuard] },
      { path: 'tareas-profesor', loadChildren: '../tareas-profesor/tareas-profesor.module#TareasProfesorModule', canActivate: [AuthUserRouteGuard] },
      { path: 'evaluaciones', loadChildren: '../evaluaciones/evaluaciones.module#EvaluacionesModule', canActivate: [AuthUserRouteGuard] },
      { path: 'limpiar-grupos', loadChildren: '../limpiar-grupos/limpiar-grupos.module#LimpiarGruposModule', canActivate: [AuthUserRouteGuard] },
      //Recursos
      { path: 'andamios-alumno', loadChildren: '../andamios-alumnos/andamios-alumnos.module#AndamiosAlumnosModule', canActivate: [AuthUserRouteGuard]  },
      { path: 'andamios-profesor', loadChildren: '../andamios-profesores/andamios-profesores.module#AndamiosProfesoresModule', canActivate: [AuthUserRouteGuard] },
      { path: 'tutoriales', loadChildren: '../tutoriales/tutoriales.module#TutorialesModule', canActivate: [AuthUserRouteGuard] },
      //Actividades
      { path: 'mis-examenes', loadChildren: '../mis-examenes/mis-examenes.module#MisExamenesModule', canActivate: [AuthUserRouteGuard]  },
      { path: 'mis-tareas', loadChildren: '../mis-tareas/mis-tareas.module#MisTareasModule', canActivate: [AuthUserRouteGuard]  },
      { path: 'mis-evaluaciones', loadChildren: '../mis-evaluaciones/mis-evaluaciones.module#MisEvaluacionesModule', canActivate: [AuthUserRouteGuard] },
      //Reportes
      { path: 'cantidadcodigos', loadChildren: '../reporte-cantidad-codigos/reporte-cantidad-codigos.module#ReporteCantidadCodigosModule'},
      //Auxiliar
      { path: 'realizados', loadChildren: '../realizados/realizados.module#RealizadosModule' },
      //Mensajeria
      { path: 'mensajes', loadChildren: '../mensajes/mensajes.module#MensajesModule' }
    ]
  }
];

export const LayoutRoutingModule = RouterModule.forChild(routes);
