import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestService } from '../general/request.service';
import { Respuesta } from '../../clases/actividades/respuesta';

/**
 * PreguntaExamenProfesorService
 * @author MG
 */
@Injectable()
export class PreguntaExamenProfesorService {

    private urlPreguntaExamen: string = "/preguntaExamenProfesor/xExamen";
    private urlPreguntaExamenEvaluar: string = "/preguntaExamenProfesor/evaluar";

    constructor(
        private requestService: RequestService
    ) { }
    
    /**
    * Lista de todas las preguntas por examen
    * @param {number} idExamen
    */
    public obtenerLista(idExamen: number): Observable<any> {
        var body = {
            id: idExamen
        };
        return this.requestService.post(this.urlPreguntaExamen, body);
    }

    /**
     * Evalua las respuestas del alumno
     * @param {number} idExamen
     * @param {Array<Respuesta>} lista
     */
    public evaluarExamen(idExamen: number, lista: Array<Respuesta>): Observable<any> {
        var body = {
            id: idExamen,
            respuesta: lista
        };
        return this.requestService.post(this.urlPreguntaExamenEvaluar, body);
    }

} 